import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";

import * as actions from '../../../redux/action_creators';
import { day_format, time_format } from '../../../constants';
import CustomModal from "../../util_components/custom_modal";
import { image_error_replace } from '../../../util_functions';
import { get_api_url } from '../../../utils/urls';
import { api_with_method } from '../../../redux/api_funcs';

class BlockedMessages extends Component {
  state = {
    loading: false,
    blocked_messages: [],
    hide_open: false,
    hide_obj: {},
    white_open: false
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = `https://${get_api_url('mytChatUrl')}/v1.0/vulgarity/messages/blocked`;

      api_with_method('get', url, null, true)
        .then((response) => {
          this.setState({ blocked_messages: [...response.data.items], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_message = (message) => {
    let message_text = '';
    try {
      message_text = message.message.data.txt;
    } catch (e) {}
    let link_url = '';
    try {
      let prefix = '';
      const uuid = message.message.frm;
      if (message.profile.role === 'student') prefix = `/studentprofile?id=`;
      else prefix = `/teacherprofile?id=`;
      link_url = `${prefix}${uuid}`;
    } catch (e) {}
    let created_date;
    try {
      created_date = message.message.ts;
    } catch (e) {}
    return (
      <div className="ct-row" key={message.message.ts}>
        <div className="ct-col">
          <a className="link-no-dec" href={link_url}>
            <div className="profile-img-name">
              <img src={message.profile.avatar} onError={image_error_replace} alt="x" />
              <div className="ct-vsm-font">
                {message.profile.nick}
                <div style={{ display: 'block' }}>({message.profile.role})</div>
              </div>
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">{message.error.word}</div>
        <div className="ct-col ct-sm-font ct-bw">{message_text}</div>
        <div className="ct-col ct-vsm-font ct-hf">
          {created_date ? (
            <>
              <div>{moment(created_date).tz(this.props.iana_timezone).format(day_format)}</div>
              <div>{moment(created_date).tz(this.props.iana_timezone).format(time_format)}</div>
            </>
          ) : (
            '--'
          )}
        </div>
        <div className="ct-col ct-sm-font ct-hf">
          <button onClick={() => this.open_white(message)} className="ct-small-btn">
            Whitelist
          </button>
        </div>
        <div className="ct-col ct-sm-font ct-hf">
          <button onClick={() => this.open_hide(message)} className="ct-small-btn">
            Hide
          </button>
        </div>
      </div>
    );
  };

  render_words = () => {
    const { blocked_messages } = this.state;
    return (
      <div className="cus-table" style={{ marginBottom: '20px' }}>
        <div className="ct-row ct-h">
          <div className="ct-col">User</div>
          <div className="ct-col">Error Word</div>
          <div className="ct-col">Message</div>
          <div className="ct-col ct-hf">Date</div>
          <div className="ct-col ct-hf" />
          <div className="ct-col ct-hf" />
        </div>
        {blocked_messages.map(this.render_single_message)}
      </div>
    );
  };

  hide_message = () =>
    this.setState({ loading: true }, () => {
      const { hide_obj, white_open } = this.state;
      const url = `https://${get_api_url('mytChatUrl')}/v1.0/vulgarity/users/${hide_obj.message.frm}/messages/${hide_obj.message[`t:h`]}${white_open ? '/accept' : ''}`;
      const api_type = white_open ? 'put' : 'delete';
      api_with_method(api_type, url, null, true)
        .then((response) => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_hide = () => {
    const { hide_obj, white_open } = this.state;
    const message_type = white_open ? 'whitelist' : 'hide';
    let message_text = 'message';
    try {
      message_text = hide_obj.message.data.txt;
    } catch (e) {}
    return (
      <div>
        <h4>
          Are you sure you want to {message_type} {message_text} ?
        </h4>
        <button onClick={this.hide_message}>Confirm</button>
        <button onClick={this.close_modal}>Cancel</button>
      </div>
    );
  };

  open_hide = (hide_obj) => this.setState({ hide_open: true, hide_obj });

  open_white = (hide_obj) => this.setState({ white_open: true, hide_obj });

  close_modal = () => this.setState({ hide_open: false, hide_obj: {}, white_open: false });

  render() {
    const { loading, hide_open, white_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Blocked Messages">
        <CustomModal show={hide_open || white_open} close={this.close_modal}>
          {this.render_hide()}
        </CustomModal>
        {this.render_words()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(BlockedMessages);
