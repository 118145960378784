import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from "../button";
import { post_api } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';
import './upload.css';

class UploadImage extends Component {
  handleClickUploadFile = (event) => {
    const payload = new FormData();

    payload.append('image_data', event.target.files[0]);
    payload.append('uuid', this.props.uuid);
    payload.append('folder_name', this.props.folder_name);
    payload.append('image_type', event.target.name);

    const url = get_api_url('upload_image');
    this.props.start_loading();
    post_api(url, payload, true)
      .then((res) => {
        this.props.change_input(this.props.image_type, res.data.url);
        this.props.stop_loading();
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        this.props.set_notification_variable(true, 'error', err_message);
        this.props.stop_loading();
      });
  };

  render() {
    const { buttonTitle, imageUrl, image_type } = this.props;
    return (
      <div style={{ marginTop: '20px' }}>
        {imageUrl && <img src={imageUrl} alt={image_type} className="upload-image" />}
        <input
          type="file"
          onChange={this.handleClickUploadFile}
          accept="image/*"
          multiple={false}
          id={`file-upload-${image_type}`}
          name={image_type}
          style={{ display: 'none' }}
        />
        <Button>
          <label htmlFor={`file-upload-${image_type}`}>{buttonTitle}</label>
        </Button>
      </div>
    );
  }
}

UploadImage.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  folder_name: PropTypes.string.isRequired,
  image_type: PropTypes.string.isRequired,
  change_input: PropTypes.func,
  stop_loading: PropTypes.func,
  start_loading: PropTypes.func,
  close_create_workshop: PropTypes.func,
  get_all_workshops: PropTypes.func
};

UploadImage.defaultProps = {
  stop_loading: () => console.log('stop loading'),
  start_loading: () => console.log('start loading'),
  close_create_workshop: () => console.log('close'),
  get_all_workshops: () => console.log('close'),
  uuid: ''
};

export default UploadImage;
