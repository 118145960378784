import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomLoading from '../../../util_components/custom_loading';
import CustomModal from '../../../util_components/custom_modal';
import CommonHeader from '../../../util_components/common_header';
import CustomInput from '../../../util_components/custom_input';
import { post_api } from '../../../../redux/api_funcs';
import { time_format, day_format_year, day_format } from '../../../../constants';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';
import Button from '../../../util_components/button';

class StudentTransactions extends Component {
  state = {
    loading: true,
    single_trans_open: true,
    subscription_open: true,
    refund_reason: '',
    create_err: false,
    sessions_open: false,
    sessions: [],
    refund_open: false,
    refund_amount: 0,
    confirm_resend: false,
    charge_open: false,
    charge_amount: 0,
    charge_reason: '',
    charge_confirm: false,
    single_transactions: [],
    session_transactions: [],
    sess_trans_open: false,
    student_details: {},
    currency_symbol: '',
    discounts_open: false,
    discounts: []
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const student_uuid = url.searchParams.get('id');
    const user_name = url.searchParams.get('name');
    this.setState({ student_uuid, user_name }, () => this.load_transaction_details());
  }

  load_transaction_details = () => {
    const url = get_api_url('list_all_transactions');
    const payload = { student_uuid: this.state.student_uuid, recurring: 0 };
    post_api(url, payload, true).then((response) =>
      this.setState(
        {
          session_transactions: [...response.data.transaction],
          single_transactions: [...response.data.single_transactions],
          student_details: { ...response.data.student_details }
        },
        () => {
          const new_payload = { student_uuid: this.state.student_uuid, recurring: 1 };
          post_api(url, new_payload, true).then((response) => {
            const subscription_transactions = [...response.data.transaction];
            this.setState({ subscription_transactions }, () => this.setState({ loading: false }));
          });
        }
      )
    );
  };

  go_back = () => {
    this.props.history.goBack();
  };

  toggle_single_trans = () => this.setState({ single_trans_open: !this.state.single_trans_open });

  toggle_sess_trans = () => this.setState({ sess_trans_open: !this.state.sess_trans_open });

  go_to_email = () => {
    const { charge_amount, charge_reason, student_uuid, student_details } = this.state;
    this.props.history.push({
      pathname: '/createemail',
      state: {
        load_email: true,
        from_transaction: true,
        amount: charge_amount,
        reason: charge_reason,
        student_uuid,
        email: student_details.email,
        name: student_details.first_name
      }
    });
  };

  render_transaction_details = (transaction) => {
    console.log(transaction);
    let link_url = '';
    if (transaction.charge_reason === 'WORKSHOP_PURCHASE' && !!transaction.workshop_uuid) {
      link_url = `/workshop/${transaction.workshop_uuid}`;
    }
    if (transaction.session_uuid) {
      link_url = `/sessiondetails?id=${transaction.workshop_uuid}`;
    }
    if (link_url) {
      return (
        <a href={link_url} className="link-btn">
          Details
        </a>
      );
    }
    return '--';
  };

  get_reason_breakup = (reason) => {
    try {
      return reason.split('_').join(' ');
    } catch (e) {
      return reason;
    }
  };

  render_single_transactions = () => {
    const { single_transactions, single_trans_open } = this.state;
    return (
      <div>
        <div
          className="details-session-logs-header student-transactions"
          onClick={this.toggle_single_trans}
        >
          Single Transactions: {single_transactions.length}
          {single_transactions.length !== 0 ? (
            <img
              className={single_trans_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {single_transactions.length !== 0 && single_trans_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-sm-font">Invoice id</div>
              <div className="ct-col ct-hf ct-sm-font">Price</div>
              <div className="ct-col ct-sm-font">Reason</div>
              <div className="ct-col ct-hf ct-sm-font">Transaction Date</div>
              <div className="ct-col ct-hf ct-sm-font">Refunded Amount</div>
              <div className="ct-col ct-hf ct-sm-font">Refunded By</div>
              <div className="ct-col ct-hf ct-sm-font">Reason</div>
              <div className="ct-col ct-hf ct-sm-font">Refund</div>
              <div className="ct-col ct-hf ct-sm-font">Discounts</div>
              <div className="ct-col ct-hf ct-sm-font">Details</div>
            </div>
            {single_transactions.map((transaction) => (
              <div className="ct-row" key={transaction.invoice_id}>
                <div className="ct-col ct-hf ct-sm-font ct-bw">{transaction.invoice_id}</div>
                <div className="ct-col ct-hf">{transaction.purchase_price}</div>
                <div className="ct-col ct-sm-font">
                  {this.get_reason_breakup(transaction.charge_reason)}
                </div>
                <div className="ct-col ct-sm-font ct-hf">
                  <div>
                    {moment(transaction.purchase_date)
                      .tz(this.props.iana_timezone)
                      .format(day_format_year)}
                  </div>
                  <div>
                    {moment(transaction.purchase_date)
                      .tz(this.props.iana_timezone)
                      .format(time_format)}
                  </div>
                </div>
                <div className="ct-col ct-hf">{transaction.refund_amount}</div>
                <div className="ct-col ct-hf">{transaction?.refunded_by}</div>
                <div className="ct-col ct-hf">{transaction?.refund_reason}</div>
                <div className="ct-col ct-hf">
                  {transaction.purchase_price > 0 &&
                  transaction.purchase_price > transaction.refund_amount ? (
                    <button
                      className="ct-small-btn"
                      onClick={() =>
                        this.open_refund(transaction.charge_id, transaction.currency_symbol)
                      }
                    >
                      Refund
                    </button>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col ct-hf">
                  {!!transaction.discounts && transaction.discounts.length > 0 ? (
                    <button
                      className="ct-small-btn"
                      onClick={() => this.view_discounts(transaction.invoice_id, 'single')}
                    >
                      View
                    </button>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col ct-hf">{this.render_transaction_details(transaction)}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  render_session_transactions = () => {
    const { session_transactions, sess_trans_open } = this.state;
    return (
      <div>
        <div
          className="details-session-logs-header student-transactions"
          onClick={this.toggle_sess_trans}
        >
          Session Transactions: {session_transactions.length}
          {session_transactions.length !== 0 ? (
            <img
              className={sess_trans_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {session_transactions.length !== 0 && sess_trans_open ? (
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Invoice Id</div>
              <div className="ct-col ct-hf">Price</div>
              <div className="ct-col ct-hf">Teacher</div>
              <div className="ct-col">Transaction Time</div>
              <div className="ct-col">Sesson Time</div>
              <div className="ct-col ct-hf">Student</div>
            </div>
            {session_transactions.map((transaction) => {
              const teacher_url = `/teacherprofile?id=${transaction.teacher_uuid}`;
              const student_url = `/studentprofile?id=${transaction.student_uuid}`;
              return (
                <div className="ct-row" key={transaction.invoice_id}>
                  <div className="ct-col">{transaction.invoice_id}</div>
                  <div className="ct-col ct-hf">{transaction.purchase_price}</div>
                  <div className="ct-col ct-hf">
                    <a className="link-no-dec" href={teacher_url}>
                      {transaction.teacher_name}
                    </a>
                  </div>
                  <div className="ct-col">{transaction.transaction_date}</div>
                  <div className="ct-col">{transaction.session_student_time}</div>
                  <div className="ct-col ct-hf">
                    <a className="link-no-dec" href={student_url}>
                      {transaction.student_name}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  toggle_subscription_open = () =>
    this.setState({ subscription_open: !this.state.subscription_open });

  view_sessions = (id) => {
    const { subscription_transactions } = this.state;
    const index = subscription_transactions.findIndex((s) => s.invoice_id === id);
    const sessions = subscription_transactions[index].session_lists;
    this.setState({ sessions, sessions_open: true });
  };

  view_discounts = (id, type = 'subscription') => {
    let { subscription_transactions } = this.state;
    if (type === 'single') {
      subscription_transactions = this.state.single_transactions;
    }
    const index = subscription_transactions.findIndex((s) => s.invoice_id === id);
    const { discounts } = subscription_transactions[index];
    this.setState({ discounts, discounts_open: true });
  };

  open_session_details = (id) => {
    const search = `?id=${id}`;
    const pathname = '/sessiondetails';
    this.props.history.push({ pathname, search });
  };

  render_sessions = () => {
    const { sessions } = this.state;
    const paid_yoga_sessions = sessions.filter(
      (s) =>
        s.paid_session === 1 &&
        (s.session_type === 'YOGA' || s.session_type === 'GROUP_SESSION_STUDENT')
    );
    const workshop_sessions = sessions.filter((s) => s.session_type === 'WORKSHOP_SESSION_STUDENT');
    const free_sessions = sessions.filter(
      (s) => s.session_type !== 'WORKSHOP_SESSION_STUDENT' && s.paid_session === 0
    );
    const file_name = `Student_transactions_${moment().format('YYYY-MM-DD')}`;
    const download_data = sessions.map((s) => ({
      teacher_name: s.teacher_name,
      start_time: moment(s.start_time).tz(this.props.iana_timezone).format('YYYY-MM-DD hh:mm z'),
      duration: s.duration,
      finished: s.session_status === 'FINISHED',
      cancelled: s.session_status === 'CANCELLED',
      cancelled_less: s.session_status === 'NO_SHOW_CANCELLATION_1HOUR'
    }));
    if (this.state.sessions_open) {
      return (
        <div style={{ marginTop: '20px' }}>
          {sessions.length > 0 ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="teachers-count">Sessions:</div>
                <div style={{ textAlign: 'right' }}>
                  <ExcelFile
                    element={<button className="data-download-btn">Download Data</button>}
                    filename={file_name}
                  >
                    <ExcelSheet data={download_data} name="Transactions">
                      <ExcelColumn label="Teacher" value="teacher_name" />
                      <ExcelColumn label="Start Time" value="start_time" />
                      <ExcelColumn label="Duration" value="duration" />
                      <ExcelColumn label="Finished" value="finished" />
                      <ExcelColumn label="Cancelled" value="cancelled" />
                      <ExcelColumn label="Cancelled less than 1 hour" value="cancelled_less" />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
              <div className="cus-table" style={{ marginBottom: '20px' }}>
                <div className="ct-row ct-h">
                  <div className="ct-col">Teacher</div>
                  <div className="ct-col">Start Time</div>
                  <div className="ct-col">Duration</div>
                  <div className="ct-col ct-hf">Finished</div>
                  <div className="ct-col ct-hf">Cancelled less than 1 hour</div>
                </div>
                {sessions.map((sess) => {
                  const teacher_url = `/teacherprofile?id=${sess.teacher_uuid}`;
                  const sess_url = `/sessiondetails?id=${sess.session_uuid}`;
                  return (
                    <div className="ct-row" key={sess.session_uuid}>
                      <div className="ct-col">
                        <a className="link-no-dec" href={teacher_url}>
                          {sess.teacher_name}
                        </a>
                      </div>
                      <div className="ct-col ct-sm-font">
                        <a className="link-no-dec" href={sess_url}>
                          <div>
                            {moment(sess.start_time)
                              .tz(this.props.iana_timezone)
                              .format(day_format_year)}
                          </div>
                          <div>
                            {moment(sess.start_time)
                              .tz(this.props.iana_timezone)
                              .format(time_format)}
                          </div>
                        </a>
                      </div>
                      <div className="ct-col">{sess.duration}</div>
                      <div className="ct-col ct-si ct-hf">
                        {sess.session_status === 'FINISHED' ? (
                          <img
                            src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                            alt="Yes"
                          />
                        ) : (
                          <img
                            src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                            alt="No"
                          />
                        )}
                      </div>
                      <div className="ct-col ct-si ct-hf">
                        {sess.session_status === 'NO_SHOW_CANCELLATION_1HOUR' ? (
                          <img
                            src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                            alt="Yes"
                          />
                        ) : (
                          <img
                            src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                            alt="No"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  open_refund = (charge_id, currency_symbol) =>
    this.setState({ charge_id, currency_symbol, refund_open: true });

  close_refund = () =>
    this.setState({
      refund_open: false,
      confirm_resend: false,
      charge_confirm: false,
      charge_reason: '',
      charge_open: false,
      charge_amount: 0,
      discounts_open: false,
      discounts: []
    });

  render_discounts = () => {
    const { discounts } = this.state;
    return (
      <div>
        <h4>Discounts and extra credits</h4>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-sm-font">Type</div>
            <div className="ct-col ct-sm-font">Name</div>
            <div className="ct-col ct-sm-font">value</div>
            <div className="ct-col ct-sm-font">Code</div>
            <div className="ct-col ct-sm-font">Offer Name</div>
          </div>
          {discounts.map((d) => (
            <div className="ct-row">
              <div className="ct-col ct-sm-font">{d.type}</div>
              <div className="ct-col ct-sm-font">{d.name}</div>
              <div className="ct-col ct-sm-font">{d.value}</div>
              <div className="ct-col ct-sm-font">{d.coupon_code}</div>
              <div className="ct-col ct-sm-font">{d.coupon_code_name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render_subscriptions = () => {
    const { subscription_transactions, subscription_open } = this.state;
    const final_tranasctions = subscription_transactions.filter(
      (s) => s.subscription_status !== 'FUTURE'
    );
    return (
      <div>
        <div
          className="details-session-logs-header student-transactions"
          onClick={this.toggle_subscription_open}
        >
          Subscription Transactions: {final_tranasctions.length}
          {final_tranasctions.length !== 0 ? (
            <img
              className={subscription_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {final_tranasctions.length !== 0 && subscription_open ? (
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-sm-font">Invoice Id</div>
              <div className="ct-col ct-sm-font">Package</div>
              <div className="ct-col ct-hf ct-sm-font">Purchase Date</div>
              <div className="ct-col ct-hf ct-vsm-font">
                <div>Price</div>
                <div>(Ywf Repeat)</div>
              </div>
              <div className="ct-col ct-hf ct-vsm-font">Transaction Success</div>
              <div className="ct-col ct-hf ct-sm-font">Next Billing</div>
              <div className="ct-col ct-hf ct-sm-font">Discounts</div>
              <div className="ct-col ct-qf ct-sm-font">Refunded amount</div>
              <div className="ct-col ct-qf ct-sm-font">Refunded By</div>
              <div className="ct-col ct-qf ct-sm-font">Reason</div>
              <div className="ct-col ct-hf ct-sm-font">Refund</div>
              <div className="ct-col ct-hf ct-sm-font">Receipt</div>
            </div>
            {final_tranasctions.map((transaction) => (
              <div className="ct-row" key={transaction.invoice_id}>
                <div className="ct-col ct-hf ct-vsm-font">{transaction.invoice_id}</div>
                <div className="ct-col ct-vsm-font">{transaction.package_name}</div>
                <div className="ct-col ct-sm-font ct-hf">
                  <div>
                    {moment(transaction.purchase_date)
                      .tz(this.props.iana_timezone)
                      .format(day_format_year)}
                  </div>
                  <div>
                    {moment(transaction.purchase_date)
                      .tz(this.props.iana_timezone)
                      .format(time_format)}
                  </div>
                </div>
                <div className="ct-col ct-hf">
                  {`${transaction.currency_symbol} ${transaction.purchase_price} ${transaction.ywf_repeat_price > 0 ? `(${transaction.ywf_repeat_price})` : ''}`}
                </div>
                <div className="ct-col ct-hf ct-si">
                  {transaction.transaction_success === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-sm-font ct-hf">
                  <div>
                    {moment(transaction.next_billing_date)
                      .tz(this.props.iana_timezone)
                      .format(day_format_year)}
                  </div>
                  <div>
                    {moment(transaction.next_billing_date)
                      .tz(this.props.iana_timezone)
                      .format(time_format)}
                  </div>
                </div>
                <div className="ct-col ct-hf">
                  {/* <button className="ct-small-btn" onClick={() => this.view_sessions(transaction.invoice_id)}>View</button> */}
                  {!!transaction.discounts && transaction.discounts.length > 0 ? (
                    <button
                      className="ct-small-btn"
                      onClick={() => this.view_discounts(transaction.invoice_id)}
                    >
                      View
                    </button>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col ct-qf">{transaction.refund_amount}</div>
                <div className="ct-col ct-qf">{transaction?.refunded_by}</div>
                <div className="ct-col ct-qf">{transaction?.refund_reason}</div>
                <div className="ct-col ct-hf">
                  {transaction.purchase_price > 0 &&
                  transaction.purchase_price > transaction.refund_amount ? (
                    <button
                      className="ct-small-btn"
                      onClick={() =>
                        this.open_refund(transaction.charge_id, transaction.currency_symbol)
                      }
                    >
                      Refund
                    </button>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col ct-hf">
                  {transaction.purchase_price > 0 ? (
                    <button
                      className="ct-small-btn"
                      onClick={() => this.confirm_resend(transaction.order_id)}
                    >
                      Resend
                    </button>
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  confirm_resend = (order_id) => this.setState({ confirm_resend: true, order_id });

  initiate_refund = () =>
    this.setState({ loading: true }, () => {
      const { refund_amount, refund_reason, charge_id } = this.state;
      if (refund_reason) {
        const url = get_api_url('refund_transaction');
        const payload = { charge_id, refund_reason };
        if (refund_amount > 0) {
          payload.amount = refund_amount.toString();
        }
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Refund added');
            this.setState({ refund_open: false });
            this.load_transaction_details();
          })
          .catch((e) => {
            let err_msg = 'Some error occured. Please contact dev team';
            try {
              const new_err_msg = e.response.data.message || e.response.data.reason;
              if (new_err_msg) {
                err_msg = new_err_msg;
              }
            } catch {}
            this.props.set_notification_variable(true, 'error', err_msg);
            this.setState({ loading: false });
          });
      } else {
        this.setState({ create_err: true, loading: true });
      }
    });

  resend_transaction_reciept = () =>
    this.setState({ loading: true }, () => {
      const { order_id } = this.state;

      const url = get_api_url('resend_receipt');
      const payload = { order_id };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Receipt Sent');
          this.setState({ refund_open: false, confirm_resend: false, loading: false });
        })
        .catch((e) => {
          let err_msg = 'Some error occured. Please contact dev team';
          try {
            const new_err_msg = e.response.data.message || e.response.data.reason;
            if (new_err_msg) {
              err_msg = new_err_msg;
            }
          } catch {}
          this.props.set_notification_variable(true, 'error', err_msg);
          this.setState({ loading: false });
        });
    });

  render_refund = () => {
    const { charge_id, refund_amount, refund_reason, create_err, currency_symbol } = this.state;
    const err_sty = create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const symbol = currency_symbol || '$';
    if (charge_id) {
      return (
        <div>
          <h4>Send refund to user</h4>
          <CustomInput
            label={`Refund amount (${symbol})`}
            onChange={this.on_number_change}
            name="refund_amount"
            value={refund_amount}
            type="number"
            style={{ width: '150px' }}
          />
          <CustomInput
            label="Refund reason"
            onChange={this.handle_change}
            name="refund_reason"
            value={refund_reason}
            style={{ width: '300px' }}
          />
          <button onClick={this.initiate_refund}>Submit</button>
          <p style={err_sty}>Please enter reason</p>
        </div>
      );
    }
    return (
      <div>
        <h4>No charge id to refund</h4>
      </div>
    );
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseFloat(e.target.value) });

  handle_change = (e) => this.setState({ [e.target.name]: e.target.value });

  render_confirm_resend = () => (
    <div>
      <h4>Resend Receipt?</h4>
      <button onClick={this.resend_transaction_reciept}>Confirm</button>
      <button onClick={this.close_refund}>Back</button>
    </div>
  );

  charge_user = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('charge_user');
      const { charge_amount, charge_reason, charge_confirm, student_uuid } = this.state;
      const payload = { student_uuid, amount: charge_amount, reason: charge_reason };
      post_api(url, payload, true)
        .then((res) => {
          this.close_refund();
          this.load_transaction_details();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_charge = () => {
    const { charge_amount, charge_reason, charge_confirm } = this.state;
    if (charge_confirm) {
      return (
        <div>
          <h4>Are you sure you want to charge user?</h4>
          <p>{`Amount: $${charge_amount}`}</p>
          <p>{`Reason: ${charge_reason}`}</p>
          <button onClick={this.charge_user}>Confirm</button>
          <button onClick={() => this.setState({ charge_confirm: false })}>Cancel</button>
        </div>
      );
    }
    return (
      <div className="num-input-no-arr">
        <h4>Charge user</h4>
        <CustomInput
          label="Charge amount ($)"
          onChange={this.on_number_change}
          name="charge_amount"
          value={charge_amount}
          type="number"
          style={{ width: '150px' }}
        />
        <CustomInput
          label="Charge reason*"
          onChange={this.handle_change}
          name="charge_reason"
          value={charge_reason}
          style={{ width: '300px' }}
        />
        <Button
          disabled={!charge_reason || charge_amount <= 0}
          onClick={() => this.setState({ charge_confirm: true })}
        >
          Submit
        </Button>
      </div>
    );
  };

  render_function = () => {
    const { loading, charge_open, discounts_open } = this.state;
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const title = `Student transactions of ${this.state.user_name ? this.state.user_name.toUpperCase() : null}`;
    return (
      <CommonHeader loading={loading} title={title} show_back>
        <button onClick={() => this.setState({ charge_open: true })}>Charge User</button>
        <CustomModal show={this.state.refund_open} close={this.close_refund}>
          {this.render_refund()}
        </CustomModal>
        <CustomModal show={this.state.confirm_resend} close={this.close_refund}>
          {this.render_confirm_resend()}
        </CustomModal>
        <CustomModal show={charge_open} close={this.close_refund}>
          {this.render_charge()}
        </CustomModal>
        <CustomModal show={discounts_open} close={this.close_refund}>
          {this.render_discounts()}
        </CustomModal>
        {this.render_session_transactions()}
        {this.render_single_transactions()}
        {this.render_subscriptions()}
        {this.render_sessions()}
      </CommonHeader>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Student Transactions</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTransactions);
