import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  time_day_format,
  mapped_student_timeslots,
  day_format_full_year,
  time_format
} from '../../../constants';
import moment from 'moment-timezone';
import './cst.css';
import { array } from 'prop-types';

class StudentProfile extends Component {
  render_second_td = (format) => {
    try {
      const value = this.props.data[format.key];
      const td_class = 'cst-single-td cst-second-td';
      const suffix = format.suffix ? format.suffix : '';

      if (format.key === 'credits_available') {
        if (this.props?.data?.subscription_info?.is_subscribed === 1) {
          const lastTrasactionDate = new Date(this.props?.data?.last_transaction_date);
          if (lastTrasactionDate > new Date()) {
            <div className={td_class}>{value ? `${value} ${suffix}` : '--'}</div>;
          } else if (
            this.props?.data?.subscription_info?.is_subscribed?.my_account_membership
              ?.hours_remaining !== null
          ) {
            const final_value =
              this.props?.data?.subscription_info?.my_account_membership?.hours_remaining * 39;
            return <div className={td_class}>{final_value}</div>;
          }
        }
      }
      if (format.type === 'time_day_format') {
        return (
          <div className={td_class}>
            {value ? moment(value).tz(this.props.iana_timezone).format(time_day_format) : '--'}
          </div>
        );
      }
      if (format.type === 'day_format_full_year') {
        return (
          <div className={td_class}>
            {value
              ? `${moment(value)
                  .tz(this.props.iana_timezone)
                  .format(day_format_full_year)} ${moment(value)
                  .tz(this.props.iana_timezone)
                  .format(time_format)}`
              : '--'}
          </div>
        );
      }
      if (format.type === 'yes_no') {
        return <div className={td_class}>{value === 1 ? 'YES' : 'NO'}</div>;
      }
      if (format.type === 'list_join') {
        const final_value = value ? value.join(' , ') : '--';
        return <div className={td_class}>{final_value}</div>;
      }
      if (format.type === 'list_ul') {
        const final_value = value || [];
        if (final_value.length > 0) {
          return (
            <div className={td_class}>
              <ul>
                {final_value.map((val) => (
                  <li key={val}>{val}</li>
                ))}
              </ul>
            </div>
          );
        }
        return <div className={td_class}>--</div>;
      } if (format.type === 'link') {
        return (
          <div className={td_class}>
            <a href={value} rel="noreferrer noopener" target="_blank">
              {value}
            </a>
          </div>
        );
      } if (format.type === 'list_of_links') {
        return (
          <div className={td_class}>
            {value.length > 0
              ? value.map((item) => (
                    <li>
                      <a href={item?.file_path} rel="noreferrer noopener" target="_blank">
                        {item?.file_name}
                      </a>
                    </li>
                  ))
              : '--'}
          </div>
        );
      } if (format.type === 'list') {
        const data_list = value?.split(format.list_delim);
        return (
          <div className={td_class}>
            <ul>
              {data_list?.map((l_item) => {
                if (l_item) {
                  return <li key={l_item}>{l_item}</li>;
                }
                return null;
              })}
            </ul>
          </div>
        );
      } if (format.type === 'string_array') {
        const parsed_data_list = JSON.parse(value);
        const data_list = parsed_data_list.sort();
        return (
          <div className={td_class}>
            <ul>
              {data_list?.map((l_item) => {
                if (l_item) {
                  return <li key={l_item}>{mapped_student_timeslots[`${l_item}`]}</li>;
                }
                return null;
              })}
            </ul>
          </div>
        );
      } 
        return <div className={td_class}>{value ? `${value} ${suffix}` : '--'}</div>;
      
    } catch (e) {
      console.log('catch error', e);
    }
  };

  render_single_row = (format, index) => {
    const row_class = format.full_row ? 'cst-single-row cst-single-row-full' : 'cst-single-row';
    if (typeof format.show_row === 'undefined' || format.show_row) {
      return (
        <div className={row_class} key={index + format.title}>
          <div className="cst-single-td cst-first-td">{format.title}: </div>
          {this.render_second_td(format)}
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="cst-container">{this.props.data_format.map(this.render_single_row)}</div>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone
  });

export default connect(mapStateToProps)(StudentProfile);
