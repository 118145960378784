import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import * as actions from '../../../redux/action_creators';
import { default_timezone, time_day_format } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';

export default function Sample() {
  const iana_timezone = useSelector((state) => state.home.iana_timezone);
  const [loading, setLoading] = useState(true);
  const [repeatGroupSessions, setRepeatGroupSessions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const url = get_api_url('get_repeat_group_sessions');
    post_api(url, null, true)
      .then((resp) => {
        setRepeatGroupSessions(resp.data.group_class_pages);
        setLoading(false);
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        dispatch(actions.set_notification_variable(true, 'error', err_message));
      });
  };

  const renderRepeatSessions = () => (
      <table>
        <thead>
          <tr>
            <th>Class Page</th>
            <th>Teacher</th>
            <th>Repeat Time</th>
            <th>Count</th>
            <th>Timings</th>
          </tr>
        </thead>
        <tbody>
          {repeatGroupSessions.map((r) => r.repeat_group_sessions.map((s, i) => {
              const new_date = moment().day(s.day_of_the_week).format('YYYY-MM-DD');
              const new_start_time = moment(`${new_date} ${s.time_of_the_day}`).valueOf();
              const background_color =
                s.future_sessions === 4
                  ? '#008000'
                  : `rgba(237, 77, 77, ${1 - 0.25 * s.future_sessions})`;
              const text_color = s.future_sessions === 4 ? '#FFFFFF' : `#000`;
              const background_style = { backgroundColor: background_color, color: text_color };

              const { pst_time_of_the_day, pst_day_of_the_week, time_of_the_day, day_of_the_week } =
                s;
              let final_day_of_the_week = s.day_of_the_week;
              let final_time_of_the_day = s.time_of_the_day;
              if (pst_time_of_the_day) {
                final_day_of_the_week = pst_day_of_the_week;
                final_time_of_the_day = pst_time_of_the_day;
              }
              const session_date = moment()
                .tz(default_timezone)
                .day(final_day_of_the_week)
                .format('YYYY-MM-DD');
              const session_time = moment
                .tz(`${session_date} ${final_time_of_the_day}`, default_timezone)
                .utc();
              return (
                <tr key={r.uuid + i}>
                  {i === 0 ? (
                    <td rowSpan={r.repeat_group_sessions.length} className="ct-grey-br">
                      <a
                        href={`/group_class_page/${r.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {r.name}
                      </a>
                    </td>
                  ) : null}
                  <td style={background_style}>{s.teacher_name}</td>
                  <td style={background_style}>
                    <div>Every {session_time.tz(iana_timezone).format('dddd')}</div>
                    <div>{session_time.tz(iana_timezone).format('hh:mm A z')}</div>
                  </td>
                  <td style={background_style}>{s.future_sessions}</td>
                  <td style={background_style}>
                    {!!s.sessions_list &&
                      s.sessions_list.map((ss) => (
                          <div style={{ fontSize: '12px' }}>
                            {moment(ss.start_time).tz(iana_timezone).format(time_day_format)}
                          </div>
                        ))}
                  </td>
                </tr>
              );
            }))}
        </tbody>
      </table>
    );

  return (
    <CommonHeader loading={loading} title="Repeat Group Sessions">
      {renderRepeatSessions()}
    </CommonHeader>
  );
}
