import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import CreateEditWorkshop from './create_edit_workshop';
import { image_error_replace } from '../../../util_functions';
import { day_format, time_format, audience_types } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class Workshops extends Component {
  state = {
    loading: false,
    workshops: [],
    create_workshop: false,
    is_present: true,
    show_bootcamps: false
  };

  componentDidMount() {
    // this.load_data()
    if (this.props.worshops_status === 'none') {
      this.props.get_all_workshops();
    }
  }

  get_audience_name = (val) => audience_types.find((a) => a.value === val).label;

  toggle_present = () => this.setState({ is_present: !this.state.is_present });

  load_data = () => {
    const url = get_api_url('list_all_workshops');
    post_api(url, null, true).then((res) =>
      this.setState({ workshops: [...res.data.workshops], loading: false })
    );
  };

  go_back = () => this.props.history.goBack();

  open_workshop_details = (uuid) => {
    this.props.history.push(`/workshop/${uuid}`);
  };

  render_single_workshop = (workshop) => {
    const teacher_url = `/teacherprofile?id=${  workshop.teacher.uuid}`;
    return (
      <div className="ct-row" key={workshop.uuid}>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={workshop.teacher.profile_photo} onError={image_error_replace} alt="x" />
              {workshop.teacher.first_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-la ct-sm-font">{workshop.name}</div>
        <div className="ct-col ct-sm-font">
          <div>{moment(workshop.start_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(workshop.start_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-sm-font">{this.get_audience_name(workshop.audience_type)}</div>
        <div className="ct-col ct-sm-font ct-hf">
          {workshop.is_active === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col ct-sm-font ct-hf">{workshop.seats_limit}</div>
        <div className="ct-col ct-sm-font ct-hf">{workshop.seats_enrolled}</div>
        <div className="ct-col">
          <button
            className="ct-small-btn"
            onClick={() => this.open_workshop_details(workshop.uuid)}
          >
            Details
          </button>
        </div>
      </div>
    );
  };

  render_workshops = () => {
    const { is_present, show_bootcamps } = this.state;
    let workshops = [];
    if (show_bootcamps) {
      workshops = this.props.workshops.filter((w) => w.audience_type === 4);
    } else {
      workshops = this.props.workshops.filter((w) => {
        if (is_present) return w.is_past_workshop === 0;
        return w.is_past_workshop === 1;
      });
    }
    if (workshops.length > 0) {
      return (
        <div style={{ marginTop: '20px' }}>
          {show_bootcamps ? 'Programs: ' : 'Workshops: '}
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-la">
                <span className="ct-ml25">Teacher</span>
              </div>
              <div className="ct-col ct-la">Name</div>
              <div className="ct-col ct-sm-font">Start Date</div>
              <div className="ct-col ct-sm-font">Type</div>
              <div className="ct-col ct-sm-font ct-hf">Active</div>
              <div className="ct-col ct-sm-font ct-hf">Limit</div>
              <div className="ct-col ct-sm-font ct-hf">Enrolled</div>
              <div className="ct-col">Details</div>
            </div>
            {workshops.map(this.render_single_workshop)}
          </div>
        </div>
      );
    }
    return null;
  };

  create_workshop = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_workshop');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Workshop created');
          this.close_create_workshop();
          this.props.get_all_workshops();
          this.setState({ loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_create_workshop = () => this.setState({ create_workshop: true });

  close_create_workshop = () => this.setState({ create_workshop: false });

  toggle_bootcamp = () =>
    this.setState({ show_bootcamps: !this.state.show_bootcamps, is_present: true });

  render() {
    const { loading, is_present, show_bootcamps } = this.state;
    const { worshops_status } = this.props;
    const {host} = window.location;
    const subdomain = host.split('.')[0];
    const url_domain =
      subdomain === 'hridaya'
        ? 'https://myyogateacher.com'
        : 'https://student-testenv4.myyogateacher.com';
    return (
      <CommonHeader
        show_back
        loading={loading || worshops_status === 'loading'}
        title="Workshops"
      >
        <CustomModal show={this.state.create_workshop} close={this.close_create_workshop}>
          <CreateEditWorkshop submit_workshop={this.create_workshop} />
        </CustomModal>
        <Button onClick={this.open_create_workshop}>Create Workshop</Button>
        <a
          className="link-btn"
          href={`${url_domain  }/workshops?preview=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Preview
        </a>
        {!show_bootcamps ? (
          <Button onClick={this.toggle_present}>
            {is_present ? `Past Workshops` : `Present Workshops`}
          </Button>
        ) : null}
        <button onClick={this.toggle_bootcamp}>{show_bootcamps ? 'Workshops' : 'Programs'}</button>
        {this.render_workshops()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    config_status: state.loading.config_status,
    config_values: state.home.config_values,
    worshops_status: state.loading.worshops_status,
    workshops: state.workshops.workshops
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_all_workshops: () => {
      dispatch(actions.get_all_workshops());
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(Workshops);
