import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { groupBy } from '../../../../util_functions';
import './charts.css';

export default class TransCharts extends Component {
  componentDidMount() {
    this.setSinTransByPrice(this.props.data);
    this.setSinTransByTeacher(this.props.data);
  }

  setSinTransByPrice = (data) => {
    const chart = am4core.create('sinTransBPrice', am4charts.PieChart);
    const processed_data = groupBy('purchase_price', data);
    const final_data = Object.keys(processed_data).map((key) => ({
        x: `$${  key}`,
        y: processed_data[key].length
      }));
    chart.data = final_data;
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'y';
    pieSeries.dataFields.category = 'x';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.text = '{x}';
    pieSeries.slices.template.tooltipText = '{x}: {y}';
  };

  setSinTransByTeacher = (data) => {
    const chart = am4core.create('sinTransBTeach', am4charts.PieChart);
    const processed_data = groupBy('teacher_uuid', data);
    const final_data = Object.keys(processed_data).map((key) => ({
        x: processed_data[key][0].teacher_name,
        y: processed_data[key].length
      }));
    chart.data = final_data;
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'y';
    pieSeries.dataFields.category = 'x';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.text = '{x}';
    pieSeries.slices.template.tooltipText = '{x}: {y}';
  };

  render() {
    return (
      <div className="custom-charts-cont">
        <div className="custom-chart-small">
          <p>Transactions By Price</p>
          <div className="custom-chart-pie" id="sinTransBPrice" />
        </div>
        <div className="custom-chart-small">
          <p>Paid session by Teacher</p>
          <div className="custom-chart-pie" id="sinTransBTeach" />
        </div>
      </div>
    );
  }
}
