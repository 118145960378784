import * as t from '../action_types';

const initialState = {
  avail_all: []
};

const set_reports_variable = (state, payload) => ({
    ...state,
    [payload.key]: payload.payload
  });

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_reports_variable:
      return set_reports_variable(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
