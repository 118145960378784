import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/action_creators';
import { api_with_method } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import Button from '../../../util_components/button';
import CustomLoading from '../../../util_components/custom_loading';
import CustomSingleSelect from '../../../util_components/custom_single_select';

function AddCreditForMembership(props) {
  const customAmount = [
    { value: 50, label: '$50' },
    { value: 100, label: '$100' }
  ];
  const [amount, setAmount] = useState();
  const [reasonForAward, setReasonForAward] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const dispatach = useDispatch();

  const handleReasonChange = (e) => {
    if (e.target && e.target.value) {
      setReasonForAward(e.target.value);
      resetError();
    }
  };
  const handleCustomAmountChange = (e) => {
    if (e.target && e.target.value) {
      setAmount(e.target.value);
      resetError();
    }
  };

  const resetError = () => {
    setShowError(false);
    setError('');
  };

  const handleSubmit = async () => {
    if (typeof amount === 'undefined' || Number.isNaN(amount)) {
      setShowError(true);
      setError('Please Select Amount');
    } else if (reasonForAward === '') {
      setShowError(true);
      setError('Please enter the reason');
    } else {
      const payload = {
        student_uuid: props.studentUUID,
        credits: Number(amount),
        reason: reasonForAward
      };

      const url = get_api_url('/v2/payments/cms/membership_credits', null, true);
      setLoading(true);
      try {
        await api_with_method('post', url, payload, true);
        closeCustomAmountChange();
        dispatach(actions.set_notification_variable(true, 'success', 'Credit Added'));
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.reason);
        setShowError(true);
      }
    }
  };

  const closeCustomAmountChange = () => {
    setShowError(false);
    setError('');
    setAmount('');
    setReasonForAward('');
    props.closeCreditForMembership();
  };

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <div>
          <div className="flex items-center justify-center">Credits for Membership Renewal</div>
          <div className="flex flex-col">
            <div>
              <CustomSingleSelect
                label="*Select Amount"
                options={customAmount}
                onChange={handleCustomAmountChange}
                value={amount}
              />
            </div>
            <div>
              <div>*Reason for Award:</div>
              <textarea
                value={reasonForAward}
                name="reasonForAward"
                onChange={handleReasonChange}
              />
            </div>
          </div>
          <div className="flex justify-end items-center mt-4">
            <div>
              <Button type="secondary" onClick={closeCustomAmountChange}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
          {showError ? (
            <div className="font-bold text-red-700 mt-4 text-center">{error}</div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default AddCreditForMembership;
