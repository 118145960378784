import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';
import './promote_grp_class.css';
import CustomSingleSelect from "../../util_components/custom_single_select";
import CustomInput from "../../util_components/custom_input";
import { session_duration_list, target_audience_names } from '../../../constants';
import moment from 'moment-timezone';

import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

import MultiCheckBox from "../../util_components/multi_check_box";

class PromoteGrpClass extends Component {
  constructor(props) {
    super(props);

    const selectedItem =
      this.props.history.location.state && this.props.history.location.state.item
        ? this.props.history.location.state.item
        : null;

    if (selectedItem == null) {
      this.props.history.push('community_class');
    }

    const level_selected = target_audience_names.find((target_audience) => target_audience.label === selectedItem.proposal.level);

    this.state = {
      showRequestList: false,
      teachers: [],
      session_title: selectedItem.proposal.name,
      target_audience: level_selected.label,
      start_date: moment
        .tz(selectedItem.proposal.datetime, this.props.iana_timezone)
        .format('YYYY-MM-DD'),

      start_time: new DateObject().set({
        hour: moment.tz(selectedItem.proposal.datetime, this.props.iana_timezone).format('HH'),
        minute: moment.tz(selectedItem.proposal.datetime, this.props.iana_timezone).format('mm')
      }),
      all_goals: [],
      goals: selectedItem.proposal.goals,
      session_desc: selectedItem.proposal.description,

      teacher_selected: '',
      session_duration: selectedItem.proposal.duration,
      selectedItem
    };
  }

  componentDidMount() {
    // this.props.promote_form_info();

    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }

    if (this.props.config_status === 'success') {
      this.setGoals();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }

    if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
      this.setGoals();
    }

    if (
      prevProps.get_proposal_promote_status === 'loading' &&
      this.props.get_proposal_promote_status === 'success'
    ) {
      alert('Success');
      this.props.history.push('community_class');
    }
  }

  setGoals = () => {
    const all_goals = this.props.config_values.yoga_goals;

    this.setState({ all_goals });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;

    this.setState({
      [key]: value
    });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.onboarding_status === 'APPROVED');

    const selectedTeacher = all_teachers.find((teacher) => teacher.uuid === this.state.selectedItem.acceptor.uuid);

    this.setState({
      teachers: all_teachers,
      teacher_selected: selectedTeacher ? selectedTeacher.uuid : '',
      teachers_loading: false
    });
  };

  set_date = (date) => {
    // const start_date = date.join("\n");
    // let dateArray = [];
    // start_date.split("\n").map((d) => {
    //   dateArray.push(d);
    // });

    this.setState({ start_date: date });
  };

  set_time = (time) => {
    this.setState({ start_time: time });
  };

  save_goals = (goals) => this.setState({ goals });

  render() {
    const teachers_list = this.state.teachers.map((teacher) => ({
        value: teacher.uuid,
        label: `${teacher.first_name} ${teacher.last_name}`
      }));
    const { all_goals, goals } = this.state;

    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');

    return (
      <div style={{ width: '100%' }}>
        <div className="promote-table">
          <div className="profile-go-back" onClick={() => this.props.history.goBack()}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="title-div title_bold">Promote to regular group class</div>
          <div>
            <CustomSingleSelect
              label="Select a teacher"
              options={teachers_list}
              name="teacher_selected"
              onChange={this.onCommonChange}
              value={this.state.teacher_selected}
              style={{ width: '300px' }}
            />

            <CustomInput
              label="Enter session title"
              onChange={this.onCommonChange}
              name="session_title"
              value={this.state.session_title}
              style={{ width: '300px' }}
            />
          </div>

          <CustomSingleSelect
            label="Select session duration"
            options={session_duration_list}
            onChange={this.onCommonChange}
            name="session_duration"
            value={this.state.session_duration}
            style={{ width: '250px' }}
          />

          <CustomSingleSelect
            label="Select target audience"
            options={target_audience_names}
            onChange={this.onCommonChange}
            name="target_audience"
            value={this.state.target_audience}
            style={{ width: '250px' }}
          />

          <div>
            <div>{`Select date and time (${curr_timezone})`}</div>
            <DatePicker
              // minDate={new Date().setDate(new Date().getDate())}
              // maxDate={new Date().setDate(new Date().getDate() + 6)}
              value={this.state.start_date}
              // multiple
              format="YYYY-MM-DD"
              onChange={this.set_date}
            />
            <DatePicker
              // minDate={new Date().setDate(new Date().getDate())}
              // maxDate={new Date().setDate(new Date().getDate() + 6)}
              value={this.state.start_time}
              placeholder="Select Time"
              disableDayPicker
              onChange={this.set_time}
              format="HH:mm"
              plugins={[<TimePicker hideSeconds />]}
            />
          </div>
          {all_goals.length > 0 ? (
            <MultiCheckBox
              title="Select goals: "
              all_values={all_goals}
              curr_values={goals}
              save_values={this.save_goals}
            />
          ) : null}

          <div className="title-div">Enter session description:</div>
          <textarea
            style={{ width: '500px', height: '200px' }}
            value={this.state.session_desc}
            name="session_desc"
            onChange={this.onCommonChange}
            maxLength="2000"
          />
          <button
            onClick={() => {
              if (
                !!this.state.teacher_selected &&
                !!this.state.target_audience &&
                !!this.state.session_desc &&
                this.state.goals.length > 0 &&
                !!this.state.start_time.toString()
              ) {
                const weekday = moment
                  .tz(this.state.start_date.toString(), this.props.iana_timezone)
                  .utc()
                  .format('dddd');

                const dt =
                  `${this.state.start_date.toString()  } ${  this.state.start_time.toString()}`;

                const startTime = moment.tz(dt, this.props.iana_timezone).utc().format('HH:mm:ss');

                const params = {
                  proposal_id: this.state.selectedItem.proposal.uuid,
                  teacher_id: this.state.teacher_selected,
                  target_audience: this.state.target_audience,
                  name: this.state.selectedItem.proposal.name,
                  description: this.state.session_desc,
                  start_time: startTime,
                  weekday,
                  duration: parseInt(this.state.session_duration),
                  goals: this.state.goals
                };

                console.log('params', params);
                this.props.promote_proposal(params);
              } else {
                alert('Some fields are empty');
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    config_status: state.loading.config_status,
    iana_timezone: state.home.iana_timezone,
    teachers_list: state.teachers.teachers_list,
    teachers_list_status: state.teachers.teachers_list_status,
    config_values: state.home.config_values,
    get_proposal_promote_status: state.loading.get_proposal_promote_status
  };
}

function mapDispatchToProps(dispatch) {
  return {
    promote_form_info: (params) => dispatch(actions.promote_form_info(params)),
    promote_proposal: (params) => dispatch(actions.promote_proposal(params)),
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoteGrpClass);
