import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from "../../util_components/common_header";
import CustomModal from "../../util_components/custom_modal";
import { image_error_replace } from '../../../util_functions';
import CustomInput from "../../util_components/custom_input";
import CustomCheckBox from "../../util_components/custom_checkbox";
import Button from "../../util_components/button";
import CustomSingleSelect from "../../util_components/custom_single_select";
import { api_with_method } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class Groups extends Component {
  state = {
    loading: false,
    create_open: false,
    discount: 0,
    is_price_split: 1,
    non_repeat_group: 0,
    sessions_per_week: 0,
    group_name: '',
    ywf_repeat_groups: [],
    student_search_text: '',
    add_student_uuid: '',
    participant_price_60: 0
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('ywf_repeat_group');
      api_with_method('post', url, null, true).then((res) => {
        this.setState({ loading: false, ywf_repeat_groups: [...res.data.ywf_repeat_groups] });
      });
    });
  };

  create_group = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('ywf_repeat_group');
      const {
        discount,
        is_price_split,
        non_repeat_group,
        add_student_uuid,
        participant_price_60,
        group_name,
        sessions_per_week
      } = this.state;
      const payload = {
        discount,
        is_price_split: parseInt(is_price_split),
        non_repeat_group: parseInt(non_repeat_group),
        student_uuid: add_student_uuid,
        name: group_name,
        sessions_per_week,
        participant_price_60: parseInt(participant_price_60)
      };
      api_with_method('put', url, payload, true)
        .then((res) => {
          const {ywf_repeat_group} = res.data;
          this.props.history.push(`/ywf_repeat_groups/${ywf_repeat_group.uuid}`);
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value });
  };

  open_create = () => this.setState({ create_open: true });

  close_create = () =>
    this.setState(
      {
        create_open: false,
        discount: 0,
        is_price_split: 1,
        add_student_uuid: '',
        student_search_text: '',
        group_name: '',
        sessions_per_week: 0,
        participant_price_60: 0
      },
      () => this.props.set_loading_variable('student_search_status', 'none')
    );

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  toggle_split = () => this.setState({ is_price_split: this.state.is_price_split === 1 ? 0 : 1 });

  toggle_non_repeat = () =>
    this.setState({ non_repeat_group: this.state.non_repeat_group === 1 ? 0 : 1 });

  on_search_click = () => {
    const {student_search_text} = this.state;
    if (student_search_text.trim().length < 3) {
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.props.search_students({
        search_param: student_search_text
      });
    }
  };

  render_create = () => {
    const {
      is_price_split,
      participant_price_60,
      add_student_uuid,
      non_repeat_group,
      student_search_text,
      group_name,
      sessions_per_week
    } = this.state;
    const student_select_values = [];
    this.props.search_students_list.map((st) => {
      const {student_uuid} = st;
      if (student_uuid) {
        const fname = st.first_name ? st.first_name : '';
        const mname = st.middle_name ? st.middle_name : '';
        const lname = st.last_name ? st.last_name : '';
        student_select_values.push({
          label: `${fname} ${mname} ${lname} (${st.email})`,
          value: student_uuid
        });
      }
      return null;
    });
    return (
      <div>
        <h2>Create Group</h2>
        <CustomInput
          label="Enter group name*"
          onChange={this.onCommonChange}
          name="group_name"
          value={group_name}
          style={{ width: '300px' }}
        />
        <div className="display-flex-between-center">
          <CustomInput
            label="Enter student name or email"
            onChange={this.onCommonChange}
            name="student_search_text"
            value={student_search_text}
            style={{ width: '300px' }}
            onKeyPress={this.on_key_press}
          />
          <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
            Search student
          </Button>
        </div>
        {!!student_search_text && this.props.student_search_status === 'success' ? (
          <CustomSingleSelect
            label="Select Host"
            options={student_select_values}
            onChange={this.onCommonChange}
            name="add_student_uuid"
            value={add_student_uuid}
            style={{ width: '250px' }}
          />
        ) : null}
        <CustomInput
          label="Participant Price ($)"
          onChange={this.on_number_change}
          name="participant_price_60"
          value={participant_price_60}
          type="number"
          style={{ width: '150px' }}
        />
        <CustomInput
          label="Sessions per Week"
          onChange={this.on_number_change}
          name="sessions_per_week"
          value={sessions_per_week}
          type="number"
          style={{ width: '150px' }}
        />
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Price split"
            checked={is_price_split}
            onClick={this.toggle_split}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Non repeat Group"
            checked={non_repeat_group}
            onClick={this.toggle_non_repeat}
          />
        </div>
        <Button disabled={!add_student_uuid || !group_name} onClick={this.create_group}>
          Create
        </Button>
      </div>
    );
  };

  render_groups = () => {
    const { ywf_repeat_groups } = this.state;
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-hf ct-la ct-pl-10">Host</div>
          <div className="ct-col">Name</div>
          <div className="ct-col ct-qf">Participant price($)</div>
          <div className="ct-col ct-qf">Price Split</div>
          <div className="ct-col ct-qf">Active</div>
          <div className="ct-col ct-qf">Details</div>
        </div>
        {ywf_repeat_groups.map((group) => {
          const student_url = `/studentprofile?id=${  group.host_uuid}`;
          return (
            <div className="ct-row" key={group.uuid}>
              <div className="ct-col ct-hf ct-sm-font">
                <a className="link-no-dec" href={student_url}>
                  <div className="profile-img-name">
                    <img src={group.host_photo} alt="i" onError={image_error_replace} />
                    {group.host_name}
                  </div>
                </a>
              </div>
              <div className="ct-col">{group.name}</div>
              <div className="ct-col ct-qf">{group.participant_price_60}</div>
              <div className="ct-col ct-qf ct-si">
                {group.is_price_split === 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>
              <div className="ct-col ct-qf ct-si">
                {group.is_active === 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>
              <div className="ct-col ct-qf">
                <a href={`/ywf_repeat_groups/${group.uuid}`} className="link-btn">
                  Details
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { loading, create_open, ywf_repeat_groups } = this.state;
    const show_loading = this.props.student_search_status === 'loading' || loading;
    return (
      <CommonHeader loading={show_loading} title="YWF Groups">
        <div>
          <button onClick={this.open_create}>Create Group</button>
        </div>
        {ywf_repeat_groups.length > 0 ? this.render_groups() : null}
        <CustomModal show={create_open} close={this.close_create}>
          {this.render_create()}
        </CustomModal>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    search_students_list: state.students.search_students_list,
    student_search_status: state.loading.student_search_status
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    search_students: (payload) => {
      dispatch(actions.search_students(payload));
    },
    set_loading_variable: (key, payload) => {
      dispatch(actions.set_loading_variable(key, payload));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
