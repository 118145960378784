import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Paginator extends PureComponent {
  state = {
    boxes_limit: 5
  };

  on_page_click = (e) => {
    const page = parseInt(e.currentTarget.dataset.page);
    const {curr_page} = this.props;
    if (curr_page !== page) {
      this.props.pageChange(page);
    }
  };

  render_boxes = () => {
    const { curr_page, page_limit } = this.props;
    if (page_limit > 1) {
      const { boxes_limit } = this.state;
      let offset = 0;
      let b_limit = page_limit;
      if (page_limit > boxes_limit) {
        const div = Math.floor((boxes_limit + 1) / 2);
        offset = Math.min(Math.max(0, curr_page - div), page_limit - boxes_limit);
        b_limit = boxes_limit;
      }
      const box_arr = [...Array(b_limit).keys()].map((i) => i + 1 + offset);
      return box_arr.map((b) => {
        const sel_class = b === curr_page ? ' pgn-sel' : '';
        return (
          <div data-page={b} className={`pgn-box${  sel_class}`} onClick={this.on_page_click} key={b}>
            {b}
          </div>
        );
      });
    }
    return null;
  };

  render() {
    return <div className="pgn-con">{this.render_boxes()}</div>;
  }
}

Paginator.propTypes = {
  curr_page: PropTypes.number.isRequired,
  page_limit: PropTypes.number.isRequired,
  pageChange: PropTypes.func.isRequired
};

Paginator.defaultProps = {
  type: 'text',
  label: 'Enter Label'
};

export default Paginator;
