import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';
import CommonHeader from "../../util_components/common_header";
import './content.css';

class TagAssociations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      association_type: '',
      association_id: '',
      slug: '',
      all_tags: [],
      selected_tags: [],
      association_type_name: 'BLOG'
    };
  }

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const title = url.searchParams.get('title');
    const slug = url.searchParams.get('slug');
    const urlParams = this.props.match.params;
    const association_type = urlParams?.association_type
      ? urlParams.association_type.charAt(0).toUpperCase() + urlParams.association_type.slice(1)
      : '';
    const association_id = urlParams?.association_id ? urlParams?.association_id : '';
    this.setState({
      association_type,
      association_id,
      title,
      slug
    });
    if (association_id) {
      if (association_type.toLowerCase() === 'blogs') {
        const url = `/v2/entity_tags/associations/blogs/${association_id}`;
        this.props.getAllAssociatedTags({ url });
      }
    }
    // if(this.props.get_all_associated_tags_status ==='success'){
    //     this.setState({
    //         loading : false,
    //         all_tags : this.props.get_all_associated_tags
    //     })
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_all_associated_tags_status === 'loading' &&
      this.props.get_all_associated_tags_status === 'success'
    ) {
      this.setState({
        loading: false,
        all_tags: this.props.get_all_associated_tags
      });
    }
    if (
      prevProps.update_tag_associations_status === 'loading' &&
      this.props.update_tag_associations_status === 'success'
    ) {
      this.setState({
        loading: false
      });
    }
  }

  updateSelectedTags(selected_tags_list, item) {
    const selected_tag_index = selected_tags_list.findIndex(
      (_item) => _item.tag_uuid === item.tag_uuid
    );
    if (selected_tag_index > -1) {
      return (selected_tags_list[selected_tag_index] = item);
    } 
      return selected_tags_list.push(item);
    
  }

  onTagSelect = (tag_data, category) => {
    const tag_obj = tag_data;
    tag_obj.is_tagged = tag_data.is_tagged === 1 ? 0 : 1;
    const tagIndex = category.tags.findIndex((t) => t.uuid === tag_data.uuid);
    category.tags[tagIndex] = tag_obj;
    const {all_tags} = this.state;
    const filtered_category_index = all_tags.findIndex((obj) => obj.uuid === category.uuid);
    all_tags[filtered_category_index] = category;

    this.updateSelectedTags(this.state.selected_tags, {
      is_tagged: tag_obj.is_tagged,
      tag_uuid: tag_obj.uuid,
      tag_category_uuid: category.uuid
    });

    this.setState({
      all_tags
    });
  };

  onSave = () => {
    const payload = {
      updates: this.state.selected_tags,
      association_type_id: this.state.association_id,
      association_type: this.state.association_type_name
    };
    this.setState({
      loading: true
    });

    this.props.updateTagAssociations({
      url: `/v2/entity_tags/associations/${this.state.association_type.toLowerCase()}`,
      payload
    });
  };

  render_all_tags = () => {
    const { association_type, association_id } = this.state;
    const cont_style = association_type === 'Blogs' ? 'content-width-half' : 'content-width-full';
    return (
      <div className={`content-border category-template-div ${cont_style}`}>
        <div className="inner-div">
          {!!this.state.all_tags && this.state.all_tags.length !== 0
            ? this.state.all_tags.map((data) => (
                  <div key={data?.uuid} className="content-div">
                    <h3 className="text-lg font-bold">{data?.name}</h3>
                    <div className="inner-div content-border content-width-full">
                      {data?.tags && data?.tags.length !== 0 ? (
                        data.tags.map((tag) => (
                            <div className="md-chips" key={tag?.tag_uuid}>
                              <input
                                type="checkbox"
                                name={tag}
                                checked={tag?.is_tagged}
                                value={tag?.uuid}
                                id={tag?.uuid}
                                style={{ display: 'none' }}
                                onClick={() => this.onTagSelect(tag, data)}
                              />
                              <label
                                htmlFor={tag?.uuid}
                                id={tag?.uuid}
                                className="md-chip md-chip-clickable md-chip-hover"
                              >
                                <span> {tag?.name} </span>
                              </label>
                            </div>
                          ))
                      ) : (
                        <p>No tags present</p>
                      )}
                    </div>
                  </div>
                ))
            : null}
        </div>
        <div className="create-tag-name-button mr-4" onClick={this.onSave}>
          <button>Save</button>
        </div>
      </div>
    );
  };

  render_iframe = () => {
    const { association_type, association_id, slug } = this.state;
    console.log('slug', `https://myyogateacher.com/articles/${slug}`);
    if (association_type === 'Blogs' && !!slug) {
      console.log(this.props.association_type);
      return (
        <div className="blog-frame">
          {/* <iframe src="https://myyogateacher.com/articles/the-journey-begins"></iframe> */}
          <iframe src={`https://myyogateacher.com/articles/${slug}`} />
        </div>
      );
    }
    return null;
  };

  render() {
    const { association_type, loading, title } = this.state;
    return (
      <CommonHeader
        loading={loading}
        show_back
        title={`Details for ${association_type} ${title}`}
      >
        <div style={{ display: 'flex' }}>
          {this.render_all_tags()}
          {this.render_iframe()}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    get_all_associated_tags: state.content.get_all_associated_tags,
    get_all_associated_tags_status: state.content.get_all_associated_tags_status,
    update_tag_associations_status: state.content.update_tag_associations_status
  });

const mapDispatchToProps = (dispatch) => ({
    getAllAssociatedTags: (payload) => dispatch(actions.get_all_associated_tags(payload)),
    updateTagAssociations: (payload) => dispatch(actions.update_tag_associations(payload))
  });

export default connect(mapStateToProps, mapDispatchToProps)(TagAssociations);
