import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";
import * as actions from '../../../redux/action_creators';
import Button from "../../util_components/button";
import { day_format, time_format, cancelled_status } from '../../../constants';
import { image_error_replace } from '../../../util_functions';
import * as selectors from '../../../redux/selectors';

class FriendsSessions extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    if (this.props.future_friends_sessions_status === 'none') {
      this.props.get_future_friends_sessions();
    }
  }

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${  session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${  session.teacher_uuid}`;
    const student_url = `/studentprofile?id=${  session.student_uuid}`;
    const status_style =
      cancelled_status.indexOf(session.session_status) === -1
        ? { color: '#016601' }
        : { color: '#ED4D4D' };
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-hf">
          {session.is_live === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={session.student_avatar_medium} onError={image_error_replace} alt="x" />
              {session.student_name}
            </div>
          </a>
        </div>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_avatar_medium} onError={image_error_replace} alt="x" />
              {session.teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-hf ct-bw ct-sm-font" style={status_style}>
          {session.session_status}
        </div>
        <div className="ct-col ct-qf ct-si">
          {session.is_recurring === 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic_repeat@2x.png" alt="ic_rep" />
          ) : null}
        </div>
        <div className="ct-col ct-hf">{session.accepted_participants}</div>
        <div className="ct-col ct-hf">{session.declined_participants}</div>
        <div className="ct-col ct-hf">{session.later_participants}</div>
        <div className="ct-col ct-hf">
          <Button onClick={() => this.open_session_viewer(session.session_uuid)}>Details</Button>
        </div>
      </div>
    );
  };

  render_sessions = () => {
    const sessions = this.props.future_friends_sessions;
    if (sessions.length > 0) {
      return (
        <div style={{ marginTop: '30px' }}>
          Friends Sessions:
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Start Date</div>
              <div className="ct-col ct-hf">Live</div>
              <div className="ct-col ct-la">
                <span className="ct-ml25">Host</span>
              </div>
              <div className="ct-col ct-la">
                <span className="ct-ml25">Teacher</span>
              </div>
              <div className="ct-col ct-hf">Status</div>
              <div className="ct-col ct-qf">Repeat</div>
              <div className="ct-col ct-hf ct-sm-font">
                Accepted <div className="ct-vsm-font">(Par)</div>
              </div>
              <div className="ct-col ct-hf ct-sm-font">
                Declined<div className="ct-vsm-font">(Par)</div>
              </div>
              <div className="ct-col ct-hf ct-sm-font">
                Decide Later<div className="ct-vsm-font">(Par)</div>
              </div>
              <div className="ct-col ct-hf">Details</div>
            </div>
            {sessions.map(this.render_single_session)}
          </div>
        </div>
      );
    }
  };

  go_to_past = () => this.props.history.push('/friends_sessions/past');

  render() {
    const { loading } = this.state;
    const screen_loading = loading || this.props.future_friends_sessions_status === 'loading';
    return (
      <CommonHeader loading={screen_loading} title="Friends Sessions">
        <Button onClick={this.go_to_past}>Past Friends Session</Button>
        {this.render_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    future_friends_sessions: selectors.future_friends_sessions(state),
    future_friends_sessions_status: state.loading.future_friends_sessions_status,
    iana_timezone: state.home.iana_timezone,
    future_friends_sess_curr_page: state.sessions.future_friends_sess_curr_page,
    future_friends_sess_page_limit: state.sessions.future_friends_sess_page_limit
  });

const mapDispatchToProps = (dispatch) => ({
    get_future_friends_sessions: (status) => {
      dispatch(actions.get_future_friends_sessions(status));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(FriendsSessions);
