import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import CommonHeader from "../../util_components/common_header";
import * as actions from '../../../redux/action_creators';

import './teachers.css';

import coords from './files/coords.json';
import india_cities from './files/india_cities.json';

function AnyReactComponent({ name, count }) {
  const len = 7 + count * 2;
  const markerStyle = {
    borderRadius: '50%',
    height: len,
    width: len,
    backgroundColor: 'red',
    cursor: 'pointer',
    zIndex: 10
  };
  return (
    <div style={markerStyle} className="teacher-marker">
      <div className="teacher-marker-name">{name}</div>
    </div>
  );
}

class TeacherAreaMap extends Component {
  state = {
    loading: true,
    center: {
      lat: 13.06129399061948,
      lng: 77.63970791074638
    },
    zoom: 11,
    teachers: []
  };

  componentDidMount() {
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.onboarding_status === 'APPROVED');
    const teachers_list = [];
    for (const teacher of all_teachers) {
      const obj = {
        uuid: teacher.uuid,
        name: `${teacher.first_name} ${teacher.last_name}`
      };
      const teacher_index = coords.findIndex((c) => c.teacher_uuid === teacher.uuid);
      let city_index = -1;
      try {
        city_index = india_cities.findIndex(
          (c) => c.name.toLowerCase() == teacher.city.toLowerCase()
        );
      } catch (e) {}
      if (teacher_index != -1) {
        const teacher_cord = coords[teacher_index];
        obj.lat = teacher_cord.lat;
        obj.lon = teacher_cord.lon;
        obj.city = 'hardcoded';
        obj.count = 0;
        teachers_list.push(obj);
      } else if (city_index != -1) {
        const city = india_cities[city_index];
        const city_name = city.name.toLowerCase();
        obj.lat = parseFloat(city.lat);
        obj.lon = parseFloat(city.lon);
        obj.city = city_name;
        obj.count = 0;
        const teacher_index = teachers_list.findIndex((t) => t.city === city_name);
        if (teacher_index != -1) {
          const t_city = teachers_list[teacher_index];
          t_city.name = `${t_city.name}, ${teacher.first_name} ${teacher.last_name}`;
          t_city.count += 1;
        } else {
          teachers_list.push(obj);
        }
      }
    }
    const final_teachers = teachers_list.filter((t) => !!t.lon);
    this.setState({ teachers: [...final_teachers], loading: false });
  };

  render_map = () => {
    const { teachers } = this.state;
    return (
      <div>
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDi8BSg5aVy95EMFONvYNmhU7dIKowadbE' }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
          >
            {teachers.map((c, index) => (
                <AnyReactComponent
                  lat={c.lat}
                  lng={c.lon}
                  name={c.name}
                  count={c.count}
                  key={index}
                />
              ))}
          </GoogleMapReact>
        </div>
      </div>
    );
  };

  render() {
    const { loading, teachers } = this.state;
    return (
      <CommonHeader loading={loading} title="Area Map" show_back>
        {this.render_map()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    teachers_list_status: state.teachers.teachers_list_status,
    teachers_list: state.teachers.teachers_list
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAreaMap);
