import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from "../../../util_components/common_header";
import { check_null_text, check_bool_flag } from '../../../../util_functions';
import { post_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import Button from '../../../util_components/button';
import CustomInput from '../../../util_components/custom_input';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

import './stc.css';

const choice_active = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 }
];

class SplitTestChoice extends Component {
  state = {
    loading: true,
    loaded: false,
    choice_details: {},
    students: [],
    modal_open: false,
    modal_error: false,
    weight: null,
    tag: '',
    name: '',
    description: '',
    test_next_page_url: '',
    is_active: null,
    image_url: '',
    choice_data: {},
    choice_data_key: '',
    choice_data_value: '',
    choice_edit_index: -1,
    choice_edit_value: '',
    choice_edit_key: '',
    config_data: [],
    edit_config_open: false,
    data_conf_value: '',
    data_conf_uuid: '',
    data_conf_key: '',
    data_conf_type: '',
    optimize_test_variant: ''
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('uuid');
    if (uuid) {
      this.setState({ uuid }, () => this.load_data());
    } else {
      this.props.history.push('/split_testing');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const {uuid} = this.state;
      const url = get_api_url('get_split_test_choice_details');
      const payload = { choice_uuid: uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            loading: false,
            choice_details: res.data.details,
            config_data: res.data.config_data,
            loaded: true
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  open_edit_conf = (conf) => {
    let data_conf_value = '';
    if (conf.data_type === 'number') {
      data_conf_value = 0;
    }
    if (conf.data_value) {
      data_conf_value = conf.data_value;
    }
    this.setState({
      edit_config_open: true,
      data_conf_value,
      data_conf_uuid: conf.uuid,
      data_conf_key: conf.data_key,
      data_conf_type: conf.data_type
    });
  };

  render_config_data = () => {
    const { config_data } = this.state;
    if (config_data.length > 0) {
      return (
        <div>
          <h4>Data config:</h4>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Key</div>
              <div className="ct-col">Type</div>
              <div className="ct-col">Value</div>
              <div className="ct-col">Details</div>
            </div>
            {config_data.map((conf) => {
              const value =
                conf.data_type === 'number' ? conf.data_value : check_null_text(conf.data_value);
              return (
                <div className="ct-row" key={conf.uuid}>
                  <div className="ct-col">{conf.data_key}</div>
                  <div className="ct-col">{conf.data_type}</div>
                  <div className="ct-col">{value}</div>
                  <div className="ct-col">
                    <button onClick={() => this.open_edit_conf(conf)}>Edit</button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  render_test_details = () => {
    const details = this.state.choice_details;
    return (
      <div>
        <h3>About choice:</h3>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Name:</td>
              <td>{details.name}</td>
              <td className="teacher-table-detail">Tag :</td>
              <td>{details.tag}</td>
            </tr>
            <tr className="table-row-left-align">
              {details.distribution_pattern !== 'WEIGHTAGE' ? (
                <>
                  <td className="teacher-table-detail">Pattern:</td>
                  <td>{details.distribution_pattern}</td>
                </>
              ) : (
                <>
                  {' '}
                  <td className="teacher-table-detail">Weight:</td>
                  <td>{details.weight}</td>
                </>
              )}
              <td className="teacher-table-detail">Next Page :</td>
              <td>{details.test_next_page_url}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Uuid:</td>
              <td>{details.uuid}</td>
              <td className="teacher-table-detail">Active:</td>
              <td>{check_bool_flag(details.is_active)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Optimize test variant:</td>
              <td>{check_null_text(details.optimize_test_variant)}</td>
              <td className="teacher-table-detail" />
              <td />
            </tr>

            {details.distribution_pattern !== 'WEIGHTAGE' ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Image url:</td>
                <td>{details.image_url}</td>
                <td className="teacher-table-detail" />
                <td />
              </tr>
            ) : null}
          </tbody>
        </table>
        <button onClick={this.open_modal}>Edit details</button>
        {this.render_config_data()}
        <div style={{ margin: '20px 0' }}>
          Choice Description:
          <div style={{ margin: '5px 0', fontSize: '14px', letterSpacing: '0.3px' }}>
            {check_null_text(details.description)}
          </div>
        </div>
      </div>
    );
  };

  go_back = () => this.props.history.goBack();

  submit_edit = () => {
    const {
      uuid,
      tag,
      name,
      description,
      weight,
      test_next_page_url,
      is_active,
      image_url,
      choice_data,
      optimize_test_variant
    } = this.state;
    if (!!tag && !!name && !!description) {
      this.setState({ loading: true }, () => {
        const url = get_api_url('update_split_test_choice');
        const payload = {
          split_choice_uuid: uuid,
          tag,
          name,
          choice_data,
          optimize_test_variant,
          description,
          test_next_page_url,
          weight,
          is_active,
          image_url
        };
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Choice created');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  open_modal = () => {
    const details = this.state.choice_details;
    const choice_data = details.choice_data ? details.choice_data : {};
    this.setState({
      modal_open: true,
      tag: details.tag,
      name: details.name,
      weight: details.weight,
      description: details.description,
      is_active: details.is_active,
      choice_data,
      test_next_page_url: details.test_next_page_url,
      image_url: details.image_url,
      optimize_test_variant: details.optimize_test_variant
    });
  };

  close_modal = () =>
    this.setState({
      modal_open: false,
      tag: '',
      name: '',
      weight: 0,
      edit_config_open: false,
      description: '',
      test_next_page_url: '',
      is_active: null,
      optimize_test_variant: ''
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    let {value} = e.target;
    if (key === 'weight') {
      value = Math.min(value, 100);
    }
    this.setState({ [key]: value, modal_error: false });
  };

  add_key_value = () => {
    const { choice_data_key, choice_data_value } = this.state;
    const choice_data = { ...this.state.choice_data, [choice_data_key]: choice_data_value };
    this.setState({ choice_data, choice_data_key: '', choice_data_value: '' });
  };

  cancel_edit_choice = () => {
    this.setState({ choice_edit_value: '', choice_edit_index: -1, choice_edit_key: '' });
  };

  save_edit_choice = () => {
    const { choice_edit_value, choice_edit_key, choice_data } = this.state;
    const new_choice_data = { ...choice_data, [choice_edit_key]: choice_edit_value };
    this.setState({
      choice_edit_value: '',
      choice_edit_index: -1,
      choice_edit_key: '',
      choice_data: new_choice_data
    });
  };

  render_create_choice = () => {
    const {
      tag,
      name,
      description,
      test_next_page_url,
      choice_edit_index,
      optimize_test_variant,
      modal_error,
      choice_data_key,
      choice_data_value,
      choice_data,
      choice_edit_value,
      is_active,
      weight,
      choice_details,
      image_url
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const show_edit = choice_edit_index === -1;
    return (
      <div>
        <h2>Edit split test choice</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CustomInput
            label="*Enter tag"
            onChange={this.onCommonChange}
            name="tag"
            value={tag}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
            style={{ width: '260px' }}
          />
          <CustomSingleSelect
            label="Change active"
            options={choice_active}
            onChange={this.onCommonChange}
            value={is_active}
            style={{ width: '260px' }}
            name="is_active"
          />
          <CustomInput
            label="Next page url"
            onChange={this.onCommonChange}
            name="test_next_page_url"
            value={test_next_page_url}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Optimize test variant"
            onChange={this.onCommonChange}
            name="optimize_test_variant"
            value={optimize_test_variant}
            style={{ width: '260px' }}
          />
          {choice_details.distribution_pattern === 'WEIGHTAGE' ? (
            <CustomInput
              label="*Enter weight"
              onChange={this.onCommonChange}
              name="weight"
              type="number"
              value={weight}
              style={{ width: '260px' }}
            />
          ) : (
            <CustomInput
              label="*Enter Image url"
              onChange={this.onCommonChange}
              name="image_url"
              value={image_url}
              style={{ width: '520px' }}
            />
          )}
          <div style={{ width: '100%' }}>
            Custom Data:
            {Object.keys(choice_data).map((key_val, index) => {
              if (choice_edit_index === index) {
                return (
                  <div key={key_val + index} style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '33%' }}>{key_val}</div>
                    <div style={{ width: '33%' }}>
                      <CustomInput
                        label="Enter value"
                        onChange={this.onCommonChange}
                        name="choice_edit_value"
                        value={choice_edit_value}
                      />
                    </div>
                    <div style={{ width: '33%' }}>
                      <button onClick={this.save_edit_choice}>save</button>
                      <button onClick={this.cancel_edit_choice}>cancel</button>
                    </div>
                  </div>
                );
              } 
                return (
                  <div key={key_val + index} style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '33%' }}>{key_val}</div>
                    <div style={{ width: '33%' }}>{choice_data[key_val]}</div>
                    <div style={{ width: '33%' }}>
                      {show_edit ? (
                        <button
                          onClick={() =>
                            this.setState({
                              choice_edit_index: index,
                              choice_edit_value: choice_data[key_val],
                              choice_edit_key: key_val
                            })
                          }
                        >
                          Edit
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              
            })}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <CustomInput
                label="Data key"
                onChange={this.onCommonChange}
                name="choice_data_key"
                value={choice_data_key}
                style={{ width: '220px' }}
              />
              <CustomInput
                label="Data Value"
                onChange={this.onCommonChange}
                name="choice_data_value"
                value={choice_data_value}
                style={{ width: '220px' }}
              />
            </div>
            <Button disabled={!choice_data_key || !choice_data_value} onClick={this.add_key_value}>
              Add data
            </Button>
          </div>
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <button onClick={this.submit_edit}>Submit</button>
        <p style={err_sty}>* fields are compulsory</p>
      </div>
    );
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  save_new_data_config = () =>
    this.setState({ loading: true }, () => {
      const { data_conf_value, data_conf_uuid, data_conf_type } = this.state;
      const url = get_api_url('update_split_test_data_config_values');
      const payload = {
        config_value_uuid: data_conf_uuid,
        data_type: data_conf_type,
        data_value: data_conf_value
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Config data updated');
          this.setState({ edit_config_open: false });
          this.load_data();
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_add_config = () => {
    const { data_conf_value, data_conf_key, data_conf_type } = this.state;
    const type = data_conf_type === 'number' ? 'number' : 'text';
    const onChangeFunc = data_conf_type === 'number' ? this.on_number_change : this.onCommonChange;
    const enable_btn = (data_conf_type === 'number' && data_conf_value === 0) || !!data_conf_value;
    return (
      <div>
        <h4>Edit custom data</h4>
        <p>Key: {data_conf_key}</p>
        <p>Type: {data_conf_type}</p>
        <CustomInput
          label="Data Value"
          onChange={onChangeFunc}
          type={type}
          name="data_conf_value"
          value={data_conf_value}
          style={{ width: '220px' }}
        />
        <Button disabled={!enable_btn} onClick={this.save_new_data_config}>
          Save
        </Button>
      </div>
    );
  };

  render() {
    const { loaded, loading, modal_open, edit_config_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Split Test Choice" show_back>
        <CustomModal show={modal_open} close={this.close_modal}>
          {this.render_create_choice()}
        </CustomModal>
        <CustomModal show={edit_config_open} close={this.close_modal}>
          {this.render_add_config()}
        </CustomModal>
        {loaded ? this.render_test_details() : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(SplitTestChoice);
