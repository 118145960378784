import * as t from '../action_types';

export const set_reports_variable = (key, payload) => ({
  type: t.set_reports_variable,
  payload: {
    key,
    payload
  }
});

export const get_past_sessions = (payload) => ({
    type: t.get_past_sessions,
    payload
  });

export const get_future_sessions = (payload) => ({
    type: t.get_future_sessions,
    payload
  });

export const get_email_templates = () => ({
    type: t.get_email_templates
  });

export const get_replacement_sessions = () => ({
    type: t.get_replacement_sessions
  });

export const get_replacement_session_teachers = (payload) => ({
    type: t.get_replacement_session_teachers,
    payload
  });

export const avail_all_in_one = (payload) => ({
  type: t.avail_all_in_one,
  payload
});
