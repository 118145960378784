import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../redux/action_creators';
import CommonHeader from '../../../util_components/common_header';
import { get_api_url } from '../../../../utils/urls';
import { post_api, api_with_method } from '../../../../redux/api_funcs';
import {
  split_test_categories,
  day_format,
  day_format_year,
  time_format,
  distribution_types,
  split_test_status
} from '../../../../constants';
import CustomModal from '../../../util_components/custom_modal';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomInput from '../../../util_components/custom_input';

class SplitTestListV2 extends Component {
  state = {
    test_list: [],
    temp_test_list: [],
    split_test_category: split_test_categories[0].value,
    show_modal: false,
    date_filter_type: '',
    tag: '',
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    test_starting_page: '',
    distribution_metric: 'USERS',
    first_success_metric_name: '',
    second_success_metric_name: '',
    third_success_metric_name: '',
    fourth_success_metric_name: '',
    fifth_success_metric_name: '',
    optimize_test_id: '',
    past_tests: false,
    status: 'LIVE',
    loading: false,
    category: 'GENERAL',
    split_status: split_test_status[0].value
  };

  componentDidMount() {
    this.load_data();
  }

  change_btn_state = () => {
    const { past_tests } = this.state;
    if (past_tests) {
      this.setState({
        past_tests: false
      });
    } else {
      this.setState({
        past_tests: true
      });
    }
    this.set_past_future();
  };

  set_past_future = () => {
    let past_test_list = [];
    const { past_tests, test_list } = this.state;

    if (past_tests) {
      past_test_list = test_list.filter((t) => moment(t.end_date) < moment());
      this.setState({
        past_tests: false,
        temp_test_list: past_test_list
      });
    } else {
      past_test_list = test_list.filter((t) => moment(t.end_date) >= moment());
      this.setState({
        past_tests: true,
        temp_test_list: past_test_list
      });
    }
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value, modal_error: false });
  };

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { split_status } = this.state;
      const url = get_api_url(`/v2/split_tests/care/all_split_tests/${split_status}`, null, true);
      api_with_method('get', url, null, true)
        .then((res) => {
          const all_test_list = res.data.data;

          this.setState({
            test_list: all_test_list,
            temp_test_list: all_test_list,
            loading: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  open_test_details = (tag) => {
    this.props.history.push({
      pathname: `/split_test_v2/${tag}`
    });
  };

  open_modal = () => {
    this.setState({
      show_modal: true,
      tag: '',
      name: '',
      description: '',
      start_date: '',
      end_date: '',
      category: 'GENERAL',
      test_starting_page: '',
      first_success_metric_name: '',
      second_success_metric_name: '',
      third_success_metric_name: '',
      fourth_success_metric_name: '',
      fifth_success_metric_name: '',
      optimize_test_id: ''
    });
  };

  close_modal = () => {
    this.setState({ show_modal: false });
  };

  submit_test = () => {
    const {
      start_date,
      end_date,
      distribution_metric,
      tag,
      name,
      test_starting_page,
      optimize_test_id,
      first_success_metric_name,
      second_success_metric_name,
      third_success_metric_name,
      fourth_success_metric_name,
      fifth_success_metric_name,
      category,
      description,
      modal_error
    } = this.state;
    const { iana_timezone } = this.props;
    if (
      !!tag &&
      !!name &&
      !!start_date &&
      !!end_date &&
      !!first_success_metric_name &&
      !!description &&
      !!category
    ) {
      const sel_start_date = `${start_date  } 00:00:01`;
      const sel_end_date = `${end_date  } 23:59:59`;
      const final_tag = tag.trim();

      const payload = {
        start_date: sel_start_date,
        end_date: sel_end_date,
        distribution_metric,
        tag: final_tag,
        name,
        test_starting_page,
        optimize_test_id,
        first_success_metric_name,
        second_success_metric_name,
        third_success_metric_name,
        fourth_success_metric_name,
        fifth_success_metric_name,
        category,
        description
      };
      const url = get_api_url('/v2/split_tests/care/add_split_test', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('post', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Split test created');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e?.response?.data?.reason;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => this.load_data());

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  create_test = () => {
    const {
      start_date,
      end_date,
      distribution_metric,
      tag,
      name,
      test_starting_page,
      optimize_test_id,
      first_success_metric_name,
      second_success_metric_name,
      third_success_metric_name,
      fourth_success_metric_name,
      fifth_success_metric_name,
      category,
      description,
      modal_error
    } = this.state;
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };

    return (
      <>
        <div className="text-center m-4">Create split test (in {curr_timezone})</div>
        <div className="flex flex-wrap justify-between px-2">
          <CustomDatePicker
            label="*Start Date"
            value={start_date}
            handleChange={(val) => this.handle_date_change('start_date', val)}
          />
          <CustomDatePicker
            label="*End Date"
            value={end_date}
            handleChange={(val) => this.handle_date_change('end_date', val)}
          />
          <CustomSingleSelect
            label="*Select Distribution"
            options={distribution_types}
            name="distribution_metric"
            onChange={this.onCommonChange}
            value={distribution_metric}
          />
          <CustomInput label="*Enter tag" onChange={this.onCommonChange} name="tag" value={tag} />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
          />
          <CustomInput
            label="Start page"
            onChange={this.onCommonChange}
            name="test_starting_page"
            value={test_starting_page}
          />
          <CustomInput
            label="Optimize test id"
            onChange={this.onCommonChange}
            name="optimize_test_id"
            value={optimize_test_id}
          />
          <CustomInput
            label="*First success metric"
            onChange={this.onCommonChange}
            name="first_success_metric_name"
            value={first_success_metric_name}
          />
          <CustomInput
            label="Second success metric"
            onChange={this.onCommonChange}
            name="second_success_metric_name"
            value={second_success_metric_name}
          />
          <CustomInput
            label="Third success metric"
            onChange={this.onCommonChange}
            name="third_success_metric_name"
            value={third_success_metric_name}
          />
          <CustomInput
            label="Fourth success metric"
            onChange={this.onCommonChange}
            name="fourth_success_metric_name"
            value={fourth_success_metric_name}
          />
          <CustomInput
            label="Fifth success metric"
            onChange={this.onCommonChange}
            name="fifth_success_metric_name"
            value={fifth_success_metric_name}
          />
          <CustomSingleSelect
            label="Select Category"
            options={split_test_categories}
            name="category"
            onChange={this.onCommonChange}
            value={category}
          />
          <div className="mb-2">
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
          <button onClick={this.submit_test}>Submit</button>
          <p style={err_sty}>* fields are compulsory</p>
        </div>
      </>
    );
  };

  change_category = (value) => {
    const { test_list } = this.state;
    const filter_list = test_list.filter((t) => t.category === value);
    this.setState({
      temp_test_list: filter_list,
      split_test_category: value
    });
  };

  render_category_btn = () => {
    const { split_test_category } = this.state;
    return split_test_categories?.map((item, index) => (
        <button
          key={index}
          className={`${item.value === split_test_category ? 'btn-white-icon bg-mytBlue text-white' : 'btn-white-icon'}`}
          onClick={() => this.change_category(item.value)}
        >
          {item.value}
        </button>
      ));
  };

  change_status = (value) => {
    this.setState(
      {
        split_status: value
      },
      () => {
        this.load_data();
      }
    );
  };

  render_status_btn = () => {
    const { split_status } = this.state;
    return split_test_status?.map((item, index) => (
        <button
          key={index}
          className={`${item.value === split_status ? 'btn-white-icon bg-mytBlue text-white' : 'btn-white-icon'}`}
          onClick={() => this.change_status(item.value)}
        >
          {item.value}
        </button>
      ));
  };

  render_single_row = (item, index) => {
    const { iana_timezone } = this.props;
    return (
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.tag}</td>
        <td>{item.published_version}</td>
        <td>
          {item.published_date === null
            ? '--'
            : moment(item.published_date).tz(iana_timezone).format(day_format_year)}
        </td>
        <td>
          <div>{moment(item.start_date).tz(iana_timezone).format(day_format_year)}</div>
        </td>
        <td>
          {item.end_date === null ? (
            'not available'
          ) : (
            <div>{moment(item.end_date).tz(iana_timezone).format(day_format_year)}</div>
          )}
        </td>
        <td>
          <button onClick={() => this.open_test_details(item.tag)}>Details</button>
        </td>
      </tr>
    );
  };

  render_table = () => {
    const { temp_test_list } = this.state;
    return temp_test_list.length > 0 ? (
      <table className="relative w-full border">
        <thead>
          <tr>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Name</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Tag</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Published version</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Published Date</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Start</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">End</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Details</th>
          </tr>
        </thead>
        <tbody>{temp_test_list.map(this.render_single_row)}</tbody>
      </table>
    ) : (
      <p>No Test Available</p>
    );
  };

  render() {
    const { split_status, split_test_category } = this.state;
    return (
      <>
        <CommonHeader
          class_name="width-full"
          loading={this.state.loading}
          show_back
          title={`Split Test - ${split_test_category} -  ${split_status}`}
        >
          <div className="flex justify-end" />
          <div className="mb-2 items-center justify-between flex">
            <div>{this.render_status_btn()}</div>
            <div className="flex">
              <button className="w-200px text-xl" onClick={this.open_modal}>
                Create Test
              </button>
            </div>
          </div>
          <div className="mb-2 items-center flex justify-center">
            <div>{this.render_category_btn()}</div>
          </div>
          <div className="flex flex-col h-screen calc_height">
            <div className="flex-grow overflow-auto">{this.render_table()}</div>
          </div>
        </CommonHeader>
        <CustomModal show={this.state.show_modal} close={this.close_modal}>
          {this.create_test()}
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(SplitTestListV2);
