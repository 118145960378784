import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { coupon_offer_format } from '../../../utils/formats';
import CustomSingleTable from "../../util_components/custom_single_table";
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomInput from '../../util_components/custom_input';
import { get_api_url } from '../../../utils/urls';
import { image_error_replace } from '../../../util_functions';
import { time_format, day_format_year, day_format_full_year } from '../../../constants';
import CreateEditCampaign from './create_edit_campaign';
import CreateEditCampaignSchedule from './create_edit_campaign_schedule';
import CustomModal from '../../util_components/custom_modal';
import Button from '../../util_components/button';

const mapping_types = [
  { value: 'WORKSHOP', label: 'WORKSHOP' },
  { value: 'PACKAGE', label: 'PACKAGE' }
];

class CouponCodes extends Component {
  state = {
    loading: false,
    offer_uuid: '',
    details: {},
    coupon_codes: [],
    activations: [],
    offer_schedules: [],
    campaign_toggle: false,
    edit_campaign_schedule: false,
    edit_campaign: false,
    view_campaign_schedule: false,
    start_time: '',
    end_time: '',
    create_offer_schedule_open: false,
    create_mapping_open: false,
    mapping_type: '',
    mapping_type_id: 0,
    offer_mappings: [],
    all_teachers: [],
    create_coupon_open: false,
    coupon_code: '',
    coupon_teacher_uuid: ''
  };

  componentDidMount() {
    try {
      const {uuid} = this.props.match.params;
      if (uuid) {
        this.setState({ offer_uuid: uuid }, () => this.load_data());
      }
      if (this.props.approved_teachers_status === 'success') {
        this.set_teachers_list();
      }
      if (this.props.approved_teachers_status === 'none') {
        this.props.get_approved_teachers('APPROVED');
      }
    } catch (e) {
      this.go_to_path('/coupon_offers');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.approved_teachers_status === 'loading' &&
      this.props.approved_teachers_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  set_teachers_list = () => {
    const all_teachers = this.props.approved_teachers_list;
    const teachers_list = all_teachers.map((teacher) => ({
        value: teacher.uuid,
        label: `${teacher.first_name} ${teacher.last_name}`
      }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ all_teachers: [...teachers_list] });
  };

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_coupons_offer_details');
      const payload = { offer_uuid: this.state.offer_uuid };
      post_api(url, payload, true)
        .then((res) => {
          const {campaign_page} = res.data;
          this.setState({
            details: res.data.details,
            coupon_codes: [...res.data.coupon_codes],
            activations: [...res.data.activations],
            loading: false,
            campaign_page,
            campaign_schedule: res.data.schedules,
            offer_schedules: [...res.data.offer_schedules],
            offer_mappings: [...res.data.offer_mappings]
          });
          campaign_page.forEach((page) => {
            this.setState({
              [`toggle_schedule_${page.id}`]: false
            });
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_coupon_code = (coupon) => {
    const { details } = this.state;
    const is_single_coupon = details.coupon_used_by === 'SINGLE_USER';
    const student_url = `/studentprofile?id=${  coupon.student_uuid}`;
    const teacher_url = `/teacherprofile?id=${  coupon.teacher_uuid}`;
    const teacher_associated_coupon = details.teacher_associated_offer === 1;
    return (
      <div className="ct-row" key={coupon.coupon_code}>
        <div className="ct-col">{coupon.coupon_code}</div>
        {is_single_coupon ? (
          <div className="ct-col ct-si">
            {coupon.is_activated === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </div>
        ) : null}
        {is_single_coupon ? (
          <div className="ct-col ct-sm-font">
            {coupon.is_activated === 1 ? (
              <>
                <div>
                  {moment(coupon.activation_date)
                    .tz(this.props.iana_timezone)
                    .format(day_format_year)}
                </div>
                <div>
                  {moment(coupon.activation_date).tz(this.props.iana_timezone).format(time_format)}
                </div>
              </>
            ) : (
              '--'
            )}
          </div>
        ) : null}
        {is_single_coupon ? (
          <div className="ct-col">
            {coupon.student_uuid ? (
              <a className="link-no-dec" href={student_url}>
                <div className="profile-img-name">
                  <img src={coupon.profile_photo} onError={image_error_replace} alt="x" />
                  {coupon.first_name}
                </div>
              </a>
            ) : (
              '--'
            )}
          </div>
        ) : null}
        {is_single_coupon ? <div className="ct-col">{coupon.package_name}</div> : null}
        {is_single_coupon ? <div className="ct-col ct-qf">{coupon.discount_amount}</div> : null}
        {teacher_associated_coupon ? (
          <div className="ct-col">
            {coupon.teacher_uuid ? (
              <a className="link-no-dec" href={teacher_url}>
                <div className="profile-img-name">
                  <img src={coupon.teacher_photo} onError={image_error_replace} alt="x" />
                  {coupon.teacher_name}
                </div>
              </a>
            ) : (
              '--'
            )}
          </div>
        ) : null}
        <div className="ct-col ct-sm-font">
          <div>{moment(coupon.expiry).tz(this.props.iana_timezone).format(day_format_year)}</div>
          <div>{moment(coupon.expiry).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
      </div>
    );
  };

  render_coupon_codes = () => {
    const { details, coupon_codes } = this.state;
    const is_single_coupon = details.coupon_used_by === 'SINGLE_USER';
    const teacher_associated_coupon = details.teacher_associated_offer === 1;
    return (
      <div style={{ marginTop: '30px' }}>
        <div className="display-flex-between-center">
          <h4>Coupon Codes:</h4>
          <button className="btn-link" onClick={this.open_create_coupon}>
            Create
          </button>
        </div>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col">Code</div>
            {is_single_coupon ? <div className="ct-col">Activated</div> : null}
            {is_single_coupon ? <div className="ct-col">Activation Time</div> : null}
            {is_single_coupon ? <div className="ct-col">Student</div> : null}
            {is_single_coupon ? <div className="ct-col">Package</div> : null}
            {is_single_coupon ? <div className="ct-col ct-qf">Discount</div> : null}
            {teacher_associated_coupon ? <div className="ct-col">Teacher</div> : null}
            <div className="ct-col">Expiry</div>
          </div>
          {coupon_codes.map(this.render_single_coupon_code)}
        </div>
      </div>
    );
  };

  toggle_campaign_block = () => {
    this.setState({ campaign_toggle: !this.state.campaign_toggle });
  };

  open_edit_campaign_schedule = (page, current_schedule) => {
    this.setState({
      edit_campaign_schedule: !this.state.edit_campaign_schedule,
      current_schedule,
      current_campaign: page
    });
  };

  view_campaign_schedule = (page) => {
    this.setState({
      [`toggle_schedule_${page.id}`]: !this.state[`toggle_schedule_${page.id}`]
    });
  };

  render_single_campaign_schedule = (schedule) => (
      <div className="ct-row" key={schedule.uuid}>
        <div className="ct-col ct-qf">{schedule.id}</div>
        <div className="ct-col ct-qf">
          {moment(schedule.start_time).utc().format('DD/MM/YYYY hh:mm a')}
        </div>
        <div className="ct-col ct-qf">
          {moment(schedule.end_time).utc().format('DD/MM/YYYY hh:mm a')}
        </div>
        <div className="ct-col ct-qf">{schedule.is_active === 1 ? 'Yes' : 'No'}</div>
        <div className="ct-col ct-qf">
          <button
            className="ct-small-btn"
            onClick={() => this.open_edit_campaign_schedule(null, schedule)}
          >
            Edit
          </button>
        </div>
      </div>
    );

  render_single_campaign_page = (page) => {
    const { campaign_schedule, view_campaign_schedule } = this.state;
    const toggle_schedule = this.state[`toggle_schedule_${page.id}`];
    const null_filtered_schedules = campaign_schedule.filter((n) => n);
    const related_schedules = null_filtered_schedules.filter(
      (t) => t.page_level_campaign_uuid === page.uuid
    );
    return (
      <div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-qf">Page ID</div>
            <div className="ct-col ct-qf">Page Tag</div>
            <div className="ct-col ct-qf">Page URL</div>
            <div className="ct-col ct-qf">Active</div>
            <div className="ct-col ct-qf" />
          </div>
          <div className="ct-row" key={page.uuid}>
            <div className="ct-col ct-qf">{page.id}</div>
            <div className="ct-col ct-qf">{page.page_tag}</div>
            <div className="ct-col ct-qf">{page.page_url}</div>
            <div className="ct-col ct-qf">{page.is_active === 1 ? 'Yes' : 'No'}</div>
            <div className="ct-col ct-qf">
              <button className="ct-small-btn" onClick={() => this.open_edit_campaign_page(page)}>
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="ct-row">
          <div className="cus-table">
            <div className="ct-row">
              <div className="ct-col ct-qf">
                <button
                  className="ct-small-btn"
                  onClick={() => this.open_edit_campaign_schedule(page, null)}
                >
                  Create Campaign Schedule
                </button>
              </div>
              <div className="ct-col ct-qf">
                <button className="ct-small-btn" onClick={() => this.view_campaign_schedule(page)}>
                  View Campaign Schedules
                </button>
              </div>
            </div>
            <div className="ct-row">
              {toggle_schedule && related_schedules.length > 0 ? (
                <div className="cus-table">
                  <div className="ct-row ct-h">
                    <div className="ct-col ct-qf">ID</div>
                    <div className="ct-col ct-qf">Start Time</div>
                    <div className="ct-col ct-qf">End Time</div>
                    <div className="ct-col ct-qf">Active</div>
                    <div className="ct-col ct-qf" />
                  </div>
                  {related_schedules.map(this.render_single_campaign_schedule)}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render_coupon_campaign_pages = () => {
    const { details, campaign_page, campaign_schedule, campaign_toggle } = this.state;
    return (
      <div style={{ marginTop: '20px' }}>
        <div className="display-flex-between">
          <div>Coupon Campaigns:</div>
          <button className="ct-small-btn" onClick={() => this.open_edit_campaign_page()}>
            Create Campaign
          </button>
        </div>
        {campaign_page.map(this.render_single_campaign_page)}
      </div>
    );
  };

  render_single_activation = (act) => {
    const { details } = this.state;
    const student_url = `/studentprofile?id=${  act.student_uuid}`;
    return (
      <div className="ct-row" key={act.student_uuid}>
        <div className="ct-col ct-hf">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={act.profile_photo} onError={image_error_replace} alt="x" />
              {act.first_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div>
            {moment(act.activation_date).tz(this.props.iana_timezone).format(day_format_year)}
          </div>
          <div>{moment(act.activation_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-sm-font">{act.package_name}</div>
        <div className="ct-col ct-hf">{act.discount_amount || act.students_discounts_value}</div>
        <div className="ct-col ct-hf ct-sm-font">{act.coupon_code}</div>
        {details.no_of_weeks !== '4' ? <div className="ct-col ct-hf">{act.no_of_weeks}</div> : null}
      </div>
    );
  };

  render_activations = () => {
    const { activations, details } = this.state;
    return (
      <div style={{ marginTop: '30px' }}>
        <h4>Coupon Activations:</h4>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-hf">Student</div>
            <div className="ct-col ct-hf">Activation Time</div>
            <div className="ct-col">Package</div>
            <div className="ct-col ct-hf">Discount Amount</div>
            <div className="ct-col ct-hf">Coupon Code</div>
            {details.no_of_weeks !== '4' ? <div className="ct-col ct-hf">Weeks</div> : null}
          </div>
          {activations.map(this.render_single_activation)}
        </div>
      </div>
    );
  };

  add_campaign = (campaign_page) =>
    this.setState({ loading: true }, () => {
      const { details } = this.state;
      const path = `create_campaign_page`;
      const url = get_api_url(path, false, false);
      const payload = {
        page_url: campaign_page.page_url,
        page_tag: campaign_page.page_tag,
        is_active: campaign_page.is_active,
        coupon_offer_id: campaign_page.coupon_offer_id,
        coupon_offer_uuid: campaign_page.coupon_offer_uuid
      };
      post_api(url, payload, true)
        .then((res) => {
          this.close_edit_campaign_page();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.close_edit_campaign_page();
          this.load_data();
        });
    });

  edit_campaign = (campaign_page) =>
    this.setState({ loading: true }, () => {
      const { details } = this.state;
      const path = `update_campaign_page`;
      const url = get_api_url(path, false, false);
      const payload = {
        page_url: campaign_page.page_url,
        page_tag: campaign_page.page_tag,
        is_active: campaign_page.is_active,
        coupon_offer_id: campaign_page.coupon_offer_id,
        coupon_offer_uuid: campaign_page.coupon_offer_uuid,
        campaign_uuid: campaign_page.campaign_uuid
      };
      post_api(url, payload, true)
        .then((res) => {
          this.close_edit_campaign_page();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.close_edit_campaign_page();
          this.load_data();
        });
    });

  add_campaign_schedule = (schedule) =>
    this.setState({ loading: true }, () => {
      const { details } = this.state;
      const path = `create_campaign_schedule`;
      const url = get_api_url(path, false, false);
      const payload = {
        page_level_campaign_id: schedule.page_level_campaign_id,
        page_level_campaign_uuid: schedule.page_level_campaign_uuid,
        start_time: schedule.start_time,
        end_time: schedule.end_time,
        is_active: schedule.is_active
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            edit_campaign_schedule: false
          });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({
            edit_campaign_schedule: false
          });
          this.load_data();
        });
    });

  edit_campaign_schedule = (schedule) =>
    this.setState({ loading: true }, () => {
      const { details } = this.state;
      const path = `update_campaign_schedule`;
      const url = get_api_url(path, false, false);
      const payload = {
        page_level_campaign_id: schedule.page_level_campaign_id,
        page_level_campaign_uuid: schedule.page_level_campaign_uuid,
        start_time: schedule.start_time,
        end_time: schedule.end_time,
        is_active: schedule.is_active,
        uuid: schedule.uuid
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            edit_campaign_schedule: false
          });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({
            edit_campaign_schedule: false
          });
          this.load_data();
        });
    });

  // create_coupon_open: false,
  // coupon_code: '',
  // coupon_teacher_uuid: '',
  open_create_coupon = () => this.setState({ create_coupon_open: true });

  close_create_coupon = () =>
    this.setState({ create_coupon_open: false, coupon_code: '', coupon_teacher_uuid: '' });

  render_create_coupon = () => {
    const { details, coupon_code, coupon_teacher_uuid, all_teachers } = this.state;
    return (
      <div>
        <h4>Create Coupon Code</h4>
        <CustomInput
          label="Code"
          value={coupon_code}
          name="coupon_code"
          onChange={this.handle_change}
        />
        {details.teacher_associated_offer === 1 ? (
          <CustomSingleSelect
            label="Teacher"
            options={all_teachers}
            onChange={this.handle_change}
            value={coupon_teacher_uuid}
            name="coupon_teacher_uuid"
          />
        ) : null}
        <Button disabled={!coupon_code} onClick={this.create_coupon_code}>
          Submit
        </Button>
      </div>
    );
  };

  create_coupon_code = () =>
    this.setState({ loading: true }, () => {
      const { details, coupon_code, coupon_teacher_uuid } = this.state;
      const url = get_api_url('create_coupon_code');
      const payload = {
        offer_id: details.id,
        coupon_code: coupon_code.toLocaleUpperCase(),
        teacher_uuid: coupon_teacher_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.close_create_coupon();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  create_mapping = () =>
    this.setState({ loading: true }, () => {
      const { details, mapping_type, mapping_type_id } = this.state;
      const url = get_api_url('create_offer_mapping');
      const payload = {
        offer_id: details.id,
        type: mapping_type,
        type_id: mapping_type_id
      };
      post_api(url, payload, true)
        .then(() => {
          this.close_create_mapping();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  handle_number_change = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10)
    });
  };

  handle_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  open_create_mapping = () => this.setState({ create_mapping_open: true });

  close_create_mapping = () =>
    this.setState({ create_mapping_open: false, mapping_type: '', mapping_type_id: 0 });

  render_create_mapping = () => {
    const { mapping_type, mapping_type_id } = this.state;
    return (
      <div>
        <h4>Create Mapping</h4>
        <CustomSingleSelect
          label="Type"
          options={mapping_types}
          onChange={this.handle_change}
          value={mapping_type}
          name="mapping_type"
        />
        <CustomInput
          label="Type ID"
          value={mapping_type_id}
          name="mapping_type_id"
          onChange={this.handle_number_change}
          type="number"
        />
        <Button disabled={!mapping_type || mapping_type_id === 0} onClick={this.create_mapping}>
          Submit
        </Button>
      </div>
    );
  };

  render_mapping = () => {
    const { details, offer_mappings } = this.state;
    const is_super_user = this.props.config_values.user_access === 'SUPER_USER';
    if (details.offer_type_mapped === 1) {
      return (
        <div style={{ marginTop: '30px' }}>
          <div className="display-flex-between-center">
            <h4>Offer Mappings:</h4>
            {is_super_user ? (
              <button className="btn-link" onClick={this.open_create_mapping}>
                Create
              </button>
            ) : null}
          </div>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Type</div>
              <div className="ct-col">Type ID</div>
            </div>
            {offer_mappings.map((m) => (
                <div className="ct-row" key={m.id}>
                  <div className="ct-col">{m.type}</div>
                  <div className="ct-col">{m.type_id}</div>
                </div>
              ))}
          </div>
        </div>
      );
    } return null;
  };

  create_schedule = () =>
    this.setState({ loading: true }, () => {
      const { start_time, end_time, details } = this.state;
      const url = get_api_url('create_offer_schedule');
      const payload = {
        offer_id: details.id,
        start_time,
        end_time
      };
      post_api(url, payload, true)
        .then(() => {
          this.close_create_offer_schedule();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_edit_campaign_page = (page) => {
    this.setState({
      edit_campaign: true,
      current_campaign: page
    });
  };

  open_create_offer_schedule = () => this.setState({ create_offer_schedule_open: true });

  close_create_offer_schedule = () =>
    this.setState({ create_offer_schedule_open: false, start_time: '', end_time: '' });

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val
    });
  };

  render_create_offer_schedule = () => {
    const { start_time, end_time } = this.state;
    return (
      <div>
        <h4>Create schedule</h4>
        <div className="display-flex-between-center">
          <CustomDatePicker
            label="Start Time"
            value={start_time}
            handleChange={(val) => this.handle_date_change('start_time', val)}
            minDate={moment().format('YYYY-MM-DD')}
          />
          <CustomDatePicker
            label="End Time"
            value={end_time}
            handleChange={(val) => this.handle_date_change('end_time', val)}
            minDate={moment().format('YYYY-MM-DD')}
          />
        </div>
        <button onClick={this.create_schedule}>Submit</button>
      </div>
    );
  };

  render_offer_schedules = () => {
    const { offer_schedules } = this.state;
    const is_super_user = this.props.config_values.user_access === 'SUPER_USER';
    return (
      <div>
        <div className="display-flex-between-center">
          <h4>Offer Schedules:</h4>
          {is_super_user ? (
            <button className="btn-link" onClick={this.open_create_offer_schedule}>
              Create
            </button>
          ) : null}
        </div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Start Time</div>
            <div className="ct-col">End Time</div>
          </div>
          {offer_schedules.map((o) => (
              <div className="ct-row" key={o.id}>
                <div className="ct-col">
                  {`${moment(o.start_time).tz(this.props.iana_timezone).format(day_format_full_year)} ${moment(o.start_time).tz(this.props.iana_timezone).format(time_format)}`}
                </div>
                <div className="ct-col">
                  {`${moment(o.end_time).tz(this.props.iana_timezone).format(day_format_full_year)} ${moment(o.end_time).tz(this.props.iana_timezone).format(time_format)}`}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  close_edit_campaign_page = () => this.setState({ edit_campaign: false });

  close_edit_campaign_schedule = () => this.setState({ edit_campaign_schedule: false });

  render() {
    const {
      loading,
      details,
      current_campaign,
      current_schedule,
      create_mapping_open,
      create_offer_schedule_open,
      create_coupon_open
    } = this.state;
    const is_multi_user = details.coupon_used_by === 'MULTI_USER';
    return (
      <CommonHeader loading={loading} title="Coupon Offer Details" show_back>
        <CustomModal show={this.state.edit_campaign} close={this.close_edit_campaign_page}>
          <CreateEditCampaign
            campaign_details={details}
            current_campaign={current_campaign}
            add_campaign={this.add_campaign}
            edit_campaign={this.edit_campaign}
          />
        </CustomModal>
        <CustomModal
          show={this.state.edit_campaign_schedule}
          close={this.close_edit_campaign_schedule}
        >
          <CreateEditCampaignSchedule
            campaign_schedule_details={details}
            campaign_page_details={details}
            current_campaign={current_campaign}
            current_schedule={current_schedule}
            edit_campaign_schedule={this.edit_campaign_schedule}
            add_campaign_schedule={this.add_campaign_schedule}
          />
        </CustomModal>
        <CustomModal
          show={create_offer_schedule_open}
          close={this.close_create_offer_schedule}
          inner_style={{ minHeight: '600px' }}
        >
          {this.render_create_offer_schedule()}
        </CustomModal>
        <CustomModal show={create_mapping_open} close={this.close_create_mapping}>
          {this.render_create_mapping()}
        </CustomModal>
        <CustomModal show={create_coupon_open} close={this.close_create_coupon}>
          {this.render_create_coupon()}
        </CustomModal>
        <CustomSingleTable data={details} data_format={coupon_offer_format} />
        {this.render_offer_schedules()}
        {this.render_mapping()}
        {this.render_coupon_codes()}
        {!!details?.use_campaign === 1 ? this.render_coupon_campaign_pages() : null}
        {is_multi_user ? this.render_activations() : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    approved_teachers_list: state.teachers.approved_teachers_list,
    teachers_list_status: state.teachers.teachers_list_status,
    approved_teachers_status: state.teachers.approved_teachers_status,
    config_values: state.home.config_values
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    },
    get_approved_teachers: (status) => {
      dispatch(actions.get_approved_teachers(status));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(CouponCodes);
