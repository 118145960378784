import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from "../../util_components/common_header";
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import Button from '../../util_components/button';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class HealthKeywords extends Component {
  state = {
    loading: false,
    open_create: false,
    keyword: '',
    open_edit: false,
    key_uuid: ''
  };

  componentDidMount() {
    if (this.props.class_types_status === 'none') {
      this.props.get_class_types();
    }
    if (this.props.class_types_status === 'success') {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.setState({ loading: false });
    }
  }

  edit_word = (uuid, keyword) => {
    this.setState({ keyword, open_edit: true, key_uuid: uuid });
  };

  render_data = () => {
    const health_keywords = this.props.health_keywords_data;
    if (health_keywords.length > 0) {
      return (
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col">Keyword</div>
            <div className="ct-col">Count</div>
            <div className="ct-col">Edit</div>
          </div>
          {health_keywords.map((keyword) => (
              <div className="ct-row" key={keyword.uuid}>
                <div className="ct-col">{keyword.keyword}</div>
                <div className="ct-col">{keyword.keyword_count}</div>
                <div className="ct-col">
                  <button onClick={() => this.edit_word(keyword.uuid, keyword.keyword)}>
                    Edit
                  </button>
                </div>
              </div>
            ))}
        </div>
      );
    }
    return null;
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value, create_err: false });
  };

  update_keywords = (keyword, uuid) => {
    const health_keywords_data = [...this.props.health_keywords_data];
    const health_keywords = [...this.props.health_keywords];
    const data_index = health_keywords_data.findIndex((k) => k.uuid === uuid);
    const old_keyword = health_keywords_data[data_index].keyword;
    const new_data = { ...health_keywords_data[data_index], keyword };
    const new_keywords_data = [
      ...health_keywords_data.slice(0, data_index),
      new_data,
      ...health_keywords_data.slice(data_index + 1)
    ];
    const index = health_keywords.indexOf(old_keyword);
    health_keywords[index] = keyword;
    this.props.set_home_variable('health_keywords', health_keywords);
    this.props.set_home_variable('health_keywords_data', new_keywords_data);
  };

  add_new_keyword = (keyword) => {
    const health_keywords_data = [...this.props.health_keywords_data, keyword];
    const health_keywords = [...this.props.health_keywords, keyword.keyword];
    this.props.set_home_variable('health_keywords', health_keywords);
    this.props.set_home_variable('health_keywords_data', health_keywords_data);
  };

  edit_keyword = () =>
    this.setState({ loading: true }, () => {
      const { keyword, key_uuid } = this.state;
      const url = get_api_url('update_health_key');
      const payload = { health_keyword: keyword, keyword_uuid: key_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Keyword edited');
          this.close_edit();
          this.setState({ loading: false });
          this.update_keywords(keyword, key_uuid);
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  create_keyword = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_health_key');
      const payload = { health_keyword: this.state.keyword };
      post_api(url, payload, true)
        .then((res) => {
          this.props.set_notification_variable(true, 'success', 'Keyword added');
          this.setState({ keyword: '', open_create: false, loading: false });
          this.add_new_keyword(res.data.keyword);
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  toggle_create = () => this.setState({ open_create: !this.state.open_create });

  close_edit = () => this.setState({ open_edit: false, keyword: '', key_uuid: '' });

  render_create_keyword = () => {
    const { keyword, open_edit } = this.state;
    const btn_fn = open_edit ? this.edit_keyword : this.create_keyword;
    return (
      <div>
        <h4>{open_edit ? 'Edit' : 'Create'} keyword</h4>
        <CustomInput
          label="Enter health keyword *"
          onChange={this.onCommonChange}
          name="keyword"
          value={keyword}
          style={{ width: '250px' }}
        />
        <Button onClick={btn_fn} disabled={!keyword}>
          Submit
        </Button>
      </div>
    );
  };

  go_back = () => this.props.history.goBack();

  render() {
    const { loading, open_create, open_edit } = this.state;
    return (
      <CommonHeader show_back loading={loading} title="Health Keywords">
        <button onClick={this.toggle_create}>Create keyword</button>
        <CustomModal show={open_create} close={this.toggle_create}>
          {this.render_create_keyword()}
        </CustomModal>
        <CustomModal show={open_edit} close={this.close_edit}>
          {this.render_create_keyword()}
        </CustomModal>
        {this.render_data()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    health_keywords: state.home.health_keywords,
    class_types_status: state.loading.class_types_status,
    health_keywords_data: state.home.health_keywords_data
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    set_home_variable: (key, payload) => dispatch(actions.set_home_variable(key, payload)),
    get_class_types: () => dispatch(actions.get_class_types())
  });

export default connect(mapStateToProps, mapDispatchToProps)(HealthKeywords);
