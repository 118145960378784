import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import * as selectors from '../selectors';
import { post_api, put_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';
import { check_user_is_from_back_pain, get_err_message } from '../../util_functions';

export function* getPastGroupSessions(action) {
  try {
    yield put(actions.set_loading_variable('past_group_sessions_status', 'loading'));
    let page = 1;
    if (action.payload.page) {
      page = action.payload.page;
    }
    const curr_page = yield select(selectors.past_group_page);
    if (curr_page !== page) {
      const past_group_data = yield select(selectors.past_group_data);
      const data_index = past_group_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('list_group_sessions');
        const lifetreePayload = { sessions_type: 'past', page: action.payload.page };
        const backpainPayload = {
          sessions_type: 'past',
          page: action.payload.page,
          is_exclusive_for_backpain_client: action.payload.is_exclusive_for_backpain_client
        };
        const response = yield call(
          post_api,
          url,
          check_user_is_from_back_pain() === 0 ? lifetreePayload : backpainPayload,
          true
        );
        yield put(
          actions.set_sessions_variable('past_group_sessions', [...response.data.sessions])
        );
        yield put(
          actions.set_sessions_variable('past_group_sessions_data', [
            ...past_group_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable('past_group_sess_page_limit', response.data.page_limit)
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('past_group_sessions', [
            ...past_group_data[data_index].data
          ])
        );
      }
      yield put(actions.set_sessions_variable('past_group_sess_curr_page', page));
    }
    yield put(actions.set_loading_variable('past_group_sessions_status', 'success'));
  } catch (error) {
    console.log(error);
    yield put(actions.set_loading_variable('past_group_sessions_status', 'error'));
  }
}

export function* getFutureGroupSessions(action) {
  try {
    yield put(actions.set_loading_variable('future_group_sessions_status', 'loading'));
    let page = 1;
    if (action.payload.page) {
      page = action.payload.page;
    }
    const curr_page = yield select(selectors.future_group_page);
    if (curr_page !== page) {
      const future_group_data = yield select(selectors.future_group_data);
      const data_index = future_group_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('list_group_sessions');
        const lifetreePayload = { page: action.payload.page };
        const backpainPayload = {
          page: action.payload.page,
          is_exclusive_for_backpain_client: action.payload.is_exclusive_for_backpain_client
        };
        const response = yield call(
          post_api,
          url,
          check_user_is_from_back_pain() === 0 ? lifetreePayload : backpainPayload,
          true
        );
        yield put(
          actions.set_sessions_variable('future_group_sessions', [...response.data.sessions])
        );
        yield put(
          actions.set_sessions_variable('future_group_sessions_data', [
            ...future_group_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable('future_group_sess_page_limit', response.data.page_limit)
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('future_group_sessions', [
            ...future_group_data[data_index].data
          ])
        );
      }
      yield put(actions.set_sessions_variable('future_group_sess_curr_page', page));
    }
    yield put(actions.set_loading_variable('future_group_sessions_status', 'success'));
  } catch (error) {
    console.log(error);
    yield put(actions.set_loading_variable('future_group_sessions_status', 'error'));
  }
}

export function* getFutureSessions(action) {
  try {
    yield put(actions.set_loading_variable('future_sessions_status', 'loading'));
    let page = 1;
    if (action.payload) {
      page = action.payload;
    }
    const curr_page = yield select(selectors.future_sess_page);
    if (curr_page !== page) {
      const future_sessions_data = yield select(selectors.future_sessions_data);
      const data_index = future_sessions_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('get_all_sessions');
        const payload = {
          timezone: new Date().getTimezoneOffset(),
          page: action.payload
        };
        const response = yield call(post_api, url, payload, true);
        yield put(actions.set_sessions_variable('future_sessions', [...response.data.sessions]));
        yield put(
          actions.set_sessions_variable('future_sessions_data', [
            ...future_sessions_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable('future_sess_page_limit', response.data.page_limit)
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('future_sessions', [
            ...future_sessions_data[data_index].data
          ])
        );
      }
    }
    yield put(actions.set_sessions_variable('future_sess_curr_page', page));
    yield put(actions.set_loading_variable('future_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('future_sessions_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* getPastSessionsNew(action) {
  try {
    yield put(actions.set_loading_variable('past_sessions_status', 'loading'));
    let page = 1;
    if (action.payload) {
      page = action.payload;
    }
    const curr_page = yield select(selectors.past_sess_page);
    if (curr_page !== page) {
      const past_sessions_data = yield select(selectors.past_sessions_data);
      const data_index = past_sessions_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('get_all_past_sessions');
        const payload = {
          timezone: new Date().getTimezoneOffset(),
          page,
          item_limit: 300
        };
        const response = yield call(post_api, url, payload, true);
        yield put(actions.set_sessions_variable('past_sessions', [...response.data.sessions]));
        yield put(
          actions.set_sessions_variable('past_sessions_data', [
            ...past_sessions_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable('past_sess_page_limit', response.data.page_limit)
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('past_sessions', [...past_sessions_data[data_index].data])
        );
      }
    }
    yield put(actions.set_sessions_variable('past_sess_curr_page', page));
    yield put(actions.set_loading_variable('past_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('past_sessions_status', 'fail'));
    console.log('chat errpr is ', error);
  }
}

export function* getFutureFriendsSessions(action) {
  try {
    yield put(actions.set_loading_variable('future_friends_sessions_status', 'loading'));
    let page = 1;
    if (action.payload) {
      page = action.payload;
    }
    const curr_page = yield select(selectors.future_friends_sess_page);
    if (curr_page !== page) {
      const future_friends_sessions_data = yield select(selectors.future_friends_sessions_data);
      const data_index = future_friends_sessions_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('get_friends_sessions');
        const payload = {
          timezone: new Date().getTimezoneOffset(),
          page,
          item_limit: 300
        };
        const response = yield call(post_api, url, payload, true);
        yield put(
          actions.set_sessions_variable('future_friends_sessions', [...response.data.sessions])
        );
        yield put(
          actions.set_sessions_variable('future_friends_sessions_data', [
            ...future_friends_sessions_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable(
              'future_friends_sess_page_limit',
              response.data.page_limit
            )
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('future_friends_sessions', [
            ...future_friends_sessions_data[data_index].data
          ])
        );
      }
    }
    yield put(actions.set_sessions_variable('future_friends_sess_curr_page', page));
    yield put(actions.set_loading_variable('future_friends_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
    yield put(actions.set_loading_variable('future_friends_sessions_status', 'fail'));
  }
}

export function* getPastFriendsSessions(action) {
  try {
    yield put(actions.set_loading_variable('past_friends_sessions_status', 'loading'));
    let page = 1;
    if (action.payload) {
      page = action.payload;
    }
    const curr_page = yield select(selectors.past_friends_sess_page);
    if (curr_page !== page) {
      const past_friends_sessions_data = yield select(selectors.past_friends_sessions_data);
      const data_index = past_friends_sessions_data.findIndex((da) => da.page === page);
      if (data_index === -1) {
        const url = get_api_url('get_friends_sessions');
        const payload = {
          timezone: new Date().getTimezoneOffset(),
          page,
          item_limit: 300,
          is_past: 1
        };
        const response = yield call(post_api, url, payload, true);
        yield put(
          actions.set_sessions_variable('past_friends_sessions', [...response.data.sessions])
        );
        yield put(
          actions.set_sessions_variable('past_friends_sessions_data', [
            ...past_friends_sessions_data,
            { page, data: [...response.data.sessions] }
          ])
        );
        if (response.data.page_limit) {
          yield put(
            actions.set_sessions_variable('past_friends_sess_page_limit', response.data.page_limit)
          );
        }
      } else {
        yield put(
          actions.set_sessions_variable('past_friends_sessions', [
            ...past_friends_sessions_data[data_index].data
          ])
        );
      }
    }
    yield put(actions.set_sessions_variable('past_friends_sess_curr_page', page));
    yield put(actions.set_loading_variable('past_friends_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
    yield put(actions.set_loading_variable('past_friends_sessions_status', 'fail'));
  }
}

export function* getPastSessions() {
  try {
    yield put(actions.set_loading_variable('past_sessions_status', 'loading'));
    const url = get_api_url('get_all_past_sessions');
    const payload = { timezone: new Date().getTimezoneOffset() };
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_home_variable('past_sessions', [...response.data.sessions]));
    yield put(actions.set_loading_variable('past_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('past_sessions_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* getProposalInfo(action) {
  try {
    yield put(actions.set_loading_variable('get_proposal_info_status', 'loading'));
    const url = get_api_url('v2/community/proposals/info');
    const {payload} = action;
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_sessions_variable('get_proposal', response.data));
    yield put(actions.set_loading_variable('get_proposal_info_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_proposal_info_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* promoteProposal(action) {
  try {
    yield put(actions.set_loading_variable('get_proposal_promote_status', 'loading'));
    const url = get_api_url('v2/community/proposals/promote');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);
    yield put(actions.set_sessions_variable('proposals_promote', response.data));
    yield put(actions.set_loading_variable('get_proposal_promote_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_proposal_promote_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* rejectProposal(action) {
  try {
    yield put(actions.set_loading_variable('get_proposal_reject_status', 'loading'));
    const url = get_api_url('v2/community/proposals/reject');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);
    yield put(actions.set_sessions_variable('proposals_promote', response.data));
    yield put(actions.set_loading_variable('get_proposal_reject_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_proposal_reject_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* acceptProposal(action) {
  try {
    yield put(actions.set_loading_variable('get_proposal_accept_status', 'loading'));
    const url = get_api_url('v2/community/proposals/accept');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);
    yield put(actions.set_sessions_variable('proposals_accept', response.data));
    yield put(actions.set_loading_variable('get_proposal_accept_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_proposal_accept_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* proposalTeacherRequest(action) {
  try {
    yield put(actions.set_loading_variable('proposal_teacher_request_status', 'loading'));
    const url = get_api_url('v2/community/proposals/requests/info');
    const {payload} = action;
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_sessions_variable('proposal_teacher_request_response', response.data));
    yield put(actions.set_loading_variable('proposal_teacher_request_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('proposal_teacher_request_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* promoteFillFormInfo(action) {
  try {
    yield put(actions.set_loading_variable('promote_form_info_status', 'loading'));
    const url = get_api_url('v2/community/proposals/promote/form_info');
    const {payload} = action;
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_sessions_variable('promote_form_info_response', response.data));
    yield put(actions.set_loading_variable('promote_form_info_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('promote_form_info_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* communityProposalAccept(action) {
  try {
    yield put(actions.set_loading_variable('community_proposal_accept_status', 'loading'));
    const url = get_api_url('v2/community/proposals/accept');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);

    yield put(actions.set_loading_variable('community_proposal_accept_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('community_proposal_accept_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* communityProposalIgnore(action) {
  try {
    yield put(actions.set_loading_variable('community_proposal_ignore_status', 'loading'));
    const url = get_api_url('v2/community/proposals/ignore');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);

    yield put(actions.set_loading_variable('community_proposal_ignore_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('community_proposal_ignore_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* communityCancelProposal(action) {
  try {
    yield put(actions.set_loading_variable('community_cancel_proposal_status', 'loading'));
    const url = get_api_url('v2/community/proposals/cancel');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);

    yield put(actions.set_loading_variable('community_cancel_proposal_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('community_cancel_proposal_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* updateProposalClassStatus(action) {
  try {
    yield put(
      actions.set_loading_variable('community_update_proposal_class_status_status', 'loading')
    );
    const url = get_api_url('v2/community/proposals/simulate/trial_end');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);

    yield put(
      actions.set_loading_variable('community_update_proposal_class_status_status', 'success')
    );
  } catch (error) {
    yield put(
      actions.set_loading_variable('community_update_proposal_class_status_status', 'fail')
    );
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export function* modifyProposalsData(action) {
  try {
    yield put(actions.set_loading_variable('modify_proposals_data_status', 'loading'));
    const url = get_api_url('v2/community/proposals/modify');
    const {payload} = action;
    const response = yield call(put_api, url, payload, true);

    yield put(actions.set_loading_variable('modify_proposals_data_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('modify_proposals_data_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', get_err_message(error)));
  }
}

export default function* sessionsSaga() {
  yield all([
    takeLatest(t.get_past_sessions, getPastSessionsNew),
    takeLatest(t.get_past_group_sessions, getPastGroupSessions),
    takeLatest(t.get_future_group_sessions, getFutureGroupSessions),
    takeLatest(t.get_future_sessions, getFutureSessions),
    takeLatest(t.get_future_friends_sessions, getFutureFriendsSessions),
    takeLatest(t.get_past_friends_sessions, getPastFriendsSessions),
    takeLatest(t.get_proposal_info, getProposalInfo),
    takeLatest(t.promote_proposal, promoteProposal),
    takeLatest(t.reject_proposal, rejectProposal),
    takeLatest(t.accept_proposal, acceptProposal),
    takeLatest(t.proposal_teacher_request, proposalTeacherRequest),
    takeLatest(t.promote_form_info, promoteFillFormInfo),
    takeLatest(t.community_proposal_accept, communityProposalAccept),
    takeLatest(t.community_proposal_ignore, communityProposalIgnore),
    takeLatest(t.community_cancel_proposal, communityCancelProposal),
    takeLatest(t.community_update_proposal_class_status, updateProposalClassStatus),
    takeLatest(t.modify_proposal_data, modifyProposalsData)
  ]);
}
