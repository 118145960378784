import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CustomModal from '../../util_components/custom_modal';
import CommonHeader from "../../util_components/common_header";

import Button from '../../util_components/button';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomDatePicker from "../../util_components/custom_date_picker";
import { image_error_replace, get_date_filter_data_timezone_utc } from '../../../util_functions';
import { date_drop_for_report, day_format, time_format } from '../../../constants';

import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import SmsWindow from './sms_window';

class SmsReport extends Component {
  state = {
    loading: false,
    date_filter_type: 'THIS WEEK',
    sms_data: [],
    send_sms_uuid: '',
    send_sms_name: '',
    sms_window_open: false,
    sms_window_student_uuid: '',
    concierge_teachers: [],
    concierge_teacher_uuid: ''
  };

  componentDidMount() {
    this.load_data();

    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }

    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.opt_in_concierge === 1);
    const teachers_list = all_teachers.map((teacher) => ({
        value: teacher.uuid,
        label: `${teacher.first_name} ${teacher.last_name}`
      }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ concierge_teachers: [{ value: 'all', label: 'ALL' }, ...teachers_list] });
  };

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { date_filter_type, start_date, end_date } = this.state;
      const url = get_api_url('get_sms_data');
      const date_data = get_date_filter_data_timezone_utc(
        date_filter_type,
        start_date,
        end_date,
        this.props.iana_timezone
      );
      const payload = {
        start_date: date_data.utc_start_date,
        end_date: date_data.utc_end_date
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ sms_data: [...res.data.sms_data], loading: false });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.setState({ loading: false });
          this.props.set_notification_variable(true, 'error', err_message);
        });
    });
  };

  change_date_filter = (e) => {
    this.setState({ date_filter_type: e.target.value }, () => {
      if (this.state.date_filter_type !== 'CUSTOM') {
        this.load_data();
      }
    });
  };

  handle_date_change = (name, val) => {
    this.setState({ [name]: val });
  };

  change_concierge = (e) => {
    this.setState({ concierge_teacher_uuid: e.target.value });
  };

  clear_concierge = () => {
    this.setState({ concierge_teacher_uuid: '' });
  };

  render_filters = () => {
    const { date_filter_type, start_date, end_date, concierge_teachers, concierge_teacher_uuid } =
      this.state;
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
      >
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a date filter"
            options={date_drop_for_report}
            onChange={this.change_date_filter}
            value={date_filter_type}
            style={{ width: '200px' }}
          />
        </div>
        {this.state.date_filter_type === 'CUSTOM' ? (
          <>
            <div className="daily-report-item">
              <CustomDatePicker
                label="Start Date"
                value={start_date}
                handleChange={(val) => this.handle_date_change('start_date', val)}
                maxDate={end_date}
              />
            </div>
            <div className="daily-report-item">
              <CustomDatePicker
                label="End Date"
                value={end_date}
                handleChange={(val) => this.handle_date_change('end_date', val)}
                minDate={start_date}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <button onClick={this.load_data}>Submit</button>
          </>
        ) : null}
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a concierge"
            options={concierge_teachers}
            onChange={this.change_concierge}
            value={concierge_teacher_uuid}
            style={{ width: '200px' }}
            showClear
            clear_fn={this.clear_concierge}
          />
        </div>
      </div>
    );
  };

  open_sms_window = (sms) => {
    this.setState({
      sms_window_open: true,
      sms_window_student_uuid: sms.student_uuid,
      send_sms_name: sms.student_name,
      send_sms_uuid: sms.uuid
    });
  };

  close_sms_window = (sms) => {
    this.setState({
      sms_window_open: false,
      sms_window_student_uuid: '',
      send_sms_name: '',
      send_sms_uuid: ''
    });
  };

  render_single_sms = (sms) => {
    const student_url = `/studentprofile?id=${  sms.student_uuid}`;
    const replies_open = this.state[`${sms.uuid}_open`];
    const concierge_url = `/teacherprofile?id=${  sms.concierge_uuid}`;
    const client_domain = sms.client_domain
      ? sms.client_domain === 'lifestyle'
        ? ''
        : 'Lifetree Health'
      : '';
    return (
      <div key={sms.uuid}>
        <div className="ct-row">
          <div className="ct-col ct-hf ct-la">
            <a className="link-no-dec" href={student_url}>
              <div className="profile-img-name">
                <img src={sms.student_photo} onError={image_error_replace} alt="x" />
                {sms.student_name}
              </div>
            </a>
            {client_domain === '' ? null : (
              <div className="bg-mytPurple px-2.5 py-1 w-fit text-white text-xs font-bold leading-3 rounded-lg mx-auto flex justify-center items-center">
                  {client_domain}
                </div>
            )}
          </div>
          <div className="ct-col ct-sm-font">{sms.sms_body}</div>
          <div className="ct-col ct-hf ct-sm-font">
            <div>{moment(sms.created_date).tz(this.props.iana_timezone).format(day_format)}</div>
            <div>{moment(sms.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
          </div>
          <div className="ct-col ct-si ct-hf">
            {sms.reply_sent == 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </div>
          <div className="ct-col ct-hf">
            {sms.concierge_uuid ? (
              <a className="link-no-dec" href={concierge_url}>
                <div className="profile-img-name">
                  <img src={sms.concierge_photo} onError={image_error_replace} alt="x" />
                  {sms.concierge_name}
                </div>
              </a>
            ) : (
              sms.concierge_name
            )}
          </div>
          <div className="ct-col ct-qf">
            <button onClick={() => this.open_sms_window(sms)}>SMS</button>
          </div>
        </div>
        {replies_open
          ? sms.replies.map((sms1) => (
                <div className="ct-row" key={sms1.uuid}>
                  <div className="ct-col ct-hf ct-la" />
                  <div className="ct-col ct-sm-font">{sms1.sms_body}</div>
                  <div className="ct-col ct-hf ct-sm-font">
                    <div>
                      {moment(sms1.created_date).tz(this.props.iana_timezone).format(day_format)}
                    </div>
                    <div>
                      {moment(sms1.created_date).tz(this.props.iana_timezone).format(time_format)}
                    </div>
                  </div>
                  <div className="ct-col ct-hf" />
                </div>
              ))
          : null}
      </div>
    );
  };

  render_data = () => {
    const { sms_data, concierge_teacher_uuid } = this.state;
    let filter_sms = [...sms_data];
    if (concierge_teacher_uuid) {
      filter_sms = sms_data.filter((s) => {
        if (concierge_teacher_uuid === 'all') {
          return !!s.concierge_uuid;
        } return s.concierge_uuid === concierge_teacher_uuid;
      });
    }
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-hf ct-la">Student</div>
          <div className="ct-col">Body</div>
          <div className="ct-col ct-hf ct-sm-font">Sent</div>
          <div className="ct-col ct-hf">Reply Sent</div>
          <div className="ct-col ct-hf">Concierge</div>
          <div className="ct-col ct-qf" />
        </div>
        {filter_sms.map(this.render_single_sms)}
      </div>
    );
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value });
  };

  on_reply_sent = () => {
    const { sms_data, send_sms_uuid } = this.state;
    const new_sms_data = [...sms_data];
    const sms = new_sms_data.find((s) => s.uuid === send_sms_uuid);
    if (sms) {
      sms.reply_sent = 1;
      this.setState({ sms_data: new_sms_data });
    }
  };

  render() {
    const { loading, sms_window_open, sms_window_student_uuid, send_sms_name, send_sms_uuid } =
      this.state;

    return (
      <CommonHeader loading={loading} title="SMS Report">
        <div style={{ minHeight: '400px' }}>
          {this.render_filters()}
          {this.render_data()}
        </div>
        {sms_window_open ? (
          <SmsWindow
            student_uuid={sms_window_student_uuid}
            student_name={send_sms_name}
            close_fn={this.close_sms_window}
            send_success_notification={this.on_reply_sent}
            original_message_uuid={send_sms_uuid}
          />
        ) : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    teachers_list: state.teachers.teachers_list,
    teachers_list_status: state.teachers.teachers_list_status
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(SmsReport);
