import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";
import CustomModal from '../../util_components/custom_modal';
import CreateEditOffer from './create_edit_offer';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import { getCookie } from '../../../util_functions';
import { time_format, day_format_year } from '../../../constants';

class BolierPlate extends Component {
  state = {
    loading: true,
    coupon_offers: [],
    create_offer_open: false
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_coupons_offers');
      post_api(url, null, true)
        .then((res) => {
          this.setState({ coupon_offers: [...res.data.coupon_offers], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_coupon = (coupon) => {
    const student_url = `/studentprofile?id=${  coupon.student_uuid}`;
    return (
      <div className="ct-row" key={coupon.coupon_code}>
        <div className="ct-col ct-hf">{coupon.offer_name}</div>
        <div className="ct-col ct-sm-font">{coupon.description}</div>
        <div className="ct-col ct-sm-font ct-hf">{coupon.type}</div>
        <div className="ct-col ct-sm-font ct-hf">{`${coupon.value}${coupon.value_type === 'PERC' ? '%' : coupon.currency}`}</div>
        <div className="ct-col ct-hf ct-sm-font">
          <div>
            {moment(coupon.expiry_date).tz(this.props.iana_timezone).format(day_format_year)}
          </div>
          <div>{moment(coupon.expiry_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-hf">
          <button className="ct-small-btn" onClick={() => this.open_offer_details(coupon.uuid)}>
            Details
          </button>
        </div>
      </div>
    );
  };

  create_coupon_offer = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_coupon_offer');
      post_api(url, payload, true)
        .then(() => {
          this.close_create_offer();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_offer_details = (uuid) => {
    this.props.history.push({ pathname: `/coupon_offers/${uuid}` });
  };

  open_create_offer = () => this.setState({ create_offer_open: true });

  close_create_offer = () => this.setState({ create_offer_open: false });

  render_coupons = () => {
    const { coupon_offers } = this.state;
    const is_super_user = this.props.config_values.user_access === 'SUPER_USER';
    const care_email = getCookie('cms_email');
    const create_access =
      care_email === 'harsha@myyogateacher.com' ||
      care_email === 'kuldeep@myyogateacher.com' ||
      care_email === 'abhinash@myyogateacher.com';
    return (
      <div style={{ marginTop: '30px' }}>
        {create_access ? (
          <div>
            <button onClick={this.open_create_offer}>Create Offer</button>
          </div>
        ) : null}
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-hf">Name</div>
            <div className="ct-col">Description</div>
            <div className="ct-col ct-hf">type</div>
            <div className="ct-col ct-hf">Discount</div>
            <div className="ct-col ct-hf">Offer Expiry</div>
            <div className="ct-col ct-hf">Details</div>
          </div>
          {coupon_offers.map(this.render_single_coupon)}
        </div>
      </div>
    );
  };

  render() {
    const { loading, create_offer_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Coupon Offers">
        {this.render_coupons()}
        <CustomModal show={create_offer_open} close={this.close_create_offer}>
          {create_offer_open ? <CreateEditOffer submit_fn={this.create_coupon_offer} /> : null}
        </CustomModal>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    config_values: state.home.config_values
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(BolierPlate);
