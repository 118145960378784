import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../util_components/common_header";

import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomDatePicker from "../../util_components/custom_date_picker";
import { image_error_replace, get_date_filter_data_timezone_utc } from '../../../util_functions';
import { date_drop_for_report, day_format, time_format } from '../../../constants';

import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class FlaggedSMS extends Component {
  state = {
    loading: false,
    date_filter_type: 'THIS WEEK',
    flagged_sms_data: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { date_filter_type, start_date, end_date } = this.state;
      const path = `/v2/notifications/student/sms_flagged`;
      const url = get_api_url(path, false, true);
      const date_data = get_date_filter_data_timezone_utc(
        date_filter_type,
        start_date,
        end_date,
        this.props.iana_timezone
      );
      const payload = {
        start_date: date_data.utc_start_date,
        end_date: date_data.utc_end_date
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ flagged_sms_data: [...res.data.data.items], loading: false });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.setState({ loading: false });
          this.props.set_notification_variable(true, 'error', err_message);
        });
    });
  };

  change_date_filter = (e) => {
    this.setState({ date_filter_type: e.target.value }, () => {
      if (this.state.date_filter_type !== 'CUSTOM') {
        this.load_data();
      }
    });
  };

  handle_date_change = (name, val) => {
    this.setState({ [name]: val });
  };

  render_filters = () => {
    const { date_filter_type, start_date, end_date } = this.state;
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
      >
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a date filter"
            options={date_drop_for_report}
            onChange={this.change_date_filter}
            value={date_filter_type}
            style={{ width: '200px' }}
          />
        </div>
        {this.state.date_filter_type === 'CUSTOM' ? (
          <>
            <div className="daily-report-item">
              <CustomDatePicker
                label="Start Date"
                value={start_date}
                handleChange={(val) => this.handle_date_change('start_date', val)}
                maxDate={end_date}
              />
            </div>
            <div className="daily-report-item">
              <CustomDatePicker
                label="End Date"
                value={end_date}
                handleChange={(val) => this.handle_date_change('end_date', val)}
                minDate={start_date}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <button onClick={this.load_data}>Submit</button>
          </>
        ) : null}
      </div>
    );
  };

  render_single_flagged_sms = (sms) => {
    const student_url = `/studentprofile?id=${  sms.student_uuid}`;
    return (
      <div key={sms.uuid}>
        <div className="ct-row">
          <div className="ct-col ct-hf ct-la">
            <a className="link-no-dec" href={student_url}>
              <div className="profile-img-name">
                <img src={sms.student_photo} onError={image_error_replace} alt="x" />
                {sms.name}
              </div>
            </a>
          </div>
          <div className="ct-col ct-sm-font">{sms.message}</div>
          <div className="ct-col ct-hf ct-sm-font">
            <div>{moment(sms.created_date).tz(this.props.iana_timezone).format(day_format)}</div>
            <div>{moment(sms.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
          </div>
        </div>
      </div>
    );
  };

  render_data = () => {
    console.log('render_data');
    const { flagged_sms_data } = this.state;
    console.log(flagged_sms_data);
    const filter_sms = [...flagged_sms_data];
    console.log(filter_sms);
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-hf ct-la">Student</div>
          <div className="ct-col">Body</div>
          <div className="ct-col ct-hf ct-sm-font">Sent</div>
        </div>
        {filter_sms.map(this.render_single_flagged_sms)}
      </div>
    );
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value });
  };

  render() {
    const { loading } = this.state;
    console.log(loading);

    return (
      <CommonHeader loading={loading} title="SMS Report">
        <div style={{ minHeight: '400px' }}>
          {this.render_filters()}
          {this.render_data()}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    teachers_list: state.teachers.teachers_list,
    teachers_list_status: state.teachers.teachers_list_status
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedSMS);
