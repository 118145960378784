import * as t from '../action_types';

const initialState = {
  get_content_category_status: 'none',
  get_content_category_response: [],
  get_category_list_status: 'none',
  get_category_list_response: [],
  add_new_tag_status: 'none',
  delete_tag_status: 'none',
  update_tag_status: 'none',
  get_all_blogs_status: 'none',
  get_all_blogs_response: {},
  get_all_associated_tags: [],
  get_all_associated_tags_status: 'none',
  update_tag_associations_status: 'none',
  add_new_category_status: 'none',
  update_category_status: 'none'
};

const set_content_variable = (state, payload) => ({
    ...state,
    [payload.key]: payload.payload
  });

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_content_variable:
      return set_content_variable(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
