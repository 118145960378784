import * as t from '../action_types';

const initialState = {
  workshops: []
};

const set_workshops_variable = (state, payload) => ({
    ...state,
    [payload.key]: payload.payload
  });

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_workshops_variable:
      return set_workshops_variable(state, action.payload);
    default:
      return state;
  }
}
