import React from 'react';
import { debounce } from '../../../util_functions';

export default class ScrollContainer extends React.Component {
  state = {
    show_to_top_icon: false
  };

  scroll_ref = React.createRef();

  handle_scroll = debounce(() => {
    try {
      const {top} = this.scroll_ref.current.getBoundingClientRect();
      const {show_to_top_icon} = this.state;
      if (show_to_top_icon && top > -50) {
        this.setState({ show_to_top_icon: false });
      } else if (!show_to_top_icon && top < -50) {
        this.setState({ show_to_top_icon: true });
      }
    } catch {}
  }, 250);

  componentDidMount() {
    window.addEventListener('scroll', this.handle_scroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handle_scroll);
  }

  scroll_to_top = (e) => {
    try {
      this.scroll_ref.current.scrollIntoView(false, { behavior: 'smooth' });
    } catch {}
  };

  render() {
    return (
      <div ref={this.scroll_ref}>
        {this.state.show_to_top_icon ? (
          <div className="scr-con-img" onClick={this.scroll_to_top}>
            <img src="https://images.myyogateacher.com/icons/arrow_white_top@2x.png" alt="x" />
          </div>
        ) : null}
      </div>
    );
  }
}
