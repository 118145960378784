import * as t from '../action_types';

const initialState = {
  all_students: [],
  search_students_list: [],
  filtered_students: [],
  search_value: '',
  yoga_level: 'ALL',
  credits: 'ALL',
  user_type: 'REAL_USER'
};

const set_students_variable = (state, payload) => ({
    ...state,
    [payload.key]: payload.payload
  });
const filter_students = (state, payload) => {
  let students = [];
  if (payload.key === 'all_students') {
    students = [...payload.payload];
  } else {
    students = [...state.all_students];
  }
  const search_value = payload.key === 'search_value' ? payload.payload : state.search_value;
  const credits = payload.key === 'credits' ? payload.payload : state.credits;
  const level = payload.key === 'yoga_level' ? payload.payload : state.yoga_level;
  const user_type = payload.key === 'user_type' ? payload.payload : state.user_type;
  const search_list = students.filter(
    (student) =>
      student.first_name.toLowerCase().indexOf(search_value.toLowerCase()) !== -1 ||
      student.last_name.toLowerCase().indexOf(search_value.toLowerCase()) !== -1 ||
      student.middle_name.toLowerCase().indexOf(search_value.toLowerCase()) !== -1 ||
      student.email.toLowerCase().indexOf(search_value.toLowerCase()) !== -1
  );
  search_list.sort((a, b) => a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 1);
  // filter with credits
  let credits_filtered = [];
  if (credits === 'ALL') {
    credits_filtered = [...search_list];
  } else if (credits === '>125') {
    credits_filtered = search_list.filter((student) => student.credits > 125);
  } else {
    credits_filtered = search_list.filter((student) => student.credits === parseInt(credits, 10));
  }

  // filter with levels
  let level_filtered = [];
  if (level === 'ALL') {
    level_filtered = [...credits_filtered];
  } else {
    level_filtered = credits_filtered.filter(
      (student) => level.toLowerCase() === student.yoga_level.toLowerCase()
    );
  }

  // filter with user types
  let type_filtered = [];
  if (user_type === 'ALL') {
    type_filtered = [...level_filtered];
  } else if (user_type === 'REAL_USER') {
    type_filtered = level_filtered.filter(
      (student) => !(student.test_user === 1 || student.teacher_evaluator === 1)
    );
  } else if (user_type === 'TEST_USER') {
    type_filtered = level_filtered.filter((student) => student.test_user === 1);
  } else {
    type_filtered = level_filtered.filter((student) => student.teacher_evaluator === 1);
  }

  // sort by created date
  const sorted_students = type_filtered.sort(
    (a, b) => new Date(b.created_date) - new Date(a.created_date)
  );
  return {
    ...state,
    [payload.key]: payload.payload,
    filtered_students: [...sorted_students]
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_students_variable:
      return set_students_variable(state, action.payload);
    case t.filter_students:
      return filter_students(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
