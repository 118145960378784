import React, { Component } from 'react';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import './updates.css';

export default class ApproveUpdates extends Component {
  onApprove = (key) => {
    const {uuid} = this.props.all_teacher_details;
    const new_payload = this.props.all_teacher_details.teacher_edit_auditing[key];
    let url;
    if (key === 'resume_document_url' || key === 'profile_photo' || key === 'video_url') {
      url = get_api_url('update_photo_video_resume');
    } else if (key === 'certificates' || key === 'yoga_types_teaching') {
      url = get_api_url('update_yoga_details');
    } else {
      url = get_api_url('update_teacher');
    }
    const payload = {
      teacher_uuid: uuid
    };
    if (key === 'years_of_yoga_practise') {
      payload[key] = new_payload.toString();
    } else {
      payload[key] = new_payload;
    }
    post_api(url, payload, true).then((result) => {
      this.props.reload_details();
    });
  };

  render() {
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.props.close_approve_updates}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Approve Updates</div>
        </div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th className="updates-column">KEY</th>
              <th className="updates-column">PREV VALUE</th>
              <th className="updates-column">NEW VALUE</th>
              <th className="updates-column">APPROVE</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(this.props.teacher_details).map((detail, index) => {
              if (detail === 'id') {
                return null;
              }
              return (
                <tr key={index + detail} className="updates-row">
                  <td className="updates-column">{detail}</td>
                  <td className="updates-column">
                    {typeof this.props.teacher_details[detail].old_value === 'string' ||
                    typeof this.props.teacher_details[detail].old_value === 'number'
                      ? this.props.teacher_details[detail].old_value
                      : this.props.teacher_details[detail]?.old_value?.map((item, index) => (
                          <p key={index}>{JSON.stringify(item, null, `       `)}</p>
                        ))}
                  </td>
                  <td className="updates-column">
                    {typeof this.props.teacher_details[detail].new_value === 'string' ||
                    typeof this.props.teacher_details[detail].new_value === 'number' ? (
                      this.props.teacher_details[detail].new_value
                    ) : Array.isArray(this.props.teacher_details[detail].new_value) ? (
                      this.props.teacher_details[detail].new_value.map((item, index) => (
                        <p key={index}>{JSON.stringify(item, null, `       `)}</p>
                      ))
                    ) : (
                      <p>{JSON.stringify(this.props.teacher_details[detail].new_value)}</p>
                    )}
                  </td>
                  <td className="updates-column">
                    <button className="approve-button" onClick={() => this.onApprove(detail)}>
                      Approve
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
