import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../../util_components/common_header";
import CustomInput from '../../../util_components/custom_input';
import { image_error_replace } from '../../../../util_functions';
import { delete_api, get_api, post_api, put_api } from '../../../../redux/api_funcs';

import './consults.css';
import { get_api_url } from '../../../../utils/urls';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';

export const mapped_student_timeslots = {
  '04-07': 'Early morning (4am - 7am)',
  '07-12': 'Morning (7am -12pm)',
  '12-17': 'Afternoon (12pm - 5pm)',
  '17-22': 'Evening (5pm - 10pm)'
  // 'Some other - specify"
};

class RecommendationsPlayground extends Component {
  state = {
    loading: false,
    loaded: false,
    sessions: [],
    session_details: {},
    student_details: {},
    show_raw_data: false,
    raw_data_search: '',

    rec_sessions: {},
    notes: '',
    notes_temp: '',
    notes_open: false,
    attachments: [],
    add_attachment: false,
    selected_file: null,
    delete_open: false,
    delete_details: {},
    student_search: '',
    rec_teachers: [],
    student: {},
    count: 3,
    use_student_preferred_time: false,
    studentGoals: [],
    group_sessions: [],
    workshoprecommendations: { recommendations: [] }
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const studentEmail = url.searchParams.get('email');
    if (studentEmail) {
      this.setState(
        {
          student_search: studentEmail
        },
        () => {
          this.getRecom();
          this.getGroupRecom();
          this.getWorkshopRecom();
        }
      );
    }
  }

  render_recommended_teachers = () => {
    const { rec_teachers } = this.state;
    try {
      return (
        <div className="rec-block" style={{ flexDirection: 'column' }}>
          {rec_teachers.map((teacher) => {
            const teacher_url = `/teacherprofile?id=${  teacher.teacher_uuid}`;

            const display_score = `${teacher.private_favourite}${teacher.teacher_availability_score}${teacher.goal_match}${teacher.group_favourite}${teacher.reco_availability}`;
            return (
              <div className="rec-teacher" style={{ marginBottom: '20px' }}>
                <a className="link-no-dec" href={teacher_url}>
                  <div className="profile-img-name rec-teacher-image">
                    <img src={teacher.profile_photo} onError={image_error_replace} alt="x" />
                    {teacher.name}
                  </div>
                </a>
                {/* <div className="rec-teacher-image">
                                    <img src={teacher.avatar_round} onError={image_error_replace} />
                                </div> */}
                {/* <div className="rec-teacher-detail">{teacher.name}</div> */}
                <div className="rec-teacher-detail" style={{ fontSize: '14px' }}>
                  Score - {display_score}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Practise - {teacher.years_of_yoga_practise} yrs
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Teaching - {teacher.years_of_yoga_teaching_experience} yrs
                </div>
                <div className="rec-teacher-types ct-sm-font">
                  Types:
                  {teacher.yoga_types_teaching.map((type) => (
                      <div className="rec-teacher-type">
                        {type.name} - {type.level}
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  getRecom() {
    if (!this.state.student_search) {
      alert('Please enter email');
      return;
    }
    this.setState({
      loading: true
    });
    const url = get_api_url(
      `/v2/recommendations/care/1on1?email=${encodeURIComponent(this.state.student_search)}&count=${this.state.count}`,
      false,
      true
    );
    get_api(url, true)
      .then((res) => {
        this.setState(
          { rec_teachers: res.data.teachers, student: res.data.student, loading: false },
          () => {
            const studentGoals = this.state.student.goals;
            this.setState({
              studentGoals,
              primaryGoals: this.state.student.primary_goal
            });
          }
        );
      })
      .catch((e) => {
        alert(e.response.data.message);
        this.setState({
          loading: false
        });
      });
  }

  getGroupRecom() {
    if (!this.state.student_search) {
      alert('Please enter email');
      return;
    }

    this.setState({
      loading: true
    });

    const body = {
      is_private: 0,
      app_platform: 'web_app',
      only_recommendation: true,
      recommendation_limit: this.state.count,
      interleave_preferred_time: false,
      email: this.state.student_search
    };

    if (
      this.state.use_student_preferred_time === true ||
      this.state.use_student_preferred_time === 'true' ||
      this.state.use_student_preferred_time === 1
    ) {
      body.interleave_preferred_time = true;
    }

    const url = get_api_url(`/v2/groups/sessions/recommendations`, false, true);
    post_api(url, body, true)
      .then((res) => {
        this.setState({ group_sessions: res.data.recommend_sessions, loading: false });
      })
      .catch((e) => {
        alert(e.response.data.message);
        this.setState({
          loading: false
        });
      });
  }

  getWorkshopRecom() {
    if (!this.state.student_search) {
      alert('Please enter email');
      return;
    }
    this.setState({
      loading: true
    });

    const url = get_api_url(
      `/v2/recommendations/care/workshop?email=${encodeURIComponent(this.state.student_search)}&count=${this.state.count}`,
      false,
      true
    );
    get_api(url, true)
      .then((res) => {
        this.setState({
          workshoprecommendations: res.data.recommendations,
          loading: false,
          student_yoga_level: res.data.recommendations.student.yoga_level
        });
      })
      .catch((e) => {
        alert(e.response.data.message);
        this.setState({
          loading: false
        });
      });
  }

  editInWorkspace = () => {
    this.setState({
      loading: true
    });

    const url = get_api_url(
      `/v2/recommendations/care/recommendations_workspace/users/${this.state.student.uuid}?count=${this.state.count}`,
      false,
      true
    );
    post_api(url, null, true)
      .then((res) => {
        this.setState({
          rec_teachers: res.data.teachers,
          student: res.data.student,
          loading: false,
          available_goals: res.data.available_goals,
          student_search: res.data.student.email
        });
      })
      .catch((e) => {
        alert(e.response.data.message);
        this.setState({
          loading: false
        });
      });
  };

  isGoalPresent = (goal) => this.state.studentGoals.includes(goal);

  updateGoalsAndRecomputeRecommendations = () => {
    this.setState({ loading: true });
    const url = get_api_url(
      `/v2/recommendations/care/recommendations_workspace/users/${this.state.student.uuid}/goals?count=${this.state.count}`,
      false,
      true
    );
    put_api(url, { goals: this.state.studentGoals.toString() }, true)
      .then((res) => {
        this.setState({
          rec_teachers: res.data.teachers,
          student: res.data.student,
          loading: false,
          available_goals: res.data.available_goals,
          student_search: res.data.student.email
        });
      })
      .catch((e) => {
        alert(e.response.data.message);
        this.setState({
          loading: false
        });
      });
  };

  formattedGoal = (goal) => {
    if (goal === 'Reduce Stress & Anxiety') {
      goal = 'Stress';
    } else if (
      goal === 'Improve Strength & Flexibility' ||
      goal === 'Increase strength and flexibility'
    ) {
      goal = 'Flexibility';
    } else if (goal === 'Mindfulness & Inner Calm') {
      goal = 'Mindfulness & Inner Calm';
    } else if (goal === 'Heal from a medical condition like pain or injury') {
      goal = 'Heal';
    } else if (goal === 'Sleep better') {
      goal = 'Sleep';
    } else if (goal === 'Ease stress and boost mental health') {
      goal = 'Stress';
    } else if (goal === 'Lose weight') {
      goal = 'Weight';
    } else if (goal === 'Advance my yoga practice') {
      goal = 'Advance';
    }

    return goal;
  };

  render_student_preferred_time = () => {
    const preferred_time = this.state.student.student_preferred_time;
    const parsed_data_list = JSON.parse(preferred_time);
    const data_list = parsed_data_list.sort();
    return (
      <div>
        {data_list.length == 0 ? (
          <div>
            <span>No Preferred Time</span>
              <br />
              <br />
          </div>
        ) : (
          <div>
            {data_list?.map((l_item) => {
              if (l_item) {
                return (
                  <>
                    <span>{mapped_student_timeslots[`${l_item}`]}</span>
                    <br />
                    <br />
                  </>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { loading, loaded, show_raw_data, notes_open, add_attachment, delete_attachment } =
      this.state;
    return (
      <CommonHeader
        loading={loading}
        show_back
        title={`Teacher Recommendation ${this.state.student && this.state.student.is_recommendations_dummy ? ' - Editing Workspace' : ''}`}
      >
        <CustomInput
          name="Student email"
          label="student email"
          value={this.state.student_search}
          onChange={(e) => {
            this.setState({ student_search: e.target.value });
          }}
         />
        <CustomInput
          name="Top n Recommendations"
          label="Top n Recommendations"
          value={this.state.count}
          onChange={(e) => {
            this.setState({
              count: e.target.value != '' ? parseInt(e.target.value) : e.target.value
            });
          }}
         />
        <CustomInput
          name="Use student preferred time"
          label="Use student preferred time"
          value={this.state.use_student_preferred_time}
          onChange={(e) => {
            let val = false;
            if (e.target.value.toLowerCase() == 'true') {
              val = true;
            } else if (e.target.value == '1') {
              val = true;
            } else {
              val = e.target.value;
            }

            this.setState({ use_student_preferred_time: val });
          }}
         />

        <button
          onClick={() => {
            this.getRecom();
            this.getGroupRecom();
            this.getWorkshopRecom();
          }}
        >
          Get Recommendations
        </button>
        {this.state.student.goals ? (
          <ExcelFile
            element={<button>Download CSV</button>}
            filename={`recommendations_${this.state.student_search}`}
          >
            <ExcelSheet data={this.getRecommendationsData()} name="Recommendations">
              <ExcelColumn label="Teacher Name" value="name" />
              <ExcelColumn label="Overall Score 5 digits" value="recommendation_score" />
              <ExcelColumn
                label="Favorite Teacher ( student joined 3 1-1 classes in last 10 1-1 classes with this teacher )"
                value="private_favourite"
              />
              <ExcelColumn
                label="Teacher Availability Check ( 7 hours in 7 days )"
                value="teacher_availability_score"
              />
              <ExcelColumn
                label="Allow Teacher trial Available"
                value="allow_teacher_trial_availability"
              />
              <ExcelColumn
                label="Teacher - Hours available in next 7 days"
                value="teacher_7_day_availability"
              />
              <ExcelColumn label="Teacher Goals" value="goals" />
              <ExcelColumn label="Student goals" value="student_goals" />
              <ExcelColumn
                label="Strong Student Goal and Teacher Profile Match"
                value="goal_match"
              />
              <ExcelColumn
                label="2 or more  group classes between Teacher and Student in last 10 group classes in last 2 months"
                value="group_favourite"
              />
              <ExcelColumn label="Reco + Availability Score 0 to 5" value="reco_availability" />
              <ExcelColumn
                label="Membership Conversion Score ( for trial users )  or Membership Retention Score ( for members )"
                value="raw_reco"
              />
              <ExcelColumn
                label="Availability Score [(Availability/total_hours * 5)]"
                value="availability_score"
              />
              <ExcelColumn
                label="Availability in next 3 days in student timezone"
                value="three_day_availability"
              />
              <ExcelColumn label="Total Hours" value="total_hours" />
            </ExcelSheet>
          </ExcelFile>
        ) : null}

        {this.state.student.uuid && !this.state.student.is_recommendations_dummy ? (
          <button onClick={this.editInWorkspace}>Edit in workspace</button>
        ) : null}

        <hr />

        {this.state.student.is_recommendations_dummy ? (
          <>
            <h2>Edit Goals</h2>
            {this.state.available_goals.map((goal) => {
              goal = this.formattedGoal(goal);
              return (
                <p>
                  {goal}
                  <span style={{ marginLeft: '3px' }}>
                    <input
                      type="checkbox"
                      checked={this.isGoalPresent(goal)}
                      onChange={() => {
                        const newGoals = this.state.studentGoals.slice();
                        if (this.state.studentGoals.includes(goal)) {
                          if (this.state.studentGoals.length === 1) {
                            alert('Atleast 1 goal is needed');
                            return;
                          }
                          newGoals.splice(newGoals.indexOf(goal), 1);
                        } else {
                          newGoals.push(goal);
                        }
                        this.setState({
                          studentGoals: newGoals
                        });
                      }}
                    />
                  </span>
                </p>
              );
            })}
            <button onClick={this.updateGoalsAndRecomputeRecommendations}>
              Update goals and recompute
            </button>
          </>
        ) : null}
        <hr />
        <table className="table-st">
          <thead>
            <tr>
              <th className="th-name-header">
                <span>Student Name</span>
              </th>
              <th>Primary Goal</th>
              <th>Medical History</th>
              <th>Health Tags</th>
              <th>Student Timezone</th>
              <th>Is Student Member</th>
              <th>Is Trial Student</th>
              <th className="th-name-header">
                <span>Level of User</span>
              </th>
              <th>Student Preferred Time</th>
            </tr>
          </thead>
          <tbody>
            {this.state.student.goals ? (
              <tr>
                <td>{this.state.student.name}</td>
                <td>{this.state.student.primary_goal}</td>
                <td>{this.state.student.health_history}</td>
                <td>{this.state.student.student_health_tags.toString()}</td>
                <td>{this.state.student.student_timezone}</td>
                <td>{this.state.student.is_student_subscribed ? 'True' : 'False'}</td>
                <td>{this.state.student.is_trial_student ? 'True' : 'False'}</td>
                <td>{this.state.student_yoga_level}</td>
                <td>{this.render_student_preferred_time()}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <hr />
        <div>
          {/* {this.render_recommended_teachers()} */}

          <table className="table-st">
            <thead>
              <tr>
                <th className="th-name-header">
                  <span>Teacher Name</span>
                </th>
                <th className="th-name-header">
                  <span>Overall Score 5 digits</span>
                </th>
                <th className="th-name-header">
                  <span>
                    Favorite Teacher
                    <span className="text-xs">
                      ( student joined 3 1-1 classes in last 10 1-1 classes with this teacher )
                    </span>
                  </span>
                </th>
                <th className="th-name-header">
                  <span>
                    Teacher Availability Check{' '}
                    <span className="text-xs">( 7 hours in 7 days )</span>
                  </span>
                </th>
                <th className="th-name-header">Allow Teacher trial Available</th>
                <th className="th-name-header">
                  <span>
                    Teacher - Hours{' '}
                    <span className="text-xs">
                      (available in next 7 days / 3 days trial student)
                    </span>
                  </span>
                </th>
                <th className="th-name-header">
                  <span>Is Therapeautic Teacher Match</span>
                </th>
                <th className="th-name-header">
                  <span>Teacher Goals</span>
                </th>
                <th className="th-name-header">Primary Goal</th>
                <th className="th-name-header">
                  Strong Student <span className="text-xs">(Goal and Teacher Profile Match)</span>
                </th>
                <th className="th-name-header">
                  2 or more group classes{' '}
                  <span className="text-xs">
                    [between Teacher and Student in last 10 group classes in last 2 months]
                  </span>
                </th>
                <th className="th-name-header">Reco + Availability Score 0 to 5</th>
                <th className="th-name-header">
                  Membership Conversion Score <span className="text-xs">( for trial users )</span>{' '}
                  or Membership Retention Score <span className="text-xs">( for members )</span>
                </th>

                <th className="th-name-header">
                  Availability Score{' '}
                  <span className="text-xs">[(Availability/total_hours * 5)]</span>
                </th>
                <th className="th-name-header">Availability in next 3 days in student timezone</th>
                <th className="th-name-header">Total Hours</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th className="th-name-header">
                  <span />
                </th>
                <th className="th-name-header">
                  <span />
                </th>
                <th className="th-name-header">
                  <span>Digit-1</span>
                </th>
                <th className="th-name-header">
                  <span>Digit-2</span>
                </th>
                <th className="th-name-header">
                  <span />
                </th>
                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header">
                  <span>Digit-3</span>
                </th>
                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header">Digit-4</th>
                <th className="th-name-header">Digit-5</th>
                <th className="th-name-header" />

                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header" />
              </tr>
            </thead>
            <tbody>
              {this.state.rec_teachers.map((teacher, index) => (
                  <tr key={teacher.id + index}>
                    <td>
                      {teacher.teacher_uuid ? (
                        <a
                          href={`/teacherprofile?id=${teacher.teacher_uuid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {teacher.name}
                        </a>
                      ) : (
                        teacher.name
                      )}
                    </td>
                    <td>{`${teacher.recommendation_score}`}</td>

                    <td>{teacher.private_favourite}</td>
                    <td>{teacher.digit_2}</td>
                    <td>{teacher?.allow_teacher_trial_availability}</td>
                    <td>{teacher.teacher_7_day_availability} hours</td>
                    <td>{teacher.isTherapeautic ? 1 : 0}</td>
                    <td>
                      {teacher.goals.map((goal, index) => {
                        goal = this.formattedGoal(goal);
                        return (
                          <>
                            <span>{goal}</span>
                            <br />
                            <br />
                          </>
                        );
                      })}
                    </td>
                    <td>{this.state.student.primary_goal}</td>
                    <td>{teacher.goal_match}</td>
                    <td>{teacher.group_favourite}</td>
                    <td>{parseFloat(teacher.reco_availability)}</td>
                    <td>{teacher.raw_reco}</td>
                    <td>
                      {teacher.total_hours === 0
                        ? 0
                        : Math.round(
                            (teacher.teacher_3_day_availability / teacher.total_hours) * 5,
                            1
                          )}
                    </td>
                    <td>{teacher.teacher_3_day_availability}</td>
                    <td>{teacher.total_hours}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <hr />
          <h3>Group Session Recommendations</h3>
          <table className="table-st">
            <thead>
              <tr>
                <th className="th-name-header">
                  <span>Group Class - Teacher Name - Time</span>
                </th>
                <th className="th-name-header">
                  <span>In Student Preferred Time</span>
                </th>
                <th className="th-name-header">
                  <span>Class available in next two days</span>
                </th>
                <th className="th-name-header">
                  <span>Student's favorite class</span>
                </th>
                <th className="th-name-header">
                  <span>A regularly scheduled class, atleast 3 times a week</span>
                </th>
                <th className="th-name-header">
                  <span>Class Match based on level, gender, health tags, goals</span>
                </th>
                <th className="th-name-header">
                  <span>Student Goals</span>
                </th>
                <th className="th-name-header">
                  <span>Class Goals</span>
                </th>
                <th className="th-name-header">
                  <span>Class Health Tags</span>
                </th>
                <th className="th-name-header">
                  <span>Class level, Matched goal/health tag</span>
                </th>
                <th className="th-name-header">
                  Other classes at the same time as user booking history
                </th>
                <th className="th-name-header">
                  Membership conversion score or membership retention score of the class
                </th>
                <th className="th-name-header">Featured in recommended</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th className="th-name-header" />
                <th className="th-name-header">Super Digit</th>
                <th className="th-name-header">
                  <span>Digit 1</span>
                </th>
                <th className="th-name-header">
                  <span>Digit 2</span>
                </th>
                <th className="th-name-header">
                  <span>Digit 3</span>
                </th>
                <th className="th-name-header">
                  <span>Digit 4</span>
                </th>
                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header" />
                <th className="th-name-header">
                  <span>Digit 5</span>
                </th>
                <th className="th-name-header">
                  <span>Digit 6</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.group_sessions.map((gs) => {
                let recommendationScore = gs.teacher_recommendation_score;
                const digit1 = Math.floor(recommendationScore / 100000);
                recommendationScore %= 100000;

                const digit2 = Math.floor(recommendationScore / 10000);
                recommendationScore %= 10000;

                const digit3 = Math.floor(recommendationScore / 1000);
                recommendationScore %= 1000;

                const digit4 = Math.floor(recommendationScore / 100);
                recommendationScore %= 100;

                const digit5 = Math.floor(recommendationScore / 10);
                recommendationScore %= 10;
                const digit6 = recommendationScore;

                return (
                  <tr>
                    <td>{`${gs.group_session_name} - ${gs.teacher_name} - ${moment(gs.epoch_time).tz('America/Los_Angeles').format('DD-MM-YY hh:mm zz')}`}</td>
                    <td>{gs.in_pref_time}</td>
                    <td>{digit1}</td>
                    <td>{digit2}</td>
                    <td>{digit3}</td>
                    <td>{digit4}</td>
                    <td>
                      {this.state.student.goals &&
                        this.state.student.goals.map((goal, index) => {
                          const formatted = this.formattedGoal(goal);
                          return (
                            <>
                              <span>{formatted}</span>
                              <br />
                              <br />
                            </>
                          );
                        })}
                    </td>
                    <td>
                      {gs.goals.map((goal, index) => {
                        const formatted = this.formattedGoal(goal);
                        return (
                          <>
                            <span>{formatted}</span>
                            <br />
                            <br />
                          </>
                        );
                      })}
                    </td>
                    <td>
                      {gs.health_keywords.map((tag, index) => (
                          <>
                            <span>{tag}</span>
                            <br />
                            <br />
                          </>
                        ))}
                    </td>
                    <td>
                      {`${gs.target_audience}`} <br />{' '}
                      {` ${gs.reco_key}: ${gs.reco_key === 'goal' ? this.formattedGoal(gs.reco_val) : gs.reco_val}`}
                    </td>
                    <td>{digit5}</td>
                    <td>{digit6}</td>
                    <td>{gs.featured_in_recommended}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <hr />
          <h3>Workshop Recommendations</h3>
          {this.state.workshoprecommendations.student ? (
            <table className="table-st">
              <thead>
                <tr>
                  <th className="th-name-header">
                    <span>Level of User</span>
                  </th>
                  <th className="th-name-header">
                    <span>Gender</span>
                  </th>
                  <th className="th-name-header">
                    <span>Kid</span>
                  </th>
                  <th className="th-name-header">
                    <span>Senior</span>
                  </th>
                  <th className="th-name-header">
                    <span>Sessions done if Newbie</span>
                  </th>
                  <th className="th-name-header">
                    <span>Favourite Teacher (Teacher with whom more than 2 sessions are done)</span>
                  </th>
                  <th className="th-name-header">
                    <span>Delete all Visitation Logs</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.workshoprecommendations.student.yoga_level}</td>
                  <td>{this.state.workshoprecommendations.student.gender}</td>
                  <td>
                    {this.state.workshoprecommendations.student.kids_count > 2 ? 'Yes' : 'No'}
                  </td>
                  <td>
                    {this.state.workshoprecommendations.student.seniors_count > 2 ? 'Yes' : 'No'}
                  </td>
                  <td>
                    {this.state.workshoprecommendations.student.yoga_level === 'NEWBIE'
                      ? this.state.workshoprecommendations.student.sessions_finished
                      : '-'}
                  </td>
                  <td>
                    {this.state.workshoprecommendations.favourite_teacher
                      ? this.state.workshoprecommendations.favourite_teacher.name
                      : 'No favourite yet'}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        const url = get_api_url(
                          `/v2/recommendations/care/workshop/users/${this.state.workshoprecommendations.student.uuid}/log`,
                          false,
                          true
                        );
                        delete_api(url, true).then((res) => {
                          this.getWorkshopRecom();
                        });
                      }}
                    >
                      Clear Visitation Logs
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}

          <table className="table-st">
            <thead>
              <tr>
                <th className="th-name-header">
                  <span>Recommendation Order</span>
                </th>
                <th className="th-name-header">
                  <span>Top 3 Recommended</span>
                </th>
                <th className="th-name-header">
                  <span>Workshop Name</span>
                </th>
                <th className="th-name-header">
                  <span>Type</span>
                </th>
                <th className="th-name-header">
                  <span>Visited Index</span>
                </th>
                <th className="th-name-header">
                  <span>Teacher</span>
                </th>
                <th className="th-name-header">
                  <span>Start Date (PST)</span>
                </th>
                <th className="th-name-header">
                  <span>Hours</span>
                </th>
                <th className="th-name-header">Mark Visited</th>
              </tr>
            </thead>
            <tbody>
              {this.state.workshoprecommendations.recommendations.map((rec, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{rec.recommended ? 'YES' : 'NO'}</td>
                    <td>{rec.workshop_name}</td>
                    <td>{rec.audience_type}</td>
                    <td>{rec.is_visited ? rec.visitIndex + 1 : 'Never Visited'}</td>
                    <td>{rec.teacher_name}</td>
                    <td>{rec.start_date}</td>
                    <td>{rec.hours}</td>
                    <td>
                      <button
                        onClick={() => {
                          const url = get_api_url(
                            `/v2/recommendations/care/workshop/${rec.workshop_uuid}/users/${this.state.workshoprecommendations.student.uuid}/log`,
                            false,
                            true
                          );
                          post_api(url, null, true).then((res) => {
                            this.getWorkshopRecom();
                          });
                        }}
                      >
                        Mark as Visited
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </CommonHeader>
    );
  }

  getRecommendationsData() {
    const csvDataArr = [];
    const header = {
      name: '',
      // rec_score: "",
      recommendation_score: '',
      private_favourite: 'Digit-1',
      teacher_availability_score: 'Digit-2',
      teacher_7_day_availability: '',
      goals: '',
      student_goals: '',
      goal_match: 'Digit-3',
      group_favourite: 'Digit-4',
      reco_availability: 'Digit-5',
      three_day_availability_score: '',
      three_day_availability: '',
      total_hours: ''
    };
    csvDataArr.push(header);
    this.state.rec_teachers.forEach((teacher, index) => {
      const teacher_availability_score =
        (teacher.teacher_3_day_availability / teacher.total_hours) * 9 > 9
          ? 9
          : Math.ceil((teacher.teacher_3_day_availability / teacher.total_hours) * 5, 1);

      const csvData = {
        name: teacher.name,
        // rec_score: `${teacher.private_favourite}${teacher.teacher_availability_score}${teacher.goal_match}${teacher.group_favourite}${parseFloat(teacher.reco_availability).toFixed(2)}`,
        recommendation_score: teacher.recommendation_score,
        private_favourite: teacher.private_favourite,
        teacher_availability_score: teacher.digit_2,
        allow_teacher_trial_availability: teacher.allow_teacher_trial_availability,
        teacher_7_day_availability: teacher.teacher_7_day_availability,
        goals: teacher.goals.toString(),
        student_goals: this.state.student.goals.toString(),
        goal_match: teacher.goal_match,
        group_favourite: teacher.group_favourite,
        reco_availability: parseFloat(teacher.reco_availability).toFixed(2),
        three_day_availability_score: teacher_availability_score,
        raw_reco: teacher.raw_reco,
        three_day_availability: teacher.teacher_3_day_availability,
        total_hours: teacher.total_hours,
        availability_score:
          teacher.total_hours === 0
            ? 0
            : Math.round((teacher.teacher_3_day_availability / teacher.total_hours) * 5, 1)
      };

      csvDataArr.push('csvData', csvData);
    });
    console.log(csvDataArr);
    return csvDataArr;
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone,
    access_type: state.home.access_type,
    cms_teacher_uuid: state.home.cms_teacher_uuid
  });

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsPlayground);
