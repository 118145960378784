import React from 'react';
import './per.css';

const no_permissions = () => (
    <div className="no-permission-cont">
      <h4>Oops!</h4>
      <p>You dont have access to this page.</p>
      <p>
        Please contact <a href="mailto: care@myyogateacher.com">care@myyogateacher.com</a> to get
        access.
      </p>
    </div>
  );

export default React.memo(no_permissions);
