import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from "../../../util_components/common_header";
import {
  day_format,
  time_format,
  distribution_types,
  split_test_categories
} from '../../../../constants';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import { post_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import CustomInput from '../../../util_components/custom_input';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

import '../st.css';

class TestList extends Component {
  state = {
    loading: true,
    loaded: false,
    test_list: [],
    modal_open: false,
    modal_error: false,
    tag: '',
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    test_starting_page: '',
    distribution_metric: 'USERS',
    first_success_metric_name: '',
    second_success_metric_name: '',
    third_success_metric_name: '',
    fourth_success_metric_name: '',
    fifth_success_metric_name: '',
    optimize_test_id: '',
    past_tests: false,
    all_test_list: [],
    split_test_category: split_test_categories[0].value,
    category: 'GENERAL'
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    let past_tests = false;
    try {
      if (url.pathname.indexOf('/past') !== -1) {
        past_tests = true;
      }
    } catch (e) {}
    this.setState({ past_tests }, () => this.load_data());
  }

  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.set_past_future();
      }
    } catch (e) {}
  }

  set_past_future = () => {
    const { all_test_list } = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    let past_tests = false;
    try {
      if (url.pathname.indexOf('/past') !== -1) {
        past_tests = true;
      }
    } catch (e) {}
    let test_list = [];
    if (past_tests) {
      test_list = all_test_list.filter((t) => moment(t.end_date) < moment());
    } else test_list = all_test_list.filter((t) => moment(t.end_date) >= moment());
    this.setState({ test_list, past_tests });
  };

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_split_test_list');
      post_api(url, null, true)
        .then((res) => {
          const all_test_list = res.data.tests;
          let test_list = [];
          if (this.state.past_tests) {
            test_list = all_test_list.filter((t) => moment(t.end_date) < moment());
          } else test_list = all_test_list.filter((t) => moment(t.end_date) >= moment());
          this.setState({ loading: false, loaded: true, test_list, all_test_list });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false, loaded: true });
        });
    });
  };

  open_test_details = (uuid) => {
    const search = `?uuid=${  uuid}`;
    this.props.history.push({
      pathname: '/split_testing/test',
      search
    });
  };

  render_single_test = (test) => (
      <div className="ct-row" key={test.uuid}>
        <div className="ct-col ct-bw">{test.name}</div>
        <div className="ct-col ct-bw">{test.tag}</div>
        <div className="ct-col ct-hf ct-bw">{test.distribution_metric}</div>
        <div className="ct-col ct-hf">{test.first_success_metric_name}</div>
        <div className="ct-col ct-qf">
          {test.is_active === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col ct-sm-font ct-hf">
          <div>{moment(test.start_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(test.start_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-sm-font ct-hf">
          <div>{moment(test.end_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(test.end_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-hf">
          <button onClick={() => this.open_test_details(test.uuid)}>Details</button>
        </div>
      </div>
    );

  toggle_tests = () => {
    const { past_tests } = this.state;
    let pathname = '/split_testing';
    if (!past_tests) pathname = '/split_testing/past';
    this.props.history.push(pathname);
  };

  render_split_tests = (test_list) => (
      <div>
        {test_list.length > 0 ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Name</div>
              <div className="ct-col">Tag</div>
              <div className="ct-col ct-bw ct-hf">Distribution metric</div>
              <div className="ct-col ct-hf">Success metric</div>
              <div className="ct-col ct-qf">Active</div>
              <div className="ct-col ct-hf">Start</div>
              <div className="ct-col ct-hf">End</div>
              <div className="ct-col ct-hf">Details</div>
            </div>
            {test_list.map(this.render_single_test)}
          </div>
        ) : (
          <p> No Tests available </p>
        )}
      </div>
    );

  change_categry = (category) => {
    const { split_test_category } = this.state;
    if (split_test_category !== category) {
      this.setState({ split_test_category: category });
    }
  };

  render_component = () => {
    const { test_list, past_tests, split_test_category } = this.state;
    const cat_test_list = test_list.filter((t) => t.category === split_test_category);
    return (
      <div>
        <button onClick={this.open_modal}>Create Test</button>
        <button onClick={this.toggle_tests}>{past_tests ? 'Current' : 'Past'} Tests</button>
        <div className="stc-container">
          <div className="stc-header">
            {split_test_categories.map((c) => (
                <div
                  className={`stc-label ${c.value === split_test_category ? 'stc-label-sel' : ''}`}
                  onClick={() => this.change_categry(c.value)}
                >
                  {c.label}
                </div>
              ))}
          </div>
          <div>{this.render_split_tests(cat_test_list)}</div>
        </div>
      </div>
    );
  };

  open_modal = () => this.setState({ modal_open: true });

  close_modal = () =>
    this.setState({
      modal_open: false,
      tag: '',
      name: '',
      description: '',
      start_date: '',
      end_date: '',
      category: 'GENERAL',
      test_starting_page: '',
      first_success_metric_name: '',
      second_success_metric_name: '',
      third_success_metric_name: '',
      fourth_success_metric_name: '',
      fifth_success_metric_name: '',
      optimize_test_id: ''
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value, modal_error: false });
  };

  handle_date_change = (name, val) => this.setState({ [name]: val, modal_error: false });

  submit_test = () => {
    const {
      tag,
      name,
      description,
      test_starting_page,
      first_success_metric_name,
      distribution_metric,
      second_success_metric_name,
      third_success_metric_name,
      modal_error,
      optimize_test_id,
      category,
      fourth_success_metric_name,
      fifth_success_metric_name,
      start_date,
      end_date
    } = this.state;
    if (
      !!tag &&
      !!name &&
      !!start_date &&
      !!end_date &&
      !!first_success_metric_name &&
      !!description
    ) {
      const sel_start_date = moment(start_date).format('YYYY-MM-DD');
      const sel_end_date = moment(end_date).format('YYYY-MM-DD');
      const final_start_date = moment
        .tz(`${sel_start_date} 00:00:01`, this.props.iana_timezone)
        .valueOf();
      const final_end_date = moment
        .tz(`${sel_end_date} 23:59:59`, this.props.iana_timezone)
        .valueOf();
      const payload = {
        tag,
        name,
        description,
        test_starting_page,
        first_success_metric_name,
        second_success_metric_name,
        third_success_metric_name,
        category,
        fourth_success_metric_name,
        fifth_success_metric_name,
        start_date: final_start_date,
        end_date: final_end_date,
        distribution_metric,
        optimize_test_id
      };
      const url = get_api_url('create_split_test');
      this.setState({ loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Split test created');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  render_create_test = () => {
    const {
      tag,
      name,
      description,
      test_starting_page,
      first_success_metric_name,
      category,
      second_success_metric_name,
      third_success_metric_name,
      modal_error,
      distribution_metric,
      optimize_test_id,
      fourth_success_metric_name,
      fifth_success_metric_name,
      start_date,
      end_date
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    return (
      <div>
        <h2>{`Create split test (in ${curr_timezone})`}</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CustomDatePicker
            label="*Start Date"
            value={start_date}
            handleChange={(val) => this.handle_date_change('start_date', val)}
          />
          <CustomDatePicker
            label="*End Date"
            value={end_date}
            handleChange={(val) => this.handle_date_change('end_date', val)}
            minDate={start_date}
          />
          <CustomSingleSelect
            label="*Select Distribution"
            options={distribution_types}
            name="distribution_metric"
            onChange={this.onCommonChange}
            value={distribution_metric}
            style={{ width: '250px' }}
          />
          <CustomInput
            label="*Enter tag"
            onChange={this.onCommonChange}
            name="tag"
            value={tag}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Start page"
            onChange={this.onCommonChange}
            name="test_starting_page"
            value={test_starting_page}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Optimize test id"
            onChange={this.onCommonChange}
            name="optimize_test_id"
            value={optimize_test_id}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*First success metric"
            onChange={this.onCommonChange}
            name="first_success_metric_name"
            value={first_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Second success metric"
            onChange={this.onCommonChange}
            name="second_success_metric_name"
            value={second_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Third success metric"
            onChange={this.onCommonChange}
            name="third_success_metric_name"
            value={third_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Fourth success metric"
            onChange={this.onCommonChange}
            name="fourth_success_metric_name"
            value={fourth_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Fifth success metric"
            onChange={this.onCommonChange}
            name="fifth_success_metric_name"
            value={fifth_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomSingleSelect
            label="Select Category"
            options={split_test_categories}
            name="category"
            onChange={this.onCommonChange}
            value={category}
            style={{ width: '250px' }}
          />
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <button onClick={this.submit_test}>Submit</button>
        <p style={err_sty}>* fields are compulsory</p>
      </div>
    );
  };

  render() {
    const { loaded, loading, modal_open, past_tests } = this.state;
    const title = past_tests ? 'Past Split Tests' : 'Current Split Tests';
    return (
      <CommonHeader loading={loading} title={title} meta_title="Split Test List">
        <CustomModal show={modal_open} close={this.close_modal}>
          {this.render_create_test()}
        </CustomModal>
        {loaded ? this.render_component() : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    iana_timezone: state.home.iana_timezone
  });

const mapDispatchToProps = (dispatch) => ({
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(TestList);
