import React, { useEffect, useState } from 'react';
import {
  clientDomainHash,
  client_domain,
  feedbackFormQuestionType,
  feedbackFormType,
  feedbackFormTypeHash,
  feedbackQuestionTypeHash,
  yesOrNo,
  yesOrNoHash
} from '../../../constants';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import { delete_api, get_api, post_api, put_api } from '../../../redux/api_funcs';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomInput from '../../util_components/custom_input';
import NoData from '../../../../public/static/images/no-data.jpg';
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  InfoIcon,
  StarIcon,
  SubmitIcon
} from '../../../utils/svg';

export default function ListIndex() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feedbackQuestionData, setFeedbackQuestions] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isPreviousPage, setIsPreviousPage] = useState(false);
  const [isNextPage, setIsNextPage] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [filterClientDomain, setFilterClientDomain] = useState('');
  const [filterType, setFilterType] = useState('');
  const [page, setPage] = useState(1);
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [options, setOptions] = useState([]);
  const [isMandatory, setIsMandatory] = useState(true);
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [clientDomain, setClientDomain] = useState('');
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showPreviewInfo, setShowPreviewInfo] = useState(false);
  const [infoDatum, setInfoDatum] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    loadData();
  }, [page, filterClientDomain, filterType]);

  const loadData = () => {
    setLoading(true);
    let apiUrl = `/v2/cms_user/feedback_forms?page=${page}`;
    if (searchBy) {
      apiUrl += `&search_by=${searchBy}`;
    }
    if (filterClientDomain) {
      apiUrl += `&client_domain=${filterClientDomain}`;
    }
    if (filterType) {
      apiUrl += `&type=${filterType}`;
    }
    const url = get_api_url(apiUrl, null, true);
    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        let prePageDisable = false;
        let nextPage = false;
        if (page > 1) {
          prePageDisable = true;
        }
        if (page < data?.total_pages) {
          nextPage = true;
        }
        setFeedbackQuestions(data?.items);
        setTotalItems(data?.total_items);
        setIsPreviousPage(prePageDisable);
        setIsNextPage(nextPage);
        setLoading(false);
        setTotalPages(data?.total_pages);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFilterCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    switch (key) {
      case 'searchBy':
        setSearchBy(value);
        break;
      case 'filterClientDomain':
        setFilterClientDomain(value);
        break;
      case 'filterType':
        setFilterType(value);
        break;
      default:
        break;
    }
    setPage(1);
  };

  const handlePagination = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setPage(pageNo);
    }
  };

  const closeModel = () => {
    setFilterClientDomain('');
    setFilterType('');
    setSearchBy('');
    setCategory('');
    setClientDomain('');
    setOptions([]);
    setQuestionType('');
    setType('');
    setIsMandatory(true);
    setQuestion('');
    setShowModal(false);
    setIsEdit(false);
    setPage(1);
  };

  const handleAddOptionField = () => {
    setOptions([...options, { op: '' }]);
  };

  const handleOptionFieldChange = (e, index) => {
    const newOption = [...options];
    newOption[index] = { op: e.target.value };
    setOptions(newOption);
  };
  const handleDeleteOptionField = (index) => {
    const newFields = [...options];
    newFields.splice(index, 1);
    setOptions(newFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const optionsArr = [];
    if (questionType === 'SINGLE_SELECT' || questionType === 'MULTIPLE_SELECT') {
      options?.map((option) => {
        optionsArr.push(option.op);
      });
    }
    const payload = {
      question,
      question_type: questionType,
      options: optionsArr,
      is_mandatory: isMandatory === 'true',
      category,
      type,
      client_domain: clientDomain
    };
    let url = get_api_url('/v2/cms_user/feedback_forms', null, true);
    let apiFunc = post_api;
    setLoading(true);
    if (isEdit) {
      url = get_api_url(`/v2/cms_user/feedback_forms/${infoDatum.uuid}`, null, true);
      apiFunc = put_api;
    }
    apiFunc(url, payload, true)
      .then(() => {
        closeModel();
        loadData();
      })
      .catch((e) => {
        console.log(e);
        let err_message;
        try {
          err_message = e?.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occurred. Please contact care team.';
        }
        setLoading(false);
      });
  };

  const toggleVisibility = () => {
    setQuestion('');
    setCategory('');
    setClientDomain('');
    setOptions([]);
    setQuestionType('');
    setType('');
    setIsMandatory(true);
    setShowModal((prevShowModal) => !prevShowModal);
    setShowMoreInfo(false);
    setIsEdit(false);
  };

  const handleShowMoreInfo = (datum, isPreview) => {
    setInfoDatum(datum);
    if (isPreview) {
      setShowPreviewInfo((prevShowModal) => !prevShowModal);
    } else {
      setShowMoreInfo((prevShowModal) => !prevShowModal);
    }
  };

  const handleDestroy = (uuid) => {
    setLoading(true);
    const url = get_api_url(`/v2/cms_user/feedback_forms/${uuid}`, null, true);
    delete_api(url, true)
      .then(() => {
        loadData();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const setEditDetails = (datum) => {
    setInfoDatum(datum);
    setQuestion(datum?.question);
    setCategory(datum?.category);
    setClientDomain(datum?.client_domain);
    setQuestionType(datum?.question_type);
    setType(datum?.type);
    setIsMandatory(datum?.is_mandatory);
    setShowModal(true);
    setIsEdit(true);
    const optionsHash = [];
    datum?.options?.map((op) => {
      optionsHash.push({ op });
    });
    setOptions(optionsHash);
  };

  const renderOptionField = (option, index) => (
      <div key={index} className="flex items-center space-x-4">
        <div className="col-span-3 sm:col-span-4">
          <label htmlFor="option" className="block mb-2 text-sm font-medium text-gray-900">
            Option {index + 1} *
          </label>
          <input
            type="text"
            name="option"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder="Option"
            value={option.op}
            onChange={(e) => handleOptionFieldChange(e, index)}
            required
          />
        </div>

        <button
          onClick={() => handleDeleteOptionField(index)}
          className="text-red-500 bg-transparent border-0 text-black cursor-pointer"
        >
          Remove
        </button>
      </div>
    );

  const renderCreateEditFeedbackQuestion = () => (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h4 className="px-3 py-1 text-2xl font-semibold">Add Question</h4>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-1xl font-semibold"
                  onClick={toggleVisibility}
                >
                  <CloseIcon />
                </button>
              </div>
              {/* body */}
              <div className="relative p-6 flex-auto">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2 sm:col-span-4">
                    <label
                      htmlFor="Question"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Question*
                    </label>
                    <input
                      type="text"
                      name="question"
                      id="question"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Question"
                      required
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-4">
                    <label
                      htmlFor="category"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Category*
                    </label>
                    <input
                      type="text"
                      name="category"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <CustomSingleSelect
                      label="Client Domain*"
                      options={client_domain}
                      name="client_domain"
                      onChange={(e) => setClientDomain(e.target.value)}
                      value={clientDomain}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <CustomSingleSelect
                      label="Question Type*"
                      options={feedbackFormQuestionType}
                      name="question_type"
                      onChange={(e) => setQuestionType(e.target.value)}
                      value={questionType}
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <CustomSingleSelect
                      label="Type*"
                      name="type"
                      value={type}
                      options={feedbackFormType}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomSingleSelect
                      label="Mandatory Question*"
                      options={yesOrNo}
                      name="isMandatory"
                      onChange={(e) => setIsMandatory(e.target.value)}
                      value={isMandatory}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-4">
                    {options.map((option, index) => renderOptionField(option, index))}
                  </div>
                  {questionType === 'SINGLE_SELECT' || questionType === 'MULTIPLE_SELECT' ? (
                    <div className="col-span-2 flex md:flex-row md:items-center md:space-y-0">
                      <button
                        className="flex items-center justify-center flex-shrink-0 text-sm bg-transparent text-blue-700 hover:bg-blue-700 hover:text-white border-0"
                        onClick={handleAddOptionField}
                      >
                        <AddIcon />
                        Add Option
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-red-500 hover:text-white hover:bg-red-500"
                  type="button"
                  onClick={toggleVisibility}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className={`text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-transparent hover:text-blue-700 ${
                    question &&
                    clientDomain &&
                    questionType &&
                    category &&
                    type &&
                    isMandatory !== null
                      ? ''
                      : 'pointer-events-none opacity-50 cursor-not-allowed'
                  }`}
                  onClick={handleSubmit}
                >
                  <SubmitIcon />
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );

  const renderTextPreview = () => (
      <input
          type="text"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          placeholder="Enter your answer"
          required={infoDatum.is_mandatory}
        />
    );

  const renderPreviewYesOrNo = () => (
      <div className="flex space-x-4">
          <div>
            <input
              type="radio"
              id="option1"
              name="options"
              value="option1"
              checked
              className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
            />
            <label htmlFor="option1" className="ml-2 text-gray-700">
              Yes
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="option2"
              name="options"
              value="option2"
              className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
            />
            <label htmlFor="option2" className="ml-2 text-gray-700">
              No
            </label>
          </div>
        </div>
    );

  const renderPreviewSingleSelect = () => (
      <select
          id="countries"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option>Select Option</option>
          {infoDatum.options.map((op, index) => (
            <option key={index} value={op}>
              {op}
            </option>
          ))}
        </select>
    );

  const renderPreviewMultipleSelect = () => (
      <div className="flex flex-wrap">
          {/* Use flex-wrap to allow items to wrap */}
          {infoDatum.options.map((op) => (
            <div key={op} className="flex items-center space-x-1 mb-1">
              {/* Add margin bottom to create space between rows */}
              <input
                id={`inline-checkbox-${op}`}
                type="checkbox"
                value={op}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor={`inline-checkbox-${op}`}
                className="text-sm font-medium text-gray-900 dark:text-gray-300 pl-5"
              >
                {op}
              </label>
            </div>
          ))}
        </div>
    );

  const renderPreviewRatingScale = () => {
    let rating = 3;
    return (
      <div className="flex space-x-1">
          {[1, 2, 3, 4, 5].map((value) => (
            <div className="flex items-center space-x-2">
              <div className="hidden">
                <input
                  type="radio"
                  id="star5"
                  name="rating"
                  value={value}
                  className="hidden"
                  onClick={() => {
                    rating = value;
                  }}
                />
              </div>
              <label htmlFor="star5" className="cursor-pointer">
                <StarIcon rating={rating} value={value} />
              </label>
            </div>
          ))}
        </div>
    );
  };

  const renderPreview = () => {
    const { question_type = '' } = infoDatum;
    return (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          id="default-modal"
        >
          <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <h5 className="text-xl font-medium text-gray-900 dark:text-white">{infoDatum.category}</h5>

            <label
              htmlFor="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {infoDatum.question}
            </label>
            {question_type === 'TEXT' ? renderTextPreview() : null}
            {question_type === 'YES_OR_NO' ? renderPreviewYesOrNo() : null}
            {question_type === 'SINGLE_SELECT' ? renderPreviewSingleSelect() : null}
            {question_type === 'MULTIPLE_SELECT' ? renderPreviewMultipleSelect() : null}
            {question_type === 'RATING_SCALE' ? renderPreviewRatingScale() : null}

            <button
              className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => handleShowMoreInfo({}, true)}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );
  };

  const renderMoreInfo = () => (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          id="default-modal"
        >
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 rounded-t">
                <h4 className="px-3 py-1 text-2xl font-semibold text-center">More Info</h4>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-1xl font-semibold"
                  onClick={handleShowMoreInfo}
                >
                  <CloseIcon />
                </button>
              </div>
              {/* body */}
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Id
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {infoDatum.id}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      UUID
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {infoDatum.uuid}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Question
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {infoDatum.question}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Question type
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {feedbackQuestionTypeHash[infoDatum.question_type]}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Category
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {infoDatum.category}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Is mandatory
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {yesOrNoHash[infoDatum.is_mandatory]}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Type
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {feedbackFormTypeHash[infoDatum.type]}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Options
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {infoDatum.options.join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td scope="col" className="px-4 py-2 font-medium">
                      Client Domain
                    </td>
                    <td scope="col" className="px-4 py-2 font-medium">
                      {clientDomainHash[infoDatum.client_domain]}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );

  const renderSingleFeedbackQuestion = (datum) => {
    const { question, category, question_type, client_domain, uuid, is_mandatory } = datum;
    return (
      <tr className="border-b">
          <td scope="col" className="px-4 py-2 font-medium">
            {question}
          </td>
          <td scope="col" className="px-4 py-2 font-medium">
            {feedbackQuestionTypeHash[question_type]}
          </td>
          <td scope="col" className="px-4 py-2 font-medium">
            {category}
          </td>
          <td scope="col" className="px-4 py-2 font-medium">
            {yesOrNoHash[is_mandatory]}
          </td>
          <td scope="col" className="px-4 py-2 font-medium">
            {clientDomainHash[client_domain]}
          </td>
          <td scope="col" className="px-4 py-2 font-medium flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Green Icon Tooltip"
                onClick={() => handleShowMoreInfo(datum)}
                className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
              >
                <InfoIcon />
              </button>

              <button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Preview"
                onClick={() => handleShowMoreInfo(datum, true)}
                className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
              >
                <EyeIcon />
              </button>

              <button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit"
                onClick={() => setEditDetails(datum)}
                className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
              >
                <EditIcon />
              </button>
              <button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Delete"
                onClick={() => handleDestroy(uuid)}
                className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
              >
                <DeleteIcon />
              </button>
            </div>
          </td>
        </tr>
    );
  };
  const renderFeedbackQuestionTable = () => (
      <div>
        {feedbackQuestionData.length > 0 ? (
          <table className="w-full text-sm text-left">
            <thead className="text-xs uppercase">
              <tr>
                <th scope="col" className="px-4 py-3">
                  Question
                </th>
                <th scope="col" className="px-4 py-3">
                  Type
                </th>
                <th scope="col" className="px-4 py-3">
                  Category
                </th>
                <th scope="col" className="px-4 py-3">
                  Mandatory Field
                </th>
                <th scope="col" className="px-4 py-3">
                  Client Domain
                </th>
                <th scope="col" className="px-4 py-3" />
              </tr>
            </thead>
            <tbody>{feedbackQuestionData.map(renderSingleFeedbackQuestion)}</tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center">
            <img src={NoData} className="w-1/2 h-1/2" />
          </div>
        )}
      </div>
    );

  const renderPagination = () => (
      <div className="flex items-center justify-center mt-4">
        <button
          className={`px-3 py-2 mr-2 font-medium leading-5 rounded-md ${
            isPreviousPage
              ? 'bg-transparent text-black hover:text-white hover:bg-blue-700 cursor-pointer'
              : 'bg-transparent text-black cursor-not-allowed'
          }`}
          onClick={() => handlePagination(page - 1)}
          disabled={!isPreviousPage}
        >
          Previous
        </button>
        <span className="px-3 py-2 font-medium leading-5 text-black-700">
          Page {page} of {totalPages}
        </span>
        <button
          className={`px-3 py-2 ml-2 font-medium leading-5 rounded-md ${
            isNextPage
              ? 'bg-transparent text-black hover:text-white hover:bg-blue-700 cursor-pointer'
              : 'bg-transparent text-black cursor-not-allowed'
          }`}
          onClick={() => handlePagination(page + 1)}
          disabled={!isNextPage}
        >
          Next
        </button>
      </div>
    );

  const renderTableHeader = () => (
      <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
        <div className="flex items-center flex-1 space-x-4">
          <h5>
            <span className="text-black-500">All Feedback Forms Questions</span>
          </h5>
        </div>
        <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
          <button
            className="block text-white bg-blue-700 hover:bg-transparent hover:text-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            type="button"
            onClick={toggleVisibility}
          >
            Add New
          </button>
        </div>
      </div>
    );

  const renderFilter = () => (
      <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
        <div className="flex items-center flex-1 space-x-4">
          <h5>
            <span className="text-gray-500">Filters</span>
          </h5>
        </div>
        <form onSubmit={loadData}>
          <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
            <CustomInput
              label="Search Question"
              value={searchBy}
              name="searchBy"
              onChange={onFilterCommonChange}
            />
            <CustomSingleSelect
              label="Form Type"
              options={feedbackFormType}
              name="filterType"
              onChange={onFilterCommonChange}
              value={filterType}
            />
            <CustomSingleSelect
              label="Client Domain"
              options={client_domain}
              name="filterClientDomain"
              onChange={onFilterCommonChange}
              value={filterClientDomain}
            />
            <button className="hidden" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    );

  const renderListOfFeedbackQuestions = () => (
      <div>
        <div>
          <section>
            <div>
              <div className="relative overflow-hidden sm:rounded-lg">
                {renderTableHeader()}
                {renderFilter()}
                <div className="overflow-x-auto">{renderFeedbackQuestionTable()}</div>
                {totalItems > 0 ? renderPagination() : null}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  const renderFeedbackQuestions = () => {
    const title = 'Feedback Questions';
    return (
      <CommonHeader loading={loading} title={title} meta_title="Feedback Questions">
        {showModal ? renderCreateEditFeedbackQuestion() : null}
        {showMoreInfo ? renderMoreInfo() : null}
        {showPreviewInfo ? renderPreview() : null}
        {!loading ? renderListOfFeedbackQuestions() : null}
      </CommonHeader>
    );
  };
  return renderFeedbackQuestions();
}
