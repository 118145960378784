import React, { Component } from 'react';
import './content.css';

class CategoryTemplate extends Component {
  render() {
    const details = this.props.category_details;
    return (
      <>
        <button onClick={this.props.on_add_new_category}>Add new Category</button>
        <div className="content-width-full content-border category-template-div">
          {details.length !== 0
            ? details.map((data, key) => (
                  <div key={key} className="content-div">
                    <div
                      className="inline-flex items-center"
                      onClick={() => this.props.on_edit_category(data?.uuid, data?.name)}
                    >
                      <h3 className="text-lg font-bold cursor-pointer">{data.name}</h3>
                      <img
                        src="https://images.myyogateacher.com/pen.png"
                        alt="icon"
                        className="h-4 w-4 ml-2 cursor-pointer"
                      />
                    </div>
                    <button
                      className="category-button"
                      onClick={() => {
                        this.props.on_add_new_tag(data?.uuid);
                      }}
                    >
                      Add new Tag
                    </button>
                    <div className="inner-div content-border content-width-full">
                      {data?.tags && data.tags.length !== 0 ? (
                        data.tags.map((tag) => (
                            <div className="md-chips">
                              <label
                                htmlFor={tag.uuid}
                                id={tag.uuid}
                                className="md-chip md-chip-clickable md-chip-hover inline-flex items-center"
                              >
                                <span
                                  onClick={() =>
                                    this.props.on_edit_tag(tag.uuid, tag.name, tag.slug)
                                  }
                                >
                                  {' '}
                                  {tag.name}{' '}
                                </span>
                                <img
                                  src="https://images.myyogateacher.com/cross_filt_pop.svg"
                                  alt="close"
                                  style={{ marginLeft: 10, height: 12, width: 12 }}
                                  onClick={() => this.props.on_delete_tag(tag.uuid)}
                                />
                              </label>
                            </div>
                          ))
                      ) : (
                        <p>No tags present</p>
                      )}
                    </div>
                  </div>
                ))
            : null}
        </div>
      </>
    );
  }
}

export default CategoryTemplate;
