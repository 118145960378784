import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomLoading from "../custom_loading";

function CommonHeader(props) {
  return (
    <div className={`${props.class_name} common-page-container`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.meta_title || props.title}</title>
      </Helmet>
      {props.loading ? <CustomLoading full_loader={props.full_loader} /> : null}
      <div className="common-page-header">
        {props.show_back ? (
          <div className="profile-go-back" onClick={() => props.history.goBack()}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
        ) : null}
        <div className="common-page-title">
          {props.title}
          {props.subtitle ? <div className="common-page-subheading">{props.subtitle}</div> : null}
        </div>
      </div>
      {props.children}
    </div>
  );
}

CommonHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  show_back: PropTypes.bool,
  loading: PropTypes.bool,
  full_loader: PropTypes.bool,
  class_name: PropTypes.string
};

CommonHeader.defaultProps = {
  title: 'MYT',
  subtitle: '',
  show_back: false,
  loading: false,
  full_loader: false,
  class_name: ''
};

export default withRouter(CommonHeader);
