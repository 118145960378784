import * as t from '../action_types';

// different status
// 1. none
// 2. inprogress
// 3. success
// 4. fail

const initialState = {
  show_notif: false,
  notif_type: '',
  notif_text: '',
  notif_dur: 1000
};

const set_notification_variable = (state, payload) => ({
    ...state,
    show_notif: payload.show_notif,
    notif_type: payload.notif_type,
    notif_text: payload.notif_text,
    notif_dur: payload.notif_dur
  });

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_notification_variable:
      return set_notification_variable(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
