import React, { Component } from 'react';
import CommonHeader from "../../util_components/common_header";
import CustomModal from "../../util_components/custom_modal";
import Button from "../../util_components/button";
import { get_api_url } from '../../../utils/urls';
import { post_api } from '../../../redux/api_funcs';

export default class StudentTests extends Component {
  state = {
    slug: '',
    student_name: '',
    student_uuid: '',
    loading: false,
    choices: [],
    change_test_uuid: '',
    change_test_choice_uuid: '',
    test_choices: [],
    open_change_choice: false,
    confirm_choice_uuid: '',
    confirm_change: false
  };

  componentDidMount() {
    const { match } = this.props;
    const {slug} = match.params;
    if (slug) {
      this.setState({ slug }, () => this.load_data());
    } else this.props.history.push('/users');
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { slug } = this.state;
      const url = get_api_url('get_student_tests');
      const payload = { slug };
      post_api(url, payload, true)
        .then((response) => {
          this.setState({
            loading: false,
            student_name: response.data.student_name,
            student_uuid: response.data.student_uuid,
            choices: response.data.choices
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  change_test = (uuid, choice_uuid) =>
    this.setState(
      { open_change_choice: true, change_test_uuid: uuid, change_test_choice_uuid: choice_uuid },
      () => {
        const url = get_api_url('get_split_test_details');
        const payload = { split_test_uuid: uuid };
        post_api(url, payload, true)
          .then((response) => {
            this.setState({ loading: false, test_choices: response.data.choices });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false });
          });
      }
    );

  close_test_choice = () =>
    this.setState({
      open_change_choice: false,
      change_test_uuid: '',
      change_test_choice_uuid: '',
      test_choices: [],
      confirm_change: false,
      confirm_choice_uuid: ''
    });

  open_test_details = (uuid) => {};

  render_single_choice = (choice) => {
    const test_url = `/split_testing/test?uuid=${choice.split_test_uuid}`;
    return (
      <div className="ct-row" key={choice.uuid}>
        <div className="ct-col">
          <a href={test_url} target="_blank" rel="noreferrer noopener">
            {choice.test_name}
          </a>
        </div>
        <div className="ct-col ct-qf ct-sm-font">
          {choice.test_active === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">{choice.choice_name}</div>
        <div className="ct-col ct-qf">
          {choice.test_active === 1 ? (
            <Button
              onClick={() =>
                this.change_test(choice.split_test_uuid, choice.split_test_choice_uuid)
              }
            >
              Change
            </Button>
          ) : (
            '--'
          )}
        </div>
      </div>
    );
  };

  render_choices = () => {
    const { choices } = this.state;
    if (choices.length > 0) {
      return (
        <div style={{ marginTop: '20px' }}>
          Workshops:
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Test</div>
              <div className="ct-col ct-qf ct-sm-font">Test Active</div>
              <div className="ct-col">Choice</div>
              <div className="ct-col ct-qf">Change Choice</div>
            </div>
            {choices.map(this.render_single_choice)}
          </div>
        </div>
      );
    }
    return null;
  };

  submit_change_choice = (choice_uuid) =>
    this.setState({ loading: true }, () => {
      const { student_uuid, change_test_uuid } = this.state;
      const payload = { student_uuid, test_uuid: change_test_uuid, choice_uuid };
      const url = get_api_url('update_split_test_choice_student');
      post_api(url, payload, true)
        .then(() => {
          this.load_data();
          this.close_test_choice();
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  render_chage_test = () => {
    const { test_choices, change_test_choice_uuid, confirm_change, confirm_choice_uuid } =
      this.state;
    if (confirm_change) {
      const selected_choice = test_choices.find((t) => t.uuid === confirm_choice_uuid);
      return (
        <div>
          Are you sure you want to select this choice for student
          <div className="create-group-class-name-edit">Name:</div>
          <div>{selected_choice.name}</div>
          <div className="create-group-class-name-edit">Weight:</div>
          <div>{selected_choice.weight}</div>
          <div className="create-group-class-name-edit">Description:</div>
          <div style={{ marginBottom: '20px' }}>{selected_choice.description}</div>
          <Button onClick={() => this.submit_change_choice(selected_choice.uuid)}>Submit</Button>
        </div>
      );
    }
    return (
      <div>
        Change Split test choice for student
        <div className="cus-table" style={{ margin: '20px 0' }}>
          <div className="ct-row ct-h">
            <div className="ct-col">Name</div>
            <div className="ct-col ct-hf ct-sm-font">Weight</div>
            <div className="ct-col ct-hf ct-sm-font">Select</div>
          </div>
          {test_choices.map((choice) => (
              <div className="ct-row" key={choice.uuid}>
                <div className="ct-col">{choice.name}</div>
                <div className="ct-col ct-hf ct-sm-font">{choice.weight}</div>
                <div className="ct-col ct-hf ct-sm-font">
                  {choice.uuid !== change_test_choice_uuid ? (
                    <Button
                      onClick={() =>
                        this.setState({
                          confirm_change: true,
                          confirm_choice_uuid: choice.uuid
                        })
                      }
                    >
                      Select
                    </Button>
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { loading, student_name, open_change_choice } = this.state;
    const title = student_name ? `Student Tests for ${  student_name}` : 'Student Tests';
    return (
      <CommonHeader show_back loading={loading} title={title}>
        <CustomModal show={open_change_choice} close={this.close_test_choice}>
          {this.render_chage_test()}
        </CustomModal>
        {this.render_choices()}
      </CommonHeader>
    );
  }
}
