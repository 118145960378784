import React, { useEffect, useReducer, useState } from 'react';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import { get_api, post_api } from '../../../redux/api_funcs';
import { StarIcon } from '../../../utils/svg';

export default function CreateSessionFeedback() {
  const [loading, setLoading] = useState(true);
  const [questionData, setQuestionData] = useState([]);
  const [feedbackHash, setFeedbackHash] = useState({});
  const [mandatoryFieldError, setMandatoryFieldError] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const setFeedbackHashData = (data) => {
    const tempFeedbackHash = {};
    data.forEach((fb) => {
      fb?.questions.forEach((question) => {
        tempFeedbackHash[question.id] = {
          question_id: question.id,
          question_uuid: question.uuid,
          question: question.question,
          question_type: question.question_type,
          options: question.options,
          answer: '',
          category: question.category,
          is_mandatory: question.is_mandatory
        };
      });
    });
    setFeedbackHash(tempFeedbackHash);
  };

  const loadData = () => {
    setLoading(true);
    const apiUrl = `/v2/cms_user/feedback_forms/QA_SCORE_CARD?client_domain=lifestyle`;
    const url = get_api_url(apiUrl, null, true);
    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        if (data) {
          setQuestionData(data);
          setFeedbackHashData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const setMandatoryFieldFalse = (id, answer = '') => {
    if (mandatoryFieldError[id] && answer !== '') {
      setMandatoryFieldError((prev) => ({ ...prev, [id]: false }));
    }
  };

  // submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    const keys = Object.keys(feedbackHash);
    let isReturn = false;
    keys.forEach((key) => {
      if (feedbackHash[key]?.is_mandatory && !feedbackHash[key]?.answer) {
        isReturn = true;
        setMandatoryFieldError((prev) => ({ ...prev, [key]: true }));
      }
    });
    if (isReturn) {
      return;
    }
    const values = Object.values(feedbackHash);
    setLoading(true);
    const apiUrl = `/v2/cms_user/sessions/feedbacks`;
    const url = get_api_url(apiUrl, null, true);
    const currentUrl = new URL(window.location.href);
    const sessionUUID = currentUrl.searchParams.get('session_uuid');
    const payload = {
      session_uuid: sessionUUID,
      feedback: values
    };
    post_api(url, payload, true)
      .then(() => {
        setLoading(false);
        window.location.replace(`/session_score_card?session_uuid=${sessionUUID}`);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const setFeedbackHashValue = (question, answer) => {
    setFeedbackHash((prev) => ({
      ...prev,
      [question.id]: {
        question_id: question.id,
        question_uuid: question.uuid,
        question: question.question,
        question_type: question.question_type,
        options: question.options,
        answer,
        category: question.category,
        is_mandatory: question.is_mandatory
      }
    }));
  };

  // handle the multiselect
  const handleMultiSelect = (e, question) => {
    let answer = [];
    if (feedbackHash[question.id]?.answer) {
      answer = feedbackHash[question.id]?.answer?.split(', ');
    }
    answer.push(e.target.value);
    setMandatoryFieldFalse(question.id, answer.join(', '));
    setFeedbackHashValue(question, answer.join(', '));
  };
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // handle the rating scale input change
  const handleRatingChange = (e, question, value) => {
    e.preventDefault();
    setMandatoryFieldFalse(question.id, value);
    setFeedbackHashValue(question, value);
  };

  // handle other common changes
  const handleChange = (e, question) => {
    setMandatoryFieldFalse(question.id, e.target.value);
    setFeedbackHashValue(question, e.target.value);
  };

  const renderErrorMessage = () => (
      <div className="text-red-500">
          <span>Please fill the field</span>
        </div>
    );

  // render the text input
  const renderTextPreview = (question) => (
      <>
        <input
          type="text"
          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600  dark:placeholder-gray-400 dark:text-white ${
            mandatoryFieldError[question.id]
              ? 'border border-red-500'
              : 'border-gray-300 dark:border-gray-500'
          }`}
          placeholder="Enter your answer"
          required
          onChange={(e) => handleChange(e, question)}
          value={feedbackHash[question.id]?.answer}
        />
        {mandatoryFieldError[question.id] ? renderErrorMessage() : null}
      </>
    );

  // render the Yes or No input
  const renderPreviewYesOrNo = (question) => (
      <>
        <div className="flex space-x-4">
          <div>
            <input
              type="radio"
              id="option1"
              value="Yes"
              className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
              onChange={(e) => handleChange(e, question)}
            />
            <label htmlFor="option1" className="ml-2 text-gray-700">
              Yes
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="option2"
              value="No"
              className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
              onChange={(e) => handleChange(e, question)}
            />
            <label htmlFor="option2" className="ml-2 text-gray-700">
              No
            </label>
          </div>
        </div>
        {mandatoryFieldError[question.id] ? renderErrorMessage() : null}
      </>
    );

  // render the selection input
  const renderPreviewSingleSelect = (question) => (
      <>
        <select
          id="large"
          className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(e) => handleChange(e, question)}
          value={feedbackHash[question.id]?.answer}
        >
          <option>Select Option</option>
          {question?.options.map((op, index) => (
            <option key={index} value={op}>
              {op}
            </option>
          ))}
        </select>
        {mandatoryFieldError[question.id] ? renderErrorMessage() : null}
      </>
    );

  // render the checkbox for multi select input
  const renderPreviewMultipleSelect = (question) => (
      <>
        <div className="flex space-x-1">
          {question.options.map((op) => (
            <div className="space-x-1">
              <div className="flex items-center me-4 ">
                <input
                  id="inline-checkbox"
                  type="checkbox"
                  value={op}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onClick={(e) => handleMultiSelect(e, question)}
                />
                <label
                  htmlFor="inline-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {op}
                </label>
              </div>
            </div>
          ))}
        </div>
        {mandatoryFieldError[question.id] ? renderErrorMessage() : null}
      </>
    );

  // render the rating scaling input
  const renderPreviewRatingScale = (question) => (
      <>
        <div className="flex space-x-1">
          {[...Array(5)].map((_, index) => {
            const starId = `star${index + 1}`;

            return (
              <div className="flex items-center space-x-2" key={index}>
                <div className="hidden">
                  <input
                    type="radio"
                    id={starId}
                    name="rating"
                    value={index + 1}
                    className="hidden"
                    // onChange={(e) => handleRatingChange(question, index + 1)}
                  />
                </div>
                <label htmlFor={starId} className="cursor-pointer">
                  <div onClick={(e) => handleRatingChange(e, question, index + 1)}>
                    <StarIcon rating={feedbackHash[question.id]?.answer} value={index} />
                  </div>
                </label>
              </div>
            );
          })}
        </div>
        {mandatoryFieldError[question.id] ? renderErrorMessage() : null}
      </>
    );

  // render the question
  const renderQuestion = (question) => {
    const { question_type = '' } = question;
    return (
      <>
        <p className="text-base text-gray-500 sm:text-lg dark:text-gray-400">
          {question.question} {question.is_mandatory ? <span> *</span> : null}
        </p>
        <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse" />
        {question_type === 'TEXT' ? renderTextPreview(question) : null}
        {question_type === 'YES_OR_NO' ? renderPreviewYesOrNo(question) : null}
        {question_type === 'SINGLE_SELECT' ? renderPreviewSingleSelect(question) : null}
        {question_type === 'MULTIPLE_SELECT' ? renderPreviewMultipleSelect(question) : null}
        {question_type === 'RATING_SCALE' ? renderPreviewRatingScale(question) : null}
        <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4" />
      </>
    );
  };

  // render the question's category
  const renderCategory = (datum) => (
      <div className="my-10 w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 className="text-center mb-2 text-3xl font-bold text-gray-900 dark:text-white">
            {datum.category}
          </h5>
          {datum?.questions.map(renderQuestion)}
        </div>
    );

  // render the form
  const renderForm = () => (
      <>
        {questionData.map(renderCategory)}
        <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
          <button
            className="block text-white bg-blue-700 hover:bg-transparent hover:text-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </>
    );

  const renderFeedbackQuestions = () => {
    const title = 'QA Score Card';
    const isLoading = loading || false;
    return (
      <CommonHeader loading={isLoading} title={title} meta_title="Feedback Questions">
        {!isLoading ? renderForm() : null}
      </CommonHeader>
    );
  };
  return renderFeedbackQuestions();
}
