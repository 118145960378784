import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { getCookie } from '../../../util_functions';

import './drawer.css';

class Drawer extends PureComponent {
  state = {
    reports_open: false,
    teacher_open: false,
    sessions_open: false,
    backpain_sessions_open: false,
    classes_open: false,
    student_open: false,
    other_open: false,
    consults_open: false,
    content_open: false,
    chatoverview_open: false,
    backpain_classes_open: false,
    email_open: false,
    dropdowns: {
      sessions: [
        '/sessions',
        '/pastsessions',
        '/pastreplacements',
        '/replacementsessions',
        '/cancelledsessions',
        '/group_class_types',
        '/friends_sessions',
        '/friends_sessions/past'
      ],
      backpain_sessions: ['/sessions/backpain', '/pastsessions/backpain'],
      reports: [
        '/studentsandsessions',
        '/availability',
        '/availability/consult',
        '/banned_students',
        '/membership_cancellations',
        '/allstudenttransactions',
        '/referrals',
        '/teachersessionstats',
        '/studentsessionstats',
        '/caremessages',
        '/cohorttimebased',
        '/keymetrics',
        '/weekly_report',
        '/groupsessionsreport',
        '/emaildeliveryreport',
        '/sms_report',
        '/ten_days_inactive_users',
        '/failedrecurringtransactions',
        '/flagged_sms'
      ],
      classes: [
        '/groupsessions/repeat',
        '/group_class_pages',
        'group_class_types',
        '/groupsessions',
        '/pastgroupsessions',
        '/community_class'
      ],
      backpain_classes: ['/backpain/groupsessions', '/backpain/pastgroupsessions'],
      consults: ['/consults/past', '/consults/future'],
      content: ['/content/tags', '/content/blogs'],
      other: [
        '/upload_images',
        '/upload_images/workshop',
        '/upload_images/blog',
        '/upload_teacher_payments',
        '/press_pages'
      ],
      chatoverview: ['/profanity_list', '/blockedmessages'],
      email: ['/createemail', '/email-templates-list']
    }
  };

  on_link_click = () => {
    if (window.innerWidth <= 700) {
      this.props.toggle_drawer();
    }
  };

  toggle_comp = (e) => {
    const { comp } = e.currentTarget.dataset;
    this.setState({ [`${comp}_open`]: !this.state[`${comp}_open`] });
  };

  does_user_has_access = (url) => this.props.access_pages.indexOf(url) !== -1;

  show_dropdown = (flag) => {
    const pages = this.state.dropdowns[flag];
    let result = false;
    pages.forEach((page) => {
      if (this.props.access_pages.indexOf(page) !== -1) {
        result = true;
      }
    });
    return result;
  };

  render_link = (url, name, is_inner = false) => {
    const inner_class = is_inner ? 'drawer-sub-inner' : '';
    if (this.props.access_pages.indexOf(url) !== -1) {
      return (
        <Link to={url} style={{ textDecoration: 'none' }} onClick={this.on_link_click}>
          <div className={`drawer-component ${inner_class}`}>{name}</div>
        </Link>
      );
    }
    return null;
  };

  render_reports_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/newusers', 'New Students', true)}
      {this.render_link('/concierge/lifestyle', 'Concierge MyYogaTeacher', true)}
      {this.render_link('/concierge/backpain', 'Concierge LifetreeHealth', true)}
      {this.render_link('/studentsandsessions', 'Students and Sessions', true)}
      {this.render_link('/blockedmessages', 'Blocked Messages', true)}
      {this.render_link('/membership_cancellations', 'Membership Cancellations', true)}
      {this.render_link('/availability', 'Teachers Availability', true)}
      {this.render_link('/availability/consult', 'Consult Teachers Availability', true)}
      {this.render_link('/banned_students', 'Banned Students', true)}
      {this.render_link('/allstudenttransactions', 'Transactions', true)}
      {this.render_link('/failedrecurringtransactions', 'Failed Recurring Transactions', true)}
      {this.render_link('/referrals', 'Referrals', true)}
      {this.render_link('/teachersessionstats', 'Teacher Session Stats', true)}
      {this.render_link('/studentsessionstats', 'Student Session Stats', true)}
      {this.render_link('/caremessages', 'Care Messages', true)}
      {this.render_link('/cohorttimebased', 'Time Based Cohort', true)}
      {this.render_link('/keymetrics', 'Key Metrics', true)}
      {this.render_link('/weekly_report', 'Weekly Report', true)}
      {this.render_link('/groupsessionsreport', 'Group Sessions Report', true)}
      {this.render_link('/emaildeliveryreport', 'Email Delivery Report', true)}
      {this.render_link('/replacement', 'Replacement Report', true)}
      {this.render_link('/sms_report', 'Sms Report', true)}
      {this.render_link('/ten_days_inactive_users', 'Ten Days Inactive Members', true)}
      {this.render_link('/flagged_sms', 'Flagged SMS Report', true)}
    </div>
  );

  render_classes_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/groupsessions/repeat', 'Repeat Classes', true)}
      {this.render_link('/groupsessions', 'Future Classes', true)}
      {this.render_link('/pastgroupsessions', 'Past Classes', true)}
      {this.render_link('/group_class_types', 'Class Types', true)}
      {this.render_link('/group_class_pages', 'Class Pages', true)}
      {this.render_link('/community_class', 'Community class', true)}
    </div>
  );

  render_back_pain_class_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/backpain/groupsessions', 'Future Group Classes', true)}
      {this.render_link('/backpain/pastgroupsessions', 'Past Group Classes', true)}
      {this.render_link('/sessions/backpain', 'Future Backpain Sessions', true)}
      {this.render_link('/pastsessions/backpain', 'Past Backpain Sessions', true)}
      {this.render_link('/backpain/group_class_pages', 'Class Page', true)}
    </div>
  );

  render_consults_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/consults/past', 'Past Consults', true)}
      {this.render_link('/consults/future', 'Future Consults', true)}
    </div>
  );

  render_content_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/content/tags', 'Tags', true)}
      {this.render_link('/content/blogs', 'Blogs', true)}
    </div>
  );

  render_backpain_session_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/sessions/backpain', 'Future Backpain Sessions', true)}
      {this.render_link('/pastsessions/backpain', 'Past Backpain Sessions', true)}
    </div>
  );

  render_session_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/sessions', 'Future Sessions', true)}
      {this.render_link('/pastsessions', 'Past Sessions', true)}
      {this.render_link('/replacementsessions', 'Pending Replacements', true)}
      {this.render_link('/pastreplacements', 'Past Replacements', true)}
      {this.render_link('/cancelledsessions', 'Cancelled Sessions', true)}
      {this.render_link('/friends_sessions', 'Friends Sessions', true)}
      {this.render_link('/friends_sessions/past', 'Past Friends Sessions', true)}
      {this.render_link('/gpt_session_notes', 'GPT Session', true)}
    </div>
  );

  render_chatoverview_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/profanity_list', 'List', true)}
      {this.render_link('/blockedmessages', 'Messages', true)}
    </div>
  );

  render_other_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/createemail', 'Create Email', true)}
      {this.render_link('/download', 'Download Data', true)}
      {this.render_link('/upload_images/workshop', 'Upload Workshop Image', true)}
      {this.render_link('/upload_images/blog', 'Upload Blog Image', true)}
      {this.render_link('/profanity_list', 'Profanity List', true)}
      {this.render_link('/upload_teacher_payments', 'Upload Teacher Payments', true)}
      {this.render_link('/press_pages', 'Press Pages', true)}
    </div>
  );

  render_email_open = () => (
    <div className="drawer-sub-component">
      {this.render_link('/createemail', 'Create Email', true)}
      {this.render_link('/email-templates-list', 'Templates', true)}
    </div>
  );

  open_student_chat = () => {
    window.open('/student_chat', '_blank');
  };

  open_teacher_chat = () => {
    window.open('/teacher_chat', '_blank');
  };

  render() {
    return (
      <div className="main-drawer">
        <div className="main-drawer-inner">
          <div className="main-drawer-profile">
            <p>{getCookie('cms_email')}</p>
            {/* <div className="drawer-logout" onClick={this.props.log_out}>
							Log out
						</div> */}
          </div>
          {this.render_link('/student_profiles', 'Student Profiles')}
          {this.show_dropdown('reports') ? (
            <div className="drawer-component" data-comp="reports" onClick={this.toggle_comp}>
              Reports
              <img
                className={this.state.reports_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.reports_open ? this.render_reports_open() : null}
          {this.render_link('/users', 'Students')}
          {this.render_link('/teacherslist', 'Teachers List')}
          {this.show_dropdown('sessions') ? (
            <div className="drawer-component" data-comp="sessions" onClick={this.toggle_comp}>
              Sessions
              <img
                className={this.state.sessions_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.sessions_open ? this.render_session_open() : null}
          {/* {this.show_dropdown('backpain_sessions') ? (
						<div className="drawer-component" data-comp="backpain_sessions" onClick={this.toggle_comp}>
							Backpain Sessions
							<img
								className={this.state.backpain_sessions_open ? 'reverse-expand' : ''}
								src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
								alt="exp"
							/>
						</div>
					) : null}
					{this.state.backpain_sessions_open ? this.render_backpain_session_open() : null} */}
          {this.show_dropdown('classes') ? (
            <div className="drawer-component" data-comp="classes" onClick={this.toggle_comp}>
              Classes
              <img
                className={this.state.classes_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.classes_open ? this.render_classes_open() : null}
          {this.show_dropdown('backpain_classes') ? (
            <div
              className="drawer-component"
              data-comp="backpain_classes"
              onClick={this.toggle_comp}
            >
              Backpain Classes
              <img
                className={this.state.backpain_classes_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.backpain_classes_open ? this.render_back_pain_class_open() : null}
          {this.show_dropdown('consults') ? (
            <div className="drawer-component" data-comp="consults" onClick={this.toggle_comp}>
              Consults
              <img
                className={this.state.consults_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.consults_open ? this.render_consults_open() : null}
          {this.show_dropdown('content') ? (
            <div className="drawer-component" data-comp="content" onClick={this.toggle_comp}>
              Content
              <img
                className={this.state.content_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.content_open ? this.render_content_open() : null}
          {this.show_dropdown('other') ? (
            <div className="drawer-component" data-comp="other" onClick={this.toggle_comp}>
              Other
              <img
                className={this.state.other_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.other_open ? this.render_other_open() : null}
          {this.show_dropdown('email') ? (
            <div className="drawer-component" data-comp="email" onClick={this.toggle_comp}>
              Emails
              <img
                className={this.state.email_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.email_open ? this.render_email_open() : null}
          {this.show_dropdown('chatoverview') ? (
            <div className="drawer-component" data-comp="chatoverview" onClick={this.toggle_comp}>
              Profanity Filters
              <img
                className={this.state.chatoverview_open ? 'reverse-expand' : ''}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
            </div>
          ) : null}
          {this.state.chatoverview_open ? this.render_chatoverview_open() : null}
          {this.render_link('/split_testing', 'Split Testing')}
          {this.render_link('/split_test_v2', 'Split Testing V2')}
          {this.render_link('/workshops', 'Workshops')}
          {this.render_link('/ywf_repeat_groups', 'YWF Groups')},
          {/* {this.render_link('/ywf_repeat_groups', 'Group Chats')}, */}
          {this.render_link('/announcements', 'Announcements')}
          {this.render_link('/coupon_offers', 'Coupon Offers')}
          {this.does_user_has_access('/chat') ? (
            <div className="drawer-component" onClick={this.open_student_chat}>
              Student Tickets/Chat
            </div>
          ) : null}
          {this.does_user_has_access('/chat') ? (
            <div className="drawer-component" onClick={this.open_teacher_chat}>
              Teacher Tickets/Chat
            </div>
          ) : null}
          {this.render_link('/livekit-users', 'Livekit Users')},
          {this.render_link('/chat-groups', 'Chat Groups')},
          {this.render_link('/magic-links', 'Magic Link Campaigns')}
          {this.render_link('/feedback-forms', 'Feedback Questions')}
          {this.render_link('/short-urls', 'Short Url Links')}
          <div className="drawer-component" onClick={this.props.log_out}>
            Log out
          </div>
        </div>
        <div className="drawer-app-ver">v {import.meta.env.VITE_APP_VER}</div>
      </div>
    );
  }
}

export default withRouter(Drawer);
