import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import CommonHeader from "../../../util_components/common_header";
import CustomSelect from '../../../util_components/custom_single_select';
import { inactive_past_inactive_status } from '../../../../constants';

class InactiveMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      inactiveMembersDetails: [],
      isInfoButtonClicked: null,
      inactive_status: inactive_past_inactive_status[0].value,
      btn_name: inactive_past_inactive_status[0].label,
      total_number_of_member: 0,
      iana_abbr_list: [],
      selected_iana_timezone: 'ALL',
      split_test_choice_list: [],
      selected_split_test_choice: 'ALL'
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.loadData();
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isInfoButtonClicked: null });
    }
  }

  loadData = () =>
    this.setState({ loading: true }, () => {
      const { inactive_status } = this.state;
      try {
        const path = `/v2/campaign/utility/ten_days_inactive_users?${inactive_status}`;
        const url = get_api_url(path, false, true);
        get_api(url, true).then((res) => {
          this.setState({
            loading: false,
            inactiveMembersDetails: res.data.data.inactive_users,
            total_number_of_member: res.data.data.inactive_users,
            iana_abbr_list: [...res.data.data.iana_timezone_abbr],
            split_test_choice_list: [...res.data.data.split_test_filter]
          });
        });
      } catch (e) {
        this.setState({ loading: false });
        console.log(e);
      }
    });

  openInfo = (index) => {
    if (index === this.state.isInfoButtonClicked) {
      this.setState({ isInfoButtonClicked: null });
    } else {
      this.setState({ isInfoButtonClicked: index });
    }
  };

  renderInfo = (data, last_inactive_date) => (
      <div
        className="bg-white rounded-xl border-2 border-borderColor z-10 top-1 left-5 p-4 absolute w-max flex flex-col items-start"
        onClick={(e) => e.stopPropagation()}
      >
        {data.inactive_history.inactive_history_details.map((data) => {
          const active_date = data.active_date?.split(' ');
          const inactive_date = data?.inactive_date?.split(' ');
          return (
            <>
              <div>Active - {active_date?.[0]}</div>
              <div className="mt-2 mb-2">
                {inactive_date?.length ? `Inactive - ${inactive_date?.[0]}` : ''}
              </div>
            </>
          );
        })}

        <div>Last Inactive Date - {last_inactive_date}</div>
        <div className="mt-2">
          No. of times became inactive - {data.inactive_history.inactive_frequency}
        </div>
      </div>
    );

  change_status = (value, label) => {
    this.setState(
      {
        inactive_status: value,
        btn_name: label,
        selected_iana_timezone: 'ALL',
        selected_split_test_choice: 'ALL'
      },
      () => {
        this.loadData();
      }
    );
  };

  change_iana_timezone = (e) => {
    this.setState({ selected_iana_timezone: e.target.value });
  };

  change_split_test_filter = (e) => {
    this.setState({ selected_split_test_choice: e.target.value });
  };

  clear_timezone = () => this.setState({ selected_iana_timezone: 'ALL' });

  clear_split_test_choice = () => this.setState({ selected_split_test_choice: 'ALL' });

  render_filter = () => {
    const {
      selected_split_test_choice,
      iana_abbr_list,
      selected_iana_timezone,
      split_test_choice_list
    } = this.state;
    return (
      <div className="flex items-center justify-center">
          <div style={{ marginRight: '10px' }}>
            <CustomSelect
              label="Select a timezone"
              options={iana_abbr_list}
              onChange={this.change_iana_timezone}
              value={selected_iana_timezone}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_timezone}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <CustomSelect
              label="Select a Split Test"
              options={split_test_choice_list}
              onChange={this.change_split_test_filter}
              value={selected_split_test_choice}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_split_test_choice}
            />
          </div>
        </div>
    );
  };

  render_status_btn = () => {
    const { inactive_status } = this.state;
    return inactive_past_inactive_status.map((item, index) => (
        <button
          key={index}
          className={`${item.value === inactive_status ? 'btn-white-icon bg-mytBlue text-white' : 'btn-white-icon'}`}
          onClick={() => this.change_status(item.value, item.label)}
        >
          {item.label}
        </button>
      ));
  };

  studentDetails = () => {
    const tableHeader = [
      'Name',
      'Active/Inactive History',
      'info',
      'Membership Date',
      'Membership Plan',
      'Last Class Date',
      'Total Classes Till Date',
      'Weeks Active %',
      'Pause %',
      'Split Test',
      'Links to Open SMS and Chat with Member'
    ];

    return (
      <div className="cus-table">
          <div className="ct-row ct-h">
            {tableHeader.map((headers) => <div className="ct-col ct-sm-font">{headers}</div>)}
          </div>
          {this.renderSingleMembers()}
        </div>
    );
  };

  renderSingleMembers = () => {
    const {
      selected_iana_timezone,
      iana_abbr_list,
      selected_split_test_choice,
      split_test_choice_list
    } = this.state;
    let filter_students = this.state.inactiveMembersDetails;
    if (!!selected_iana_timezone && selected_iana_timezone !== 'ALL') {
      const timezone = iana_abbr_list.find((item) => item.label === selected_iana_timezone);
      if (timezone) {
        filter_students = filter_students.filter((s) => s.iana_timezone_abbr === timezone.value);
      }
    }
    if (!!selected_split_test_choice && selected_split_test_choice !== 'ALL') {
      const choice_list = split_test_choice_list.find(
        (item) => item.label === selected_split_test_choice
      );
      if (choice_list) {
        filter_students = filter_students.filter(
          (s) => s.split_test_details.choice === choice_list.value
        );
      }
    }
    return (
      <div className="flex flex-col items-stretch">
        {filter_students?.map((data, index) => {
          const [last_inactive_date] = data.inactive_history.last_inactive_date?.split(' ');
          const [membership_start_date] = data.membership_details.membership_start_date?.split(' ');
          const [last_class_date] = data.last_class_date?.split(' ');
          return (
            <div key={index} className="ct-row flex">
              <div className="ct-col ct-sm-font flex flex-col break-all">
                <div>{data.student_name}</div>
                <div>{data.student_email}</div>
              </div>
              <div className="flex-row ct-col ct-sm-font flex break-all">
                <div>Last Inactive - {last_inactive_date}</div>
              </div>
              <div>
                <div
                  ref={this.wrapperRef}
                  className="ct-col ct-sm-font cursor-pointer flex items-center justify-center"
                  onClick={(e) => this.openInfo(index)}
                >
                  <div className="relative ct-si">
                    <img src="https://images.myyogateacher.com/icons/ic_info_blue.png" />
                    {this.state.isInfoButtonClicked === index &&
                      this.renderInfo(data, last_inactive_date)}
                  </div>
                </div>
              </div>
              <div className="ct-col ct-sm-font flex flex-col break-all">
                <div>Member Date - {membership_start_date}</div>
                <div>Member Months - {data.membership_details.membership_month}</div>
              </div>
              <div className="ct-col ct-sm-font flex justify-center item-center">
                {data.membership_details.package_name}
              </div>
              <div className="ct-col ct-sm-font">{last_class_date}</div>
              <div className="ct-col ct-sm-font flex flex-col break-all">
                <div>1on1 - {data.session_finished_stats.yoga}</div>
                <div>Group Class - {data.session_finished_stats.gc}</div>
              </div>
              <div className="ct-col ct-sm-font ">{data.active_weeks}%</div>
              <div className="ct-col ct-sm-font ">{data.pause_percentage}%</div>
              <div className="ct-col ct-sm-font flex flex-col break-all">
                <div>{data.split_test_details.choice}</div>
                <div>{data.split_test_details.tag}</div>
                {data.split_test_details.teacher_name !== '' && (
                  <div>Teacher - {data.split_test_details.teacher_name}</div>
                )}
              </div>
              <div className="ct-col ct-sm-font flex flex-col gap-2">
                {/* {
                                        data.zendesk_profile !== "" ?
                                            <a className='btn-link' target='_blank' href={data.zendesk_profile}>Zendesk profile</a>
                                            :
                                            'NA'
                                    } */}
                {data.zendesk_profile !== '' ? (
                  <a className="btn-link" target="_blank" href={data.student_profile} rel="noreferrer">
                    Student profile
                  </a>
                ) : (
                  'NA'
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { loading, btn_name, total_number_of_member } = this.state;
    return (
      <div>
        <CommonHeader className="width-full" loading={loading} title={`${btn_name} Members`}>
          <>
            <div className="flex justify-center text-3xl font-bold ">
              {total_number_of_member?.length}
            </div>
            <div className="mb-2 items-center flex">{this.render_status_btn()}</div>
            <div>{this.render_filter()}</div>
            {this.studentDetails()}
          </>
        </CommonHeader>
      </div>
    );
  }
}

export default InactiveMembers;
