import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import Button from "../../util_components/button";
import CommonHeader from "../../util_components/common_header";
import CustomModal from "../../util_components/custom_modal";
import LinkButton from "../../util_components/link_button";
import CustomInput from "../../util_components/custom_input";
import CustomSingleSelect from "../../util_components/custom_single_select";
import { day_format, time_format ,
  session_duration_list,
  group_session_private_values,
  target_audience_names,
  group_types,
  boolean_values
} from '../../../constants';
import Paginator from "../../util_components/paginator";
import {
  image_error_replace,
  check_null_text,
  check_user_is_from_back_pain
} from '../../../util_functions';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class GroupSessions extends Component {
  state = {
    create_session_open: false,
    loading: true,
    teachers: [],
    teacher_selected: '',
    start_date: '',
    session_title: '',
    session_desc: '',
    session_other_title: '',
    session_duration: '60',
    session_max_non_member_participants: 0,
    target_audience: 'Beginner',
    is_premium_group_session: '0',
    session_create_err: false,
    session_create_loading: false,
    group_sessions_loading: true,
    group_sessions: [],
    filtered_group_sessions: [],
    is_private_group_session: '0',
    featured_in_recommended: '0',
    filter_name: 'all',
    filter_time: '',
    filter_teacher: 'all',
    confirm_premium: false,
    premium_session_uuid: '',
    bootcamp_workshop_names: [],
    bootcamp_workshop_uuid: ''
  };

  componentDidMount() {
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
    if (this.props.config_status === 'success') {
      this.setState({
        session_max_non_member_participants:
          this.props.config_values.max_non_member_participants_for_group_classes
      });
    }
    if (this.props.future_group_sessions_status === 'none') {
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.props.get_future_group_sessions(payload);
    }
    if (this.props.future_group_sessions_status === 'success') {
      this.set_future_sessions();
    }
    if (this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (this.props.class_types_status === 'none') {
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.props.get_class_types(payload);
    }
    if (this.props.worshops_status === 'none') this.props.get_all_workshops();
    if (this.props.worshops_status === 'success') this.set_bootcamp_workshops();
  }

  set_future_sessions = () =>
    this.setState({ group_sessions: [...this.props.future_group_sessions] }, () => {
      this.filter_sessions();
    });

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
      this.setState({
        session_max_non_member_participants:
          this.props.config_values.max_non_member_participants_for_group_classes
      });
    }
    if (
      prevProps.future_group_sessions_status === 'loading' &&
      this.props.future_group_sessions_status === 'success'
    ) {
      this.set_future_sessions();
    }
    if (prevProps.future_group_sessions !== this.props.future_group_sessions) {
      this.set_future_sessions();
    }
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (prevProps.worshops_status === 'loading' && this.props.worshops_status === 'success') {
      this.set_bootcamp_workshops();
    }

    if (prevProps.location.pathname.toString() !== this.props.location.pathname.toString()) {
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.setState({ filter_teacher: 'all', filter_name: 'all', filter_time: '' }, () => {
        this.props.get_future_group_sessions(payload);
      });
    }
  }

  set_bootcamp_workshops = () => {
    const { workshops } = this.props;
    const bootcamp_workshops = workshops.filter((w) => w.audience_type == 4);
    const bootcamp_workshop_names = bootcamp_workshops.map((w) => ({
      value: w.uuid,
      label: `${w.name}(${w.start_date})`
    }));
    bootcamp_workshop_names.push({ value: '', label: 'NONE' });
    this.setState({ bootcamp_workshop_names });
  };

  set_session_names = () => {
    const group_session_values = this.props.class_types;
    const group_session_names = [];
    const group_session_descriptions = [];
    for (const sess of group_session_values) {
      group_session_names.push({ value: sess.uuid, label: sess.name });
      group_session_descriptions.push({ value: sess.uuid, description: sess.description });
    }
    const grp_sess_name_filters = [...group_session_names];
    group_session_names.sort((a, b) => a.label.localeCompare(b.label));
    grp_sess_name_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ group_session_names, group_session_descriptions, grp_sess_name_filters });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter(
      (t) => t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY'
    );
    const teachers_list = all_teachers.map((teacher) => ({
        value: teacher.uuid,
        label: `${teacher.first_name} ${teacher.last_name}`
      }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    const grp_sess_teach_filters = [...teachers_list];
    grp_sess_teach_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ teachers: [...teachers_list], loading: false, grp_sess_teach_filters });
  };

  toggle_create_session = () => {
    this.setState({ create_session_open: !this.state.create_session_open });
  };

  set_start_date = (date) => this.setState({ start_date: date, session_create_err: false });

  onCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    const { group_session_descriptions } = this.state;
    this.setState({ [key]: value, session_create_err: false }, () => {
      if (key === 'session_title') {
        const index = group_session_descriptions.findIndex((gs) => value === gs.value);
        if (index !== -1) {
          this.setState({ session_desc: group_session_descriptions[index].description });
        } else {
          this.setState({ session_desc: '' });
        }
      }
    });
  };

  create_sessions = () => {
    const {
      teacher_selected,
      start_date,
      session_title,
      session_desc,
      session_duration,
      session_other_title,
      is_private_group_session,
      featured_in_recommended,
      target_audience,
      is_premium_group_session,
      session_max_non_member_participants,
      bootcamp_workshop_uuid
    } = this.state;
    if (
      !!teacher_selected &&
      !!start_date &&
      !!session_title &&
      !!session_desc &&
      (session_title !== 'other' || !!session_other_title)
    ) {
      const sel_time = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
      const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
      const class_type = this.props.class_types.find((c) => c.uuid === session_title);
      const paylaod = {
        teacher_uuid: teacher_selected,
        start_time: epoch_time,
        description: session_desc,
        duration: session_duration,
        is_private_group_session: parseInt(is_private_group_session),
        featured_in_recommended: parseInt(featured_in_recommended),
        target_audience,
        is_premium_group_session: parseInt(is_premium_group_session),
        class_type_uuid: class_type?.uuid,
        name: class_type.name,
        bootcamp_workshop_uuid,
        is_exclusive_for_backpain_client: class_type?.is_exclusive_for_backpain_client
      };
      if (session_max_non_member_participants) {
        paylaod.max_non_member_participants = session_max_non_member_participants;
      }
      const url = get_api_url('create_group_session');
      this.setState({ session_create_loading: true }, () => {
        post_api(url, paylaod, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Group session created');
            this.setState({
              session_create_loading: false,
              teacher_selected: '',
              start_date: '',
              session_title: '',
              session_desc: '',
              create_session_open: false,
              session_max_non_member_participants:
                this.props.config_values.max_non_member_participants_for_group_classes
            });
            this.props.get_future_group_sessions();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ session_create_loading: false });
          });
      });
    } else {
      this.setState({ session_create_err: true });
    }
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  render_create_session = () => {
    const {
      group_session_names,
      session_create_err,
      teachers,
      bootcamp_workshop_names,
      bootcamp_workshop_uuid
    } = this.state;
    const err_sty = session_create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    return (
      <div>
        <h2>Create group session</h2>
        <div className="display-flex-between">
          <div>
            <div>{`Select time (${curr_timezone})`}</div>
            <DatePicker
              selected={this.state.start_date}
              onChange={(date) => this.set_start_date(date)}
              minDate={new Date()}
              maxDate={moment(new Date()).add(90, 'days').toDate()}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
          <CustomSingleSelect
            label="Select a teacher"
            options={teachers}
            name="teacher_selected"
            onChange={this.onCommonChange}
            value={this.state.teacher_selected}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select session duration"
            options={session_duration_list}
            onChange={this.onCommonChange}
            name="session_duration"
            value={this.state.session_duration}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session private of public"
            options={group_session_private_values}
            onChange={this.onCommonChange}
            name="is_private_group_session"
            value={this.state.is_private_group_session}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Target audience"
            options={target_audience_names}
            onChange={this.onCommonChange}
            name="target_audience"
            value={this.state.target_audience}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session title"
            options={group_session_names}
            onChange={this.onCommonChange}
            name="session_title"
            value={this.state.session_title}
            style={{ width: '250px' }}
          />
        </div>
        {this.state.session_title === 'other' ? (
          <CustomInput
            label="Enter session title"
            onChange={this.onCommonChange}
            name="session_other_title"
            value={this.state.session_other_title}
            style={{ width: '300px' }}
          />
        ) : null}
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select session type"
            options={group_types}
            onChange={this.onCommonChange}
            name="is_premium_group_session"
            value={this.state.is_premium_group_session}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Feature in recommended"
            options={boolean_values}
            onChange={this.onCommonChange}
            name="featured_in_recommended"
            value={this.state.featured_in_recommended}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <CustomInput
            label="Enter Max Non Member Participants"
            onChange={this.on_number_change}
            name="session_max_non_member_participants"
            type="number"
            value={this.state.session_max_non_member_participants}
            style={{ width: '200px' }}
          />
          <CustomSingleSelect
            label="Select Bootcamp Workshop"
            options={bootcamp_workshop_names}
            onChange={this.onCommonChange}
            name="bootcamp_workshop_uuid"
            value={bootcamp_workshop_uuid}
            style={{ width: '250px' }}
          />
        </div>
        <div>Enter session description:</div>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={this.state.session_desc}
          name="session_desc"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <p style={{ margin: '0', fontSize: '12px' }}>
          you have {2000 - this.state.session_desc.length} characters left
        </p>
        <p style={err_sty}>All fields are compulsory</p>
        <Button onClick={this.create_sessions} disabled={this.state.session_create_loading}>
          Submit
        </Button>
      </div>
    );
  };

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${  session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search,
      state: {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      }
    });
  };

  make_premium = () => {
    const session_uuid = this.state.premium_session_uuid;
    this.setState({ loading: true }, () => {
      const url = get_api_url('make_group_session_premium');
      const payload = { session_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Group session updated');
          this.props.update_future_group_sessions(session_uuid, 'is_premium_group_session', 1);
          this.setState({ loading: false, confirm_premium: false, premium_session_uuid: '' });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false, confirm_premium: false, premium_session_uuid: '' });
        });
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${  session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
              {session.teacher_name.split(' ')[0]}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-sm-font">{session.group_session_name}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">{session.participants}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.members_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_member_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {session.is_live === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">
          <Button onClick={() => this.open_session_viewer(session.session_uuid)}>Details</Button>
        </div>
        {/* <div className="ct-col">
                    {session.is_premium_group_session === 1 ? 'Members only' : <Button onClick={() => this.open_confirm_premium(session.session_uuid)}>Promote</Button>}
                </div> */}
      </div>
    );
  };

  render_group_sessions = (sessions) => (
      <div className="cus-table" style={{ marginBottom: '20px' }}>
        <div className="ct-row ct-h">
          <div className="ct-col">Teacher</div>
          <div className="ct-col">
            <div>Start Time</div>
            <div className="ct-sm-font">(Dur)</div>
          </div>
          <div className="ct-col">Name</div>
          <div className="ct-col ct-3rdf ct-sm-font">
            Total <span className="ct-vsm-font">Booked</span>
          </div>
          <div className="ct-col ct-3rdf ct-sm-font">
            Members <span className="ct-vsm-font">Booked</span>
          </div>
          <div className="ct-col ct-3rdf ct-sm-font">
            NM <span className="ct-vsm-font">Booked</span>
          </div>
          <div className="ct-col ct-3rdf ct-sm-font">Live</div>
          <div className="ct-col">Details</div>
        </div>
        {sessions.map(this.render_single_session)}
      </div>
    );

  changePage = (page) => {
    const payload = {
      is_exclusive_for_backpain_client: check_user_is_from_back_pain(),
      page
    };
    this.props.get_future_group_sessions(payload);
  };

  render_sessions = () => {
    const sessions = this.state.filtered_group_sessions;
    const private_sessions = sessions.filter((sess) => sess.is_private_group_session === 1);
    const public_sessions = sessions.filter((sess) => sess.is_private_group_session === 0);
    return (
      <div>
        {private_sessions.length !== 0 ? (
          <>
            <h4>{`Private Sessions (${private_sessions.length}):`} </h4>
            {this.render_group_sessions(private_sessions)}
          </>
        ) : null}
        {public_sessions.length !== 0 ? (
          <>
            <br />
            <h4>{`Public Sessions (${public_sessions.length}):`}</h4>
            {this.render_group_sessions(public_sessions)}
          </>
        ) : null}
        <Paginator
          curr_page={this.props.future_group_sess_curr_page}
          page_limit={this.props.future_group_sess_page_limit}
          pageChange={this.changePage}
        />
      </div>
    );
  };

  go_past = () => this.props.history.push('/pastgroupsessions');

  go_recommended = () => this.props.history.push('/pastgroupsessions/recommended');

  filter_sessions = () => {
    let { filter_name, filter_time, filter_teacher, group_sessions } = this.state;
    if (filter_teacher === 'all') {
      filter_teacher = '';
    }
    if (filter_name === 'all') {
      filter_name = '';
    }
    const tea_fil_sess = group_sessions.filter(
      (sess) => sess.teacher_uuid.indexOf(filter_teacher) !== -1
    );
    // let name_fil_sess = tea_fil_sess.filter(sess => sess.group_session_name.toLowerCase().indexOf(filter_name.toLowerCase()) !== -1)
    const name_fil_sess = tea_fil_sess.filter(
      (sess) => sess.class_type_uuid.indexOf(filter_name) !== -1
    );
    let date_fil_sess = [];
    if (filter_time) {
      const sel_date = moment(filter_time).format('YYYY-MM-DD');
      date_fil_sess = name_fil_sess.filter(
        (sess) =>
          moment(sess.epoch_start_time).tz(this.props.iana_timezone).format('YYYY-MM-DD') ===
          sel_date
      );
    } else {
      date_fil_sess = name_fil_sess;
    }
    this.setState({ group_sessions_loading: false, filtered_group_sessions: [...date_fil_sess] });
  };

  onFilterChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    this.setState({ [key]: value }, () => this.filter_sessions());
  };

  filterDateChange = (date) => {
    this.setState({ filter_time: date }, () => this.filter_sessions());
  };

  render_filters = () => {
    const {
      filter_name,
      filter_time,
      filter_teacher,
      grp_sess_name_filters,
      grp_sess_teach_filters,
      filtered_group_sessions
    } = this.state;
    const download_sessions = filtered_group_sessions.map((s) => {
      const new_s = {
        ...s,
        start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('MMM DD hh:mm A z')
      };
      return new_s;
    });
    const file_name = `Group_sessions_${moment().format('YYYY-MM-DD')}`;
    return (
      <div>
        <hr />
        <h2>Filters:</h2>
        <div style={{ margin: '10px' }} className="display-flex-between">
          <CustomSingleSelect
            label="By teacher"
            options={grp_sess_teach_filters}
            name="filter_teacher"
            onChange={this.onFilterChange}
            value={filter_teacher}
            style={{ width: '250px' }}
          />
          <div>
            <div className="custom-single-select-label">By date:</div>
            <DatePicker
              selected={filter_time}
              onChange={(date) => this.filterDateChange(date)}
              isClearable
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <CustomSingleSelect
            label="By class name"
            options={grp_sess_name_filters}
            onChange={this.onFilterChange}
            name="filter_name"
            value={filter_name}
            style={{ width: '250px' }}
          />
        </div>
        <hr />
        <div style={{ textAlign: 'right' }}>
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={download_sessions} name="Sessions">
              <ExcelColumn label="Teacher" value="teacher_name" />
              <ExcelColumn label="Start Time" value="start_time" />
              <ExcelColumn label="Status" value="session_status" />
              <ExcelColumn label="Name" value="group_session_name" />
              <ExcelColumn label="Duration" value="duration" />
              <ExcelColumn label="Total Participants" value="participants" />
              <ExcelColumn label="Members" value="members_participants" />
              <ExcelColumn
                label="Non Members"
                value="non_members_participants_after_first_10_days"
              />
              <ExcelColumn
                label="Non Members (Trial)"
                value="non_members_participants_within_first_10_days"
              />
              <ExcelColumn label="Non Members (Total)" value="non_member_participants" />
              <ExcelColumn label="Non Members (Limit)" value="max_non_member_participants" />
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    );
  };

  render_confirm_premium = () => (
      <div>
        <h2>Are you sure you want to make this session premium?</h2>
        <Button onClick={this.make_premium}>Confirm</Button>
        <Button type="secondary" onClick={this.close_confirm_premium}>
          Cancel
        </Button>
      </div>
    );

  open_confirm_premium = (uuid) =>
    this.setState({ confirm_premium: true, premium_session_uuid: uuid });

  close_confirm_premium = () => this.setState({ confirm_premium: false, premium_session_uuid: '' });

  render() {
    const { session_create_loading, loading, group_sessions_loading } = this.state;
    const show_loading =
      session_create_loading ||
      loading ||
      group_sessions_loading ||
      this.props.config_status === 'loading' ||
      this.props.future_group_sessions_status === 'loading';
    return (
      <CommonHeader
        loading={show_loading}
        title={check_user_is_from_back_pain() === 1 ? 'Future Back Pain Classes' : 'Future Classes'}
      >
        <CustomModal show={this.state.create_session_open} close={this.toggle_create_session}>
          {this.render_create_session()}
        </CustomModal>
        <CustomModal show={this.state.confirm_premium} close={this.close_confirm_premium}>
          {this.render_confirm_premium()}
        </CustomModal>
        <div className="display-flex-between">
          <Button onClick={this.toggle_create_session}>Create a group session</Button>
          <Button onClick={this.go_past}>Past Group Sessions</Button>
          {check_user_is_from_back_pain() === 1 ? null : (
            <LinkButton href="/groupsessions/recommended" title="Recommended classes" />
          )}
        </div>
        {this.state.loading ||
        this.state.group_sessions_loading ||
        this.props.config_status === 'loading'
          ? null
          : this.render_filters()}
        {this.render_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
    teachers_list: state.teachers.teachers_list,
    teachers_list_status: state.teachers.teachers_list_status,
    iana_timezone: state.home.iana_timezone,
    config_status: state.loading.config_status,
    config_values: state.home.config_values,
    future_group_sessions_status: state.loading.future_group_sessions_status,
    future_group_sessions: state.sessions.future_group_sessions,
    future_group_sess_curr_page: state.sessions.future_group_sess_curr_page,
    future_group_sess_page_limit: state.sessions.future_group_sess_page_limit,
    class_types: state.home.class_types,
    health_keywords: state.home.health_keywords,
    class_types_status: state.loading.class_types_status,
    worshops_status: state.loading.worshops_status,
    workshops: state.workshops.workshops
  });

const mapDispatchToProps = (dispatch) => ({
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    },
    set_notification_variable: (show, type, mes) => {
      dispatch(actions.set_notification_variable(show, type, mes));
    },
    get_future_group_sessions: (payload) => {
      dispatch(actions.get_future_group_sessions(payload));
    },
    update_future_group_sessions: (uuid, key, value) => {
      dispatch(actions.update_future_group_sessions(uuid, key, value));
    },
    get_class_types: (payload) => {
      dispatch(actions.get_class_types(payload));
    },
    get_all_workshops: () => dispatch(actions.get_all_workshops())
  });

export default connect(mapStateToProps, mapDispatchToProps)(GroupSessions);
