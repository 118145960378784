import cookie from 'react-cookies';
import moment from 'moment-timezone';
import { post_api } from './redux/api_funcs';
import { get_api_url } from './utils/urls';

export const getCookie = (cookie_name) => cookie.load(cookie_name);

export const setCookie = (cookie_name, value, age = 86400, client) => {
  const url = client === 'backpain' ? '.lifetreehealth.com' : '.myyogateacher.com';
  cookie.save(cookie_name, value, {
    maxAge: age,
    path: '/',
    domain: url
  });
};

export const removeCookie = (cookie_name) => {
  cookie.remove(cookie_name, { path: '/', domain: '.myyogateacher.com' });
};

export const image_error_replace = (e) => {
  e.target.onerror = null;
  e.target.src = 'https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png';
};

export const compare_teacher_details = (teacher_details) => {
  const teacher_differences = {};
  const teacher_prev_details = { ...teacher_details };
  for (const [key, value] of Object.entries(teacher_prev_details)) {
    if (
      key !== 'teacher_edit_auditing' &&
      key !== 'uuid' &&
      // typeof teacher_prev_details.teacher_edit_auditing[key] !== 'undefined' &&
      !!teacher_prev_details.teacher_edit_auditing[key] &&
      !(
        (value === '' || value === null) &&
        (teacher_prev_details.teacher_edit_auditing[key] === '' ||
          teacher_prev_details.teacher_edit_auditing[key] === null)
      )
    ) {
      if (
        (typeof value === 'string' &&
          typeof teacher_prev_details.teacher_edit_auditing[key] === 'number') ||
        (typeof value === 'number' &&
          typeof teacher_prev_details.teacher_edit_auditing[key] === 'string')
      ) {
        if (value.toString() !== teacher_prev_details.teacher_edit_auditing[key].toString()) {
          teacher_differences[key] = {
            old_value: value,
            new_value: teacher_prev_details.teacher_edit_auditing[key]
          };
        }
      } else if (
        JSON.stringify(teacher_prev_details.teacher_edit_auditing[key]) !== JSON.stringify(value)
      ) {
        if (key === 'resume_document_url' || key === 'video_url') {
          if (teacher_prev_details.teacher_edit_auditing[key] !== '') {
            teacher_differences[key] = {
              old_value: value,
              new_value: teacher_prev_details.teacher_edit_auditing[key]
            };
          }
        } else {
          teacher_differences[key] = {
            old_value: value,
            new_value: teacher_prev_details.teacher_edit_auditing[key]
          };
        }
      }
    }
  }
  return teacher_differences;
};

export const chunk_array = (array, size) => {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const groupBy = (key, array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const get_date_filter_data = (type, fn_start_date, fn_end_date) => {
  let final_start_date = '';
  let final_end_date = '';
  if (type === 'TODAY') {
    const start_date = moment().format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    // const end_time = moment().format('HH:mm');
    const end_time = moment().endOf('day').format('HH:mm:ss');
    final_end_date = `${start_date} ${end_time}`;
  } else if (type === 'TOMORROW') {
    const start_date = moment().add(1, 'days').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const next_day = moment().add(1, 'day');
    const end_date = next_day.endOf('day').format('HH:mm:ss');
    final_end_date = `${start_date} ${end_date}`;
  } else if (type === 'YESTERDAY') {
    const start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment().format('YYYY-MM-DD');
    final_end_date = `${end_date} 00:00`;
  } else if (type === 'THIS WEEK') {
    const start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment()
      .endOf('isoWeek') // End of ISO week
      .format('YYYY-MM-DD');
    final_end_date = `${end_date} ` + `23:59:59`;
  } else if (type === 'NEXT WEEK') {
    const start_date = moment()
      .startOf('isoWeek') // Start of the current ISO week
      .add(1, 'weeks') // Move to the start of next week
      .format('YYYY-MM-DD'); // Format as YYYY-MM-DD
    final_start_date = `${start_date} 00:00`;
    const end_date = moment(start_date, 'YYYY-MM-DD')
      .endOf('isoWeek') // Move to the end of the ISO week (Sunday)
      .format('YYYY-MM-DD'); // Format as YYYY-MM-DD
    console.log('end_date', end_date);
    final_end_date = `${end_date} ` + `23:59:59`;
  } else if (type === 'LAST WEEK') {
    const start_date = moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment(start_date, 'YYYY-MM-DD').add(1, 'weeks').format('YYYY-MM-DD');
    final_end_date = `${end_date} 00:00`;
  } else if (type === 'LAST 2 WEEKS') {
    const start_date = moment().subtract(2, 'weeks').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    final_end_date = `${end_date} ${end_time}`;
  } else if (type === 'LAST 4 WEEKS') {
    const start_date = moment().subtract(4, 'weeks').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    final_end_date = `${end_date} ${end_time}`;
  } else if (type === 'LAST 30 DAYS') {
    const start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    final_end_date = `${end_date} ${end_time}`;
  } else if (type === 'THIS MONTH') {
    const start_date = moment().startOf('month').format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    final_end_date = `${end_date} ${end_time}`;
  } else if (type === 'LAST MONTH') {
    const today = moment().format('YYYY-MM-DD');
    const start_date = moment(today, 'YYYY-MM-DD')
      .startOf('month')
      .subtract(1, 'months')
      .format('YYYY-MM-DD');
    final_start_date = `${start_date} 00:00`;
    const end_date = moment(today, 'YYYY-MM-DD')
      .startOf('month')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    final_end_date = `${end_date} 00:00`;
  } else {
    final_start_date = `${fn_start_date} 00:00`;
    const end_date = moment(fn_end_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    final_end_date = `${end_date} 00:00`;
  }
  return {
    final_start_date,
    final_end_date
  };
};

export const get_date_filter_data_timezone_utc = (
  type,
  fn_start_date,
  fn_end_date,
  iana_timezone
) => {
  const date_date = get_date_filter_data(type, fn_start_date, fn_end_date);
  let time_zone = 'America/Los_Angeles';
  const date_time_format = 'YYYY-MM-DD HH:mm:ss';
  if (iana_timezone) {
    time_zone = iana_timezone;
  }
  const start_date = `${date_date.final_start_date}:00`;
  const end_date = `${date_date.final_end_date}:00`;
  const utc_start_date = moment.tz(start_date, time_zone).utc().format(date_time_format);
  const utc_end_date = moment.tz(end_date, time_zone).utc().format(date_time_format);
  return {
    utc_start_date,
    utc_end_date
  };
};

export const convert_to_pascal_with_spaces = (word) =>
  word
    .split('_')
    .map((item) => {
      const temp = item.split('');
      temp[0] = temp[0].toUpperCase();
      return temp.join('');
    })
    .join(' ');

export const combine_name = (f, m = '', l = '') => {
  let name = '';
  if (f) {
    name += ` ${f}`;
  }
  if (m) {
    name += ` ${m}`;
  }
  if (l) {
    name += ` ${l}`;
  }
  return name;
};

export function debounce(callback, wait, immediate = false) {
  let timeout = null;
  return function () {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
}

export function check_null_text(cus_str) {
  if (cus_str) {
    return cus_str;
  }
  return '--';
}

export function check_bool_flag(flag) {
  if (flag === 1) {
    return 'True';
  }
  return 'False';
}

export function check_bool_flag_reverse(flag) {
  if (flag === 1) {
    return 'True';
  }
  return 'False';
}

export function moment_format_tz(time, timezone, format = '') {
  let cus_format = 'YYYY-MM-DD hh:mm A z';
  let time_zone = 'America/Los_Angeles';
  if (timezone) {
    time_zone = timezone;
  }
  if (format) {
    cus_format = format;
  }
  return moment(time).tz(time_zone).format(cus_format);
}

export function get_err_message(e) {
  console.log(e);
  let err_message;
  try {
    err_message = e.response.data.message || e.response.statusText || e.response.status;
  } catch (err) {
    err_message = 'Some error occured. Please contact dev team.';
  }
  return err_message;
}

export function is_production() {
  if (import.meta.env.VITE_APP_STAGE === 'production') {
    return true;
  }
  return false;
}

export const format_to_label_value = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Please enter a valid object');
  }

  return Object.keys(obj)?.map((key) => ({ value: key, label: obj[key] }));
};

export const session_type = (type) => {
  if (type === 'YOGA') return '1on1';
  if (type === 'GROUP_SESSION') return 'Group';
  if (type === 'GROUP_SESSION_STUDENT') return 'Group';
  if (type === 'WORKSHOP_SESSION') return 'Workshop';
  if (type === 'WORKSHOP_SESSION_STUDENT') return 'Workshop';
  if (type === 'PRIVATE_GROUP_SESSION') return 'Private Group Session';
  if (type === 'PRIVATE_GROUP_SESSION_STUDENT') return 'Private Group Session Student';
  if (type === 'YOGA_WITH_FRIENDS_SESSION') return 'YWF';
  if (type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT') return 'YWF';
  if (type === 'BACK_PAIN_CONSULT') return 'BP-Consult';
  if (type === 'BACK_PAIN_THERAPY') return 'BP-Therapy';
};

export const uploadDiagnosisReport = async (report, uuid, clientDomain) => {
  const api_url =
    clientDomain === 'backpain'
      ? '/v2/onboarding/backpain/cms/pain_diagnosis_report'
      : '/v2/onboarding/student/health_records';
  const url = get_api_url(api_url, null, true);
  if (!report) throw new Error('file not found');
  const formData = new FormData();
  if (clientDomain === 'backpain') {
    formData.append('back_pain_diagnosis_reports', report);
    formData.append('student_uuid', uuid);
  } else {
    formData.append('files', report);
    formData.append('student_uuid', uuid);
  }
  return post_api(url, formData, true);
};

export const check_user_is_from_back_pain = () => {
  const url = window.location.href;
  const isBackPain = url.split('/').includes('backpain');
  if (isBackPain) {
    return 1;
  }
  return 0;
};

export function isEmpty(val) {
  // use cases
  //---------------
  // []        true
  // {}        true
  // null      true
  // undefined true
  // ""        true
  // ''        true
  // 0         false (number)
  // true      false
  // false     false
  // Date      false
  // function  false

  if (val === undefined) {
    return true;
  }

  if (
    typeof val === 'function' ||
    typeof val === 'number' ||
    typeof val === 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  ) {
    return false;
  }

  if (val == null || val.length === 0) {
    return true;
  }

  if (typeof val === 'object') {
    return !!(Object.keys(val).length === 0);
  }

  return false;
}

export const getDayAndTime = (selectedDateString) => {
  const dateMoment = moment(selectedDateString);

  const dayOfWeek = dateMoment.day();

  const formattedTime = dateMoment.format('HH:mm');

  return { dayOfWeek, formattedTime };
};

export const convertEpochToReadableDate = (epochTime) => {
  const date = new Date(epochTime);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Adding 1 because months are zero-based
  const day = `0${date.getDate()}`.slice(-2);
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

export const isViewOnly = (access) => access === 'VIEW_ONLY';
